import { groupBy } from 'lodash';
import { Helmet } from 'react-helmet-async'
import { useTranslation } from "react-i18next"
import { CloudArrowUpIcon, PencilSquareIcon } from "@heroicons/react/24/outline";
import { Link, Outlet } from 'react-router-dom';
import _ from 'lodash'

import { useMessages } from '../Messages';
import { downloadReport } from '../backend';
import Image from '../components/Image'
import DeleteButton from '../components/DeleteButton';
import { useRoles } from '../user/Auth';
import { t } from 'i18next';
import { IconChevronDown, IconEdit } from '../components/icons';
import EmptyState from '../components/EmptyState';
import { useFilteredCommodities } from '../filter/FilteredCommodities';
import clsx from 'clsx';
import { useProducts } from './ProductsProvider';
import { Menu, MenuButton, MenuItem, MenuItems, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import { Commodity } from './products';
import { useTags } from '../tags/TagsProvider';


export default function ProductPortfolio() {
  const { t } = useTranslation();

  const { commodities: allCommodities } = useFilteredCommodities();

  const commodities = allCommodities;

  const commoditiesByClass = groupBy(commodities, (c) => c.commodityClass);
  const sortedClasses = Object.keys(commoditiesByClass).sort()

  const isEmpty = allCommodities.length === 0;

  return (
    <>
      {/* @ts-ignore */}
      <Helmet>
        <title>{t("product-portfolio")} | Patent Cockpit</title>
      </Helmet>
      <div className="modern-header-row">
        <div className="flex flex-row max-sm:flex-wrap gap-1 sm:gap-2 items-end sm:items-center max-w-screen-lg">
          <h2 className='max-sm:self-start modern-h2'>{t("products")}</h2>
          <div className="grow" />
          <ProductPortfolioExportButton />
          <ProductPortfolioMenu />
        </div>
      </div>
      {isEmpty ?
        <div className='p-4 max-w-screen-lg'>
            <EmptyState items={noProductsActions(t)} />
        </div>
      : 
      <div className="main-content pt-0 bg-pcx-100">
        <div className="grid grid-cols-1 md:grid-cols-[1fr_2fr_1fr] gap-y-2 max-w-screen-lg">
          {sortedClasses.flatMap((cl) => {
            const cs = commoditiesByClass[cl];
            return <CommodityFamily key={cl} commodities={cs} commodityClass={cl} />
          })}
        </div>
      </div>}
      <Outlet />
    </>
  );
}

function CommodityFamily({commodities, commodityClass}: {commodities: Commodity[], commodityClass?: string}) {
  return (
    <div className='md:col-span-3 grid grid-cols-subgrid bg-white rounded-xl shadow p-2'>
      <h3
        className=" text-pcx-500 font-medium text-base col-span-3 px-2 my-2"
      >
        {commodityClass !== "undefined" ? commodityClass : t("unclassified")}
      </h3>
      {_(commodities).sortBy((c) => c.commodityReference).map((commodity) => <CommodityRow key={commodity.commodityId} {...{ commodity, }} />).value()}
    </div>
  )
}

function CommodityRow({
  commodity,
}) {
  const { isDev, isEditUser, hasProductCountries } = useRoles();
  const { deleteCommodity, reload, commodityCountryLinksByCommodityId } = useProducts()
  const {tagsLookup} = useTags()

  const commodityCountryLinks = commodityCountryLinksByCommodityId[commodity.commodityId] ?? []
  const countryTags = commodityCountryLinks.map((l) => l.countryCode);
  const tagsOfProduct = tagsLookup?.["commodity"]?.[commodity.commodityId] ?? []

  const ribbonStyle = clsx('ribbon', commodity.status === "active" && "bg-pcx-200")

  return (
    <div
      key={commodity.commodityId}
      className="px-2 md:col-span-3 grid grid-cols-subgrid gap-3 border-t-2 border-pc-200 pt-2 mb-2 last:mb-0 p-1"
    >
      <div className="">
        <Image
          {...{
            entity: "commodity",
            entityId: commodity.commodityId,
            isEditable: isEditUser,
          }}
        />
      </div>

      <Link to={`product/${commodity.commodityId}`} className="block space-y-px">
          <h4>{commodity.commodityReference}</h4>
          <div className="flex flex-wrap gap-2 text-sm text-gray-500">
            {isDev &&
              _(tagsOfProduct)
                .uniq()
                .sortBy((t) => t)
                .map((t) => <div key={t}>{t}</div>)
                .value()}
          </div>
          <p className='text-base whitespace-pre-wrap'>{commodity.commodityDescription}</p>
      </Link>

      <div className="flex flex-row justify-between">
        <div className="flex items-start flex-wrap gap-1 text-sm">
          <div className={ribbonStyle}>{t(commodity.status)}</div>
          {commodity.isThirdParty && (
            <div className={ribbonStyle}>{t("isThirdParty")}</div>
          )}
          {(hasProductCountries ? countryTags : []).map((cc) => (
            <div key={cc} className={ribbonStyle}>
              {cc}
            </div>
          ))}
        </div>
        {isEditUser && (
          <div className="shrink-0 w-fit flex flex-row gap-1 md:pl-4 pl-2">
            <Link
              to={`product/${commodity.commodityId}/edit`}
              className="btn-primary h-5 w-5 p-px"
              title={t("edit")}
            >
              <IconEdit className="" />
            </Link>
            <DeleteButton
              className="btn-warn h-5 w-5 p-px"
              deleteAction={() => deleteCommodity(commodity).then(() => reload())}
              question={t('really-delete-name', {name: commodity.commodityReference})}
            />
          </div>
        )}
      </div>
    </div>
  );
}

function ProductPortfolioExportButton() {
  const { hasExcelExport } = useRoles();
  const {setErrorMessage} = useMessages()

  const { commodities } = useFilteredCommodities();

  function handleExport() {
    downloadReport({
      url: "/api/excel",
      report: "product-portfolio-report",
    }).catch((e) => setErrorMessage(e.message));
  }

  return commodities?.length > 0 && hasExcelExport && (
    <button className="btn-secondary py-0.5 rounded-sm font-medium text-sm" onClick={handleExport}>
      {t("excel-export")}
    </button>
  )
}

function ProductPortfolioMenu() {
  const { isEditUser, hasExcelImport } = useRoles();

  const items = [
    {to: "product/add", label: "add-product", disabled: !isEditUser},
    {to: "/products/extract", label: "excel-import", disabled: !hasExcelImport || !isEditUser},
  ].filter((i) => !i.disabled);

  if (items.length === 0) return null;


  return (
    <Menu as="div" className="relative inline-block text-left font-normal">
      <MenuButton className="btn-secondary py-0.5 rounded-sm flex justify-center gap-1 font-medium text-sm focus:outline-0 focus:ring-0">
        {t('add-product')}
        <IconChevronDown aria-hidden="true" />
      </MenuButton>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <MenuItems className="absolute right-0 z-10 mt-2 w-fit origin-top-right rounded-sm bg-white border border-pcx-500 shadow-lg ring-1 ring-pcx-600 ring-opacity-5 focus:outline-none overflow-hidden">
          {items.map(({ to, label, disabled }) =>
            disabled ? null :
              <MenuItem key={label}>
                {({ focus }) =>
                  <Link to={to} className={clsx(
                    focus ? 'bg-pcx-200 text-pcx-800' : 'text-pcx-600',
                    'block px-4 py-2 text-sm whitespace-nowrap'
                  )}>
                    {t(label)}
                  </Link>}
              </MenuItem>
          )}
        </MenuItems>
      </Transition>
    </Menu>
  )
}


const noProductsActions = (t) => [
  {
    title: t("upload-product-portfolio"),
    description: t("upload-product-portfolio-desc"),
    icon: CloudArrowUpIcon,
    background: "bg-pcx-600",
    to: "/products/extract",
  },
  {
    title: t("add-product"),
    description: t("manually-add-product-desc"),
    icon: PencilSquareIcon,
    background: "bg-pcx-300",
    to: "product/add",
  },
];
