import { Helmet } from "react-helmet-async"
import { Link, Outlet } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { TFunction } from "i18next";
import { CloudArrowDownIcon, PencilSquareIcon } from "@heroicons/react/24/outline";
import clsx from "clsx"
import _ from 'lodash'

import { trademark_family } from "../data"
import { Trademark, TrademarkFamily, useTrademarks } from "./TrademarksProvider"
import { trademarkUrl, trademarkFamilyUrl } from "./utils"
import Image, { EditImageModal } from "../components/Image"
import EmptyState from "../components/EmptyState"
import { useRoles } from "../user/Auth";
import { useFilteredTrademarks } from "../filter/FilteredTrademarks";
import { useBackend } from "../BackendProvider";
import { ChevronRightIcon } from "@heroicons/react/20/solid";
import { useState } from "react";
import { FamilyBreadCrumb } from "../patents/Family";

export default function TrademarkPortfolio() {
    const {t} = useTranslation()

    const {isEditUser} = useRoles()

    const { isLoading } = useTrademarks()
    const { trademarkFamilies, trademarksByFamilyId } = useFilteredTrademarks()

    return (
        <>
            {/* @ts-ignore */}
            <Helmet>
                <title>{t('trademarks')} | Patent Cockpit</title>
            </Helmet>
            <div className="portfolio-menu max-w-4xl sm:items-center">
                <h2 className="flex flex-row gap-1 md:gap-4 whitespace-nowrap shrink min-w-0 grow pr-4">
                    <TrademarkBreadcrumbs />
                </h2>
                {isEditUser && <div className="flex flex-col sm:flex-row gap-1 items-end sm:items-center">
                    <Link className={"btn-secondary py-0.5 text-sm"} to="import">{t('excel-import')}</Link>
                    <Link className={clsx(trademarkFamilies?.length === 0 ? "btn-primary" : "btn-secondary", "py-0.5 text-sm")} to="add">
                        {t('add-trademark-family')}
                    </Link>
                </div>}
            </div>
            <div className="w-full overflow-auto grow">
                <div className="max-w-4xl p-4 pt-0 space-y-2">
                    {trademarkFamilies?.length === 0 && !isLoading && <EmptyPortfolio />}
                    {_(trademarkFamilies)
                        .sortBy(f => f.reference)
                        .reverse()
                        .map(family => <TmFamily key={family.reference} family={family} trademarks={trademarksByFamilyId[family.familyId] ?? []} />)
                        .value()}
                </div>
            </div>
            <Outlet />
        </>
    )
}

function TmFamily({family, trademarks}: {family: TrademarkFamily, trademarks: Trademark[]}) {
    const {t} = useTranslation()
    return (
        <div className="p-4 bg-white rounded-lg shadow">
            <div className="grid grid-cols-1 lg:grid-cols-[1fr_250px] max-lg:divide-y-2 lg:divide-x-2 divide-pcx-200">
                <div className="group max-lg:pb-2 md:pr-2 flex flex-col md:flex-row gap-4">
                        <TmImage {...{family}} />
                    <Link to={trademarkFamilyUrl(family)} className="block">
                        <h2 className="group-hover:text-pcx-800">{family.reference}: {family.name}</h2>
                        <p>{family.description}</p>
                    </Link>
                </div>
                <div className="max-lg:pt-2 lg:pl-2 flex flex-row flex-wrap content-start gap-1 text-sm">
                    {_(trademarks)
                        .sortBy('countryCode')
                        .map((trademark, ti) =>
                            <Link
                                key={trademark.trademarkId ?? ti}
                                to={trademarkUrl(trademark)}
                                title={`${trademark.reference}: ${t(trademark.status)}`}
                                className="ribbon hover:shadow hover:text-pcx-700 h-fit">
                                {trademark.countryCode}
                            </Link>
                        ).value()}
                </div>
            </div>
        </div>
    )
}

function ModernChevronRight({className}: {className?: string}) {
    return <ChevronRightIcon className={clsx("h-5 w-5 flex-shrink-0 text-gray-600 self-center", className)} aria-hidden="true" />
}

export function TrademarkBreadcrumbs({family, trademark}: {family?: TrademarkFamily, trademark?: Trademark}) {
    const {t} = useTranslation()

    return <>
        <Link to="/trademarks" className={clsx(trademark && "hidden sm:block", "modern-h2")}>{t('portfolio')}</Link>
        {family && <>
            <ModernChevronRight className={trademark ? "hidden sm:block" : ""} />
            <FamilyBreadCrumb {...{to: trademarkFamilyUrl(family), familyName: family.name, reference: family.reference}} />
        </>}
        {trademark && <>
                <ModernChevronRight />
              <Link className="modern-h2" to={trademarkUrl(trademark)}>{trademark.reference}</Link>
        </>}
    </>
}

export function TmImage({family, isEditable = false}: {family: TrademarkFamily, isEditable?: boolean}) {

    const { imagesLookup } = useBackend()

    const hasImage = imagesLookup[trademark_family]?.[family.familyId] !== undefined

    //console.log({family, hasImage, ims: imagesLookup,})

    if (hasImage)
        return (
            <div className="w-40 h-36 shrink-0">
                <Image {...{
                    entity: trademark_family,
                    entityId: family.familyId,
                    isEditable,
                    title: family.reference + ": " + family.name,
                    text: family.description
                }} />
            </div>
        )
    else
        return <FmWords {...{familyId: family.familyId, isEditable }} />
}

function FmWords({familyId, isEditable}: {familyId?: number, isEditable?: boolean}) {
    const {t} = useTranslation()

    const {trademarksByFamilyId} = useTrademarks()
    const [showEdit, setShowEdit] = useState(false)

    const trademarks = trademarksByFamilyId[familyId ?? 0] ?? []

    if (trademarks.length === 0 && !isEditable)
      return <div className="w-40"> </div>;

    const words = _(trademarks).map(t => t.words?.toLocaleUpperCase()?.trim()).filter(w => typeof w === 'string' && w !== '').uniq().join(', ')
    return <>
        <div className="w-40 h-36 relative group shrink-0 text-pcx-600/80 align-middle text-center font-bold overflow-hidden flex flex-row items-center">
            <div className="w-full max-h-full">
                {words}
            </div>
            {isEditable &&
                <div className="absolute  hidden group-hover:flex flex-row items-center place-content-center w-full h-full bg-black/30">
                    <button className="btn-primary h-fit" onClick={() => setShowEdit(s => !s)}>{t('edit')}</button>
                </div>}
        </div>
        {showEdit && <EditImageModal {...{entity: trademark_family, entityId: familyId, setShowPopup: setShowEdit}} />}
    </>
}

const make_items = (t: TFunction) => [
    {
        title: t('add-trademark-family'),
        description: t('add-trademark-family-manually'),
        to: "add",
        icon: PencilSquareIcon,
        background: "bg-pcx-500",
    },
    {
        title: t('excel-import'),
        description: t('excel-import-trademarks-desc'),
        to: "import",
        icon: CloudArrowDownIcon,
        background: 'bg-pcx-300',
    }
]

export function EmptyPortfolio() {
    const {t} = useTranslation()
    const {isEditUser} = useRoles()
    const items = isEditUser ? make_items(t) : []

    return <EmptyState {...{
        title: t("no-trademark-families"),
        text: t("add-trademark-families-desc"),
        items,
     }} />
}

export function EmptyFilteredPortfolio() {
    const {t} = useTranslation()
    const {isEditUser} = useRoles()
    const items = isEditUser ? make_items(t) : []

    return <EmptyState {...{
        title: t("no-filtered-trademark-families"),
        text: t("add-trademark-families-desc"),
        items,
     }} />
}