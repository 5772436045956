import { Helmet } from "react-helmet-async"
import { useTranslation } from "react-i18next"
import { Outlet, useParams } from "react-router-dom";

import { AgorumRoot, BrowserProvider } from "./DocumentsProvider";
import { FolderView} from "./DocumentFiles"
import { clientAreaPath, getClientRoot, /*useAgorumObject*/ } from "./backend";
import { useDocumentSettings } from "./DocumentsSettings";
import { emptyStringAsUndefined } from "../utils/strings";
import { ArrowTopRightOnSquareIcon, LinkIcon  } from "@heroicons/react/20/solid";
import { Popover, PopoverButton, PopoverPanel } from "@headlessui/react";
import { useRef, useState } from "react";
import clsx from "clsx";
// import { DocumentSearch } from "./DocumentSearch";


export function DocumentsMenu() {
    const {t} = useTranslation()

    return (
        <>
            {/* @ts-ignore */}
            <Helmet>
                <title>{t('documents')} | Patent Cockpit</title>
            </Helmet>
            <Outlet />
        </>
    )
}

function DocumentsHeader({children = null}) {
    const {t} = useTranslation()
    return (
        <div className="modern-header-row min-w-md max-w-xl flex flex-row gap-2 items-center">
            <h2 className="modern-h2">{t('documents')}</h2>
            {children}
        </div>
    )
}

export default function DocumentsBrowser() {

    const {number, settings} = useDocumentSettings()
    //const number = settings?.agorumClientId?.toString()

    if (!number) return <DocumentsHeader />
    else return <DocumentsBrowserPage {...{number, settings}} />
}

function DocumentsBrowserPage({number, settings}: {number: string, settings: {baseurl: string}}) {
    const {t} = useTranslation()

    const params = useParams()
    const hasPreview = params.id !== undefined

    const folder = useRef(null)
    const [isResizing, setIsResizing] = useState(false)

    function onDrag(event) {
        if (isResizing) {
            event.preventDefault()
            const moveX = event.movementX
            folder.current.style.width = `${folder.current.offsetWidth + moveX}px`
        }
    }

    if (!emptyStringAsUndefined(number)) return null

    const root = getClientRoot(number)
    // TODO only used for search. Maybe push down?
    // const {object} = useAgorumObject({root})

    return <>
        <DocumentsHeader >
            <DocumentsExternalLink {...{number, ...settings}}/>
        </DocumentsHeader>
        <div className="main-content pt-0 bg-pcx-100">
            <BrowserProvider>
                <div className="flex flex-row gap-1 items-start w-fit"
                    onMouseUp={() => setIsResizing(false)} /*onBlur={() => setIsResizing(false)}*/ onMouseMove={onDrag}
                >
                    <div ref={folder} className={clsx(
                        isResizing && "select-none",
                        "bg-white p-4 rounded-xl shadow space-y-0.5",
                        hasPreview ? "hidden md:block" : "block",
                        "min-w-md xl:min-w-xl"
                    )}>
                        <AgorumRoot {...{ root }} >
                            <FolderView name={t("documents")} defaultOpen nonEditable hasSortButton />
                        </AgorumRoot>
                    </div>
                    <div onMouseDown={() => setIsResizing(true)} aria-hidden="true"
                        className="max-sm:hidden px-1 w-fit group self-stretch cursor-col-resize" >
                        <div className="h-full w-1 rounded bg-slate-200 group-hover:bg-pcx-400" />
                    </div>
                    <Outlet />
                </div>
            </BrowserProvider>
        </div>
    </>
}

function DocumentsExternalLink({number, baseurl}: {number: string, baseurl: string}) {
    const {t} = useTranslation()

    const [isCopied, setIsCopied] = useState(false)

    const url = `https://${baseurl}/webdav/dms/${clientAreaPath(number)}`

    const copyToClipboard = async () => {
        try {
            await navigator.clipboard.writeText(url);
            //   setCopySuccess("Copied!");
            setIsCopied(true);
            setTimeout(() => setIsCopied(false), 1000); // Reset after 1 second
        } catch (err) {
            //   setCopySuccess("Failed to copy!");
            console.error("Failed to copy text: ", err);
            setIsCopied(false);
        }
      };

    return (
        <Popover className="relative pt-1">
            <PopoverButton title="Direct WebDAV URL">
                <ArrowTopRightOnSquareIcon className="size-4 text-gray-500/60" />
            </PopoverButton>
            <PopoverPanel anchor="right start" className="relative ml-1 -mt-1 bg-white rounded-lg shadow-lg p-4 border border-pcx-200">
                <h3 className="text-sm mb-2 text-slate-800">Direct WebDAV URL</h3>

                <p className="text-xs text-slate-400 mb-3 px-px">
                    You need a special username and password. 
                    If you don&#39;t have it, <a href="mailto:support@patent-cockpit.com?Subject=DMS username and password needed" className="hover:text-pcx-400 underline">contact support</a>.
                </p>

                <div className="flex flex-row gap-2">
                    <div className="relative w-fit">
                    <input type="text" className="form-input text-xs px-1.5 py-1 min-w-xs border-slate-200" value={url}/>
                        <div className="absolute right-0 top-0 p-1 w-12 h-full pointer-events-none">
                            <div className="bg-gradient-to-r from-white/20 to-white/90 h-full w-full" />
                        </div>
                    </div>
                    <button title="Copy Link" onClick={copyToClipboard} 
                        className={clsx("btn-primary text-xs space-x-2", isCopied && "scale-110", "transition-transform transform duration-300 ease-in-out")}
                    >
                        <LinkIcon className="size-4 inline" /> {isCopied ? t('copied') : t('copy')}
                    </button>
                </div>

                {/* <div className="text-xs space-x-2">
                    <span>{url}</span>
                    <button title="Copy Link" onClick={copyToClipboard} className="btn-secondary p-0.5"><LinkIcon className="size-3" /></button>
                </div> */}
            </PopoverPanel>
        </Popover>
    )
}