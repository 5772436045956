import React, { useState } from 'react'
import {
    useQuery,
    useMutation,
    useQueryClient,
    useInfiniteQuery,
    InfiniteData,
} from '@tanstack/react-query'
import { CalendarDate, getLocalTimeZone, today as createToday, parseDate } from '@internationalized/date';
import _ from 'lodash'

import { UUID } from "./renewals";
import { useAuth, useRoles } from '../user/Auth'
import { ErrorMessage, useMessages } from '../Messages'
import { synchronize, get_entities, rpc, pass_through, download_document, post_instructions } from './dennemeyer_backend'
import { IpRightInfo } from './renewals';
import { InstructionTimelineSettings, calculateFirstPossibleDueDate, calculateLatestPossibleDueDate, calculateTimeline, isOpen } from './settings/instruction_timeline';

export interface PageResponse<T> {
    Data?: Page<T>;
    Errors?: string[];
    HasError: boolean;
    ErrorDetails?: any;
}

export interface Page<T> {
    PageSize: number;
    IsLastPage: boolean;
    NextPageLink?: string;
    PreviousPageLink?: string;
    CurrentPageNumber: number;
    TotalRows: number;
    Page?: T[];
}

export interface PatentNumber {
    Number: string;
    Date: string;
}

export interface IpRight {
    DennemeyerId: UUID;
    MaintenanceActions?: MaintenanceAction[];
    UniqueCaseKey: string;
    CustomerReferenceNumbers: string[];
    PermanentOrder: boolean;

    CountryCode: string;
    Application?: PatentNumber;
    Publication?: PatentNumber;
    Grant?: PatentNumber;
    IpType: string;
    IpSubType: string;
    Status: string;
    Origin: string;
    NumberOfClaims?: number;
    NextRenewalDueDate?: string; // only for trademarks?
    ExpiryDate?: string;
    NextMaintenanceActionDueDate?: string;
    LastModifiedDate?: string;
    IntegrationStatus?: IntegrationStatus;

    RegisteredOwners?: RegisteredOwner[];
    CostCenters: IpCostCenter[];

    Former?: PatentNumber;
}

export type IntegrationStatus = "UnderReview" | "Imported"

export interface RegisteredOwner {
    Name: string;
    Address: string;
}

export interface IpCostCenter {
    ReferenceNumber: string;
    Split: number;
    Company: {Name: string};
}

export interface PcIpRight {
    ipRightId?: number,
    familyMemberId: number,
    dennemeyerId?: UUID,
    ipType: string,
    ipSubType: string,
    //linked?: number;
    origin: string,
    status: DmStatus,
    created?: string,
    updated?: string,
    firstDueDate?: string,
    dirty: boolean,
    localAgent?: string,
}

export type DmStatus = "Pending" | "Granted" | "Inactive"
export type OldPaymentProviderStatus = "active" | "inactive" | "unknown"


export interface PcOwner {
    ownerId?: number;
    agentId?: number;
    companyCode?: string;
    name: string;
    ipEmail: string;
}

export interface PcAddress {
    addressId?: number;
    address1: string;
    address2?: string;
    city: string;
    state?: string;
    postCode: string;
    countryCode: string;
}

export type AddressType = "LegalAddress" | "BillingAddress" | "RenewalAddress" | "OtherAddress"

export interface PcOwnerAddress {
    ownerId: number;
    addressId: number;
    addressType: AddressType;
}

export type OwnerType = "registered" | "effective"

export interface PcOwnership {
    ownerId: number;
    ipRightId: number;
    ownerType: OwnerType;
}

export interface IgnoredPatent {
    familyMemberId: number;
}

export type MaintenanceActionPhase = 
    "Auto-Pay in Progress" |
    "Upcoming" | "Waiting For Instruction" | "Instructed / In Progress" | 
    "OnHold" | "Inactive" | "Closed" | "Transferred"

export interface MaintenanceAction {
    DennemeyerId: UUID;
    DueDate: string;
    InstructionInfo?: InstructionInfo;
    ActionType?: string; //e.g. Annuity or Affidavit
    Annuity?: number;
    Phase: MaintenanceActionPhase;
    //Status: string; // deprecated
    FeesActual?: FeeActual[];
    FeesEstimated?: FeeEstimated[];
    IpRightInfo: IpRightInfo;
    PermanentOrder: boolean;
}

export interface InstructionInfo {
    Instruction: Instruction;
    InstructedAt: string;
    Comments?: string;
}

export type Instruction = "Pay" | "Skip" | "Cancel" | "Hold"
export type SpecialInstruction = Instruction | "Undecide"

export interface PcInstruction {
    actionId?: number,
    ipRightId: number,
    dennemeyerId: UUID,
    instruction: Instruction,
    commentId?: number,
    created?: string,
}

export interface FeeActual extends Fee {
    DNDennemeyerId: UUID;
    DNNumber: number;
}

export interface FeeEstimated extends Fee {
    RNDennemeyerId: UUID;
    RNNumber: number;
}

export interface Fee {
    IssueDate?: string;
    TotalFee?: number;
    Currency?: string;
}

export interface PcCostCenter {
    costCenterId?: number;
    name: string;
}

export interface PcCostCenterLink {
    costCenterId: number;
    ipRightId: number;
    percentage: number;
}

export interface ValidationMessage {
    validationId: number;
    ipRightId: number;
    severity: string;
    message: string;
    errorCode: string;
}


const DennemeyerContext = React.createContext({
    ipRights: [] as PcIpRight[],
    postIpRight: (ipRight: PcIpRight) => Promise.resolve(ipRight),
    deleteIpRight: (ipRight: PcIpRight) => Promise.resolve({}),
    invalidateIpRights: () => {},

    owners: [] as PcOwner[],
    postOwner: (owner: PcOwner) => Promise.resolve({}),
    deleteOwner: (owner: PcOwner) => Promise.resolve({}),
    addresses: [] as PcAddress[],
    postAddress: (address: PcAddress) => Promise.resolve({}),
    deleteAddress: (address: PcAddress) => Promise.resolve({}),
    ownerAddresses: [] as PcOwnerAddress[],
    postOwnerAddress: (ownerAddress: PcOwnerAddress) => Promise.resolve({}),
    deleteOwnerAddress: (ownerAddress: PcOwnerAddress) => Promise.resolve({}),
    ownershipLinks: [] as PcOwnership[],
    postOwnershipLink: (ownershipLink: PcOwnership | PcOwnership[]) => Promise.resolve({}),
    deleteOwnershipLink: (ownershipLink: PcOwnership | PcOwnership[]) => Promise.resolve({}),

    costCenters: [] as PcCostCenter[],
    postCostCenter: (costCenter: PcCostCenter) => Promise.resolve({}),
    deleteCostCenter: (costCenter: PcCostCenter) => Promise.resolve({}),
    costCenterLinks: [] as PcCostCenterLink[],
    postCostCenterLink: (costCenterLink: PcCostCenterLink | PcCostCenterLink[]) => Promise.resolve({}),
    deleteCostCenterLink: (costCenterLink: PcCostCenterLink | PcCostCenterLink[]) => Promise.resolve({}),

    instructions: [] as PcInstruction[],
    postInstruction: (instruction: PcInstruction) => Promise.resolve(instruction),
    deleteInstruction: (instruction: PcInstruction) => Promise.resolve({}),

    addressById: {},
    addressesByOwner: {},
    ownerById: {} as Record<number, PcOwner>,
    ownershipsByIpRight: {} as Record<number, PcOwnership[]>,
    ownershipsByOwner: {},
    ipRightByMemberId: {} as Record<number, PcIpRight>,
    ipRightById: {} as Record<number, PcIpRight>,
    ipRightByDennemeyerId: {} as Record<string, PcIpRight>,
    validationsByIpRightId: {} as Record<number, ValidationMessage[]>,
    costCentersByIpRightId: {} as {[ipRightId: string]: (PcCostCenter & PcCostCenterLink)[]},

    instructionsByIpRightId: {} as Record<number, PcInstruction[]>,
    instructionByDennemeyerId: {} as Record<string, PcInstruction>,

    isLoading: false,
    reload: () => {},
    //isMaintenancesLoading: true,
})

export function useCrud<T>(entity: string, get_id: (e: T) => any, enabled = true) {
    const queryClient = useQueryClient()
    const {setErrorMessage} = useMessages()
    const {team, setStrike} = useAuth()

    const queryKey = [entity]
    const queryFn = async () => rpc({ entity, operation: 'get' })
        .then(res => { setStrike(0); return res })
        .catch((err: ErrorMessage) => {
            setErrorMessage(err.message);
            if (err.status === 'unauthorized') {
                console.warn("unauthorized")
                setStrike(s => s + 1)
            }
            return []
        })
    const { data, error, isLoading } = useQuery<T[], ErrorMessage, T[]>({
        queryKey,
        queryFn,
        placeholderData: [], initialData: [], enabled
    })

    if (error)
        setErrorMessage(error.message)

    const mutationConfig = {
        onError: (err: ErrorMessage, payload: T, context) => {
            console.error(err)
            if (err.status === "unauthorized") {
                setStrike(s => s + 1)
            }
            queryClient.setQueryData(queryKey, context.previousEntities)
        },
        onSettled: () => {
            // @ts-ignore
            queryClient.invalidateQueries({
                queryKey,
                //queryFn, // TODO CHECK MIGRATION
                refetchType: 'all',
            })
            setStrike(0)
        },
        enabled,
    }

    function postOperation(payload: T) {
        if (get_id(payload))
            return "update"
        else
            return "add"
    }

    const postMutation = useMutation<T, ErrorMessage, T>({
        mutationFn: (payload) =>
            rpc({ entity, operation: postOperation(payload), data: { ...payload, realm: team } })
                .catch(err => setErrorMessage(err.message)), 
        ...mutationConfig,
        onMutate: async (payload) => {
            await queryClient.cancelQueries({queryKey})
            const previousEntities = queryClient.getQueryData(queryKey)
            queryClient.setQueryData(queryKey, old => {
                // check if old is of type array
                if (Array.isArray(old)) {
                    //console.log({old})
                    const p_id = get_id(payload)
                    return p_id
                        ? old.map(o => get_id(o) === p_id ? payload : o)
                        : [...old, payload]
                } else {
                    return [payload]
                }
            })
            return { previousEntities }
        },
    })

    const deleteMutation = useMutation<T, ErrorMessage, T>({
        mutationFn: (payload) =>
            rpc({ entity, operation: 'delete', id: get_id(payload) })
                .catch(err => setErrorMessage(err.message)), 
        ...mutationConfig,
        onMutate: async (payload) => {
            //console.log({payload})
            await queryClient.cancelQueries({queryKey})
            const previousEntities = queryClient.getQueryData(queryKey)
            queryClient.setQueryData(queryKey, (old) => {
                return Array.isArray(old) ? old.filter(o => get_id(o) !== get_id(payload)) : []
            })
            return { previousEntities }
        }
    })

    return {
        data, 
        isLoading, 
        postMutation: postMutation.mutateAsync, 
        deleteMutation: deleteMutation.mutateAsync
    }
}

export function useLinkedCrud<T>(entity: string, isEqual = (a: T, b: T) => false, enabled = true) {
    const queryClient = useQueryClient()
    const {setErrorMessage} = useMessages()
    const {team, setStrike} = useAuth()

    const queryKey = [entity]
    const queryFn = () => rpc({ entity, operation: 'get' }).catch(err => { 
        setErrorMessage(err.message)
        if (err.status === 'unauthorized') {
            console.warn("unauthorized")
            setStrike(s => s + 1)
        }
        return []
    })
    const { data, error, isLoading } = useQuery<T[], ErrorMessage, T[]>({
        queryKey,
        queryFn,
        placeholderData: [], initialData: [], enabled 
    })

    if (error)
        setErrorMessage(error.message)

    const mutationConfig = {
        onError: (err: ErrorMessage, payload: T, context) => {
            console.error(err)
            queryClient.setQueryData(queryKey, context.previousEntities)
        },
        onSettled: async () => {
            //console.log('invalidate ' + queryKey)
            await queryClient.invalidateQueries({
                queryKey,
                refetchType: 'all',
            })
        },
        enabled,
    }

    function postOperation(payload: T | T[]) {
        if (Array.isArray(payload))
            return "bulk-add"
        else
            return "add"
    }

    function dataWithTeam(payload: T | T[]) {
        if (Array.isArray(payload))
            return payload.map(p => ({ ...p, realm: team }))
        else
            return { ...payload, realm: team }
    }

    const postMutation = useMutation<T | T[], ErrorMessage, T>({
        mutationFn: (payload) =>
            rpc({ entity, operation: postOperation(payload), data: dataWithTeam(payload) })
                .catch((err: ErrorMessage) => setErrorMessage(err.message)), 
        ...mutationConfig,
        onMutate: async (payload) => {
            await queryClient.cancelQueries({queryKey})
            const previousEntities = queryClient.getQueryData(queryKey)
            queryClient.setQueryData(queryKey, old => {
                // @ts-ignore
                return [...(old ?? []), payload]
            })
            return { previousEntities }
        },
    })

    function deleteOperation(payload: T | T[]) {
        if (Array.isArray(payload))
            return "bulk-delete"
        else
            return "delete"
    }


    const deleteMutation = useMutation<T | T[], ErrorMessage, T>({
        mutationFn: (payload) =>
            rpc({ entity, operation: deleteOperation(payload), data: dataWithTeam(payload) })
                .catch(err => setErrorMessage(err.message)), 
        ...mutationConfig,
        onMutate: async (payload) => {
            //console.log({payload})
            await queryClient.cancelQueries({queryKey})
            const previousEntities = queryClient.getQueryData(queryKey)
            queryClient.setQueryData(queryKey, (old) => {
                return Array.isArray(old) ? old.filter(o => !isEqual(o, payload)) : []
            })
            return { previousEntities }
        }
    })

    return {
        data, isLoading, postMutation: postMutation.mutateAsync, deleteMutation: deleteMutation.mutateAsync
    }
}

function useGetOnly<T>(entities: string, enabled = true) {
    const {setErrorMessage} = useMessages()

    const queryKey = [entities]
    const { data, error, isLoading } = useQuery<T[], ErrorMessage, T[]>({
        queryKey,
        queryFn: () => get_entities(entities).catch(err => { setErrorMessage(err.message); return [] }),
        placeholderData: [], initialData: [], enabled 
    })

    if (error)
        setErrorMessage(error.message)

    return {data, isLoading}
}

const ipRightKey = 'ip-rights'

// This is actually only the DM data saved on the PC side
export default function DennemeyerProvider({children}) {
    const {hasAnnuities} = useRoles()

    const queryClient = useQueryClient()

    const {data: ipRights, isLoading: isIpRightsLoading, postMutation: postIpRight, deleteMutation: deleteIpRight} =
        useCrud<PcIpRight>(ipRightKey, e => e.ipRightId, hasAnnuities) 
    const {data: owners, isLoading: isOwnersLoading, postMutation: postOwner, deleteMutation: deleteOwner} =
        useCrud<PcOwner>('owners', e => e.ownerId, hasAnnuities)
    const {data: addresses, isLoading: isAddressesLoading, postMutation: postAddress, deleteMutation: deleteAddress} =
        useCrud<PcAddress>('addresses', e => e.addressId, hasAnnuities)

    const {data: instructions, /*isLoading: isInstructionsLoading,*/ postMutation: postInstruction, deleteMutation: deleteInstruction} =
        useCrud<PcInstruction>('instructions', e => e.actionId, hasAnnuities)

    function invalidateIpRights() {
        queryClient.invalidateQueries({queryKey: [ipRightKey]})
    }

    const {data: ownerAddresses, isLoading: isOwnerAddressesLoading, postMutation: postOwnerAddress, deleteMutation: deleteOwnerAddress} = 
        useLinkedCrud<PcOwnerAddress>('owner-address', (a, b) => a.ownerId === b.ownerId && a.addressId === b.addressId, hasAnnuities)
    const {data: ownershipLinks, isLoading: isOwnershipLoading, postMutation: postOwnershipLink, deleteMutation: deleteOwnershipLink} =
        useLinkedCrud<PcOwnership>('ownership', (a, b) => a.ownerId === b.ownerId && a.ipRightId === b.ipRightId, hasAnnuities)

    const {data: costCenters, isLoading: isCostcentersLoading, postMutation: postCostCenter, deleteMutation: deleteCostCenter} =
        useCrud<PcCostCenter>('cost-centers', e => e.costCenterId, hasAnnuities)
    const {data: costCenterLinks, isLoading: isCostCenterLinksLoading, postMutation: postCostCenterLink, deleteMutation: deleteCostCenterLink} =
        useLinkedCrud<PcCostCenterLink>('cost-center-links', (a, b) => a.costCenterId === b.costCenterId && a.ipRightId === b.ipRightId, hasAnnuities)

    const {data: validations} = useGetOnly<ValidationMessage>('validations', hasAnnuities)

    function reload() {
        queryClient.invalidateQueries({queryKey: [ipRightKey]})
        queryClient.invalidateQueries({queryKey: ['validations']})
        queryClient.invalidateQueries({queryKey: ['instructions']})
        queryClient.invalidateQueries({queryKey: ['cost-center-links']})
        queryClient.invalidateQueries({queryKey: ['ownership']})
    }

    const addressById = _.keyBy(addresses, 'addressId')
    const addressesByOwner = _.groupBy(ownerAddresses, 'ownerId')
    const ownerById = _.keyBy(owners, 'ownerId')
    const ownershipsByIpRight = _.groupBy(ownershipLinks, 'ipRightId')
    const ownershipsByOwner = _.groupBy(ownershipLinks, 'ownerId')

    const ipRightByMemberId = _.keyBy(ipRights, 'familyMemberId')
    const ipRightById = _.keyBy(ipRights, 'ipRightId')
    const ipRightByDennemeyerId = _.keyBy(ipRights, 'dennemeyerId')
    const validationsByIpRightId = _(validations).filter(v => v.severity === "Error").groupBy('ipRightId').value()

    const ccById = _.keyBy(costCenters, 'costCenterId')
    const costCentersByIpRightId = _(costCenterLinks)
        .groupBy('ipRightId')
        .mapValues(links => links.map(({costCenterId, percentage, ipRightId}) => ({...ccById[costCenterId], costCenterId, percentage, ipRightId})))
        .value()

    const instructionsByIpRightId = _(instructions)
        .groupBy('ipRightId')
        .mapValues(is => _.sortBy(is, i => i.created))
        .filter(instruction => instruction !== undefined)
        .value()

    
    const instructionByDennemeyerId = _(instructions)
        .groupBy(i => i.dennemeyerId)
        .mapValues(is => _.maxBy(is, i => i.created))
        .value()

    const isLoading = isIpRightsLoading || isOwnersLoading || isAddressesLoading || isOwnerAddressesLoading || isOwnershipLoading || isCostcentersLoading || isCostCenterLinksLoading

    return <DennemeyerContext.Provider value={{
        ipRights, postIpRight, deleteIpRight, invalidateIpRights,
        owners, postOwner, deleteOwner,
        addresses, postAddress, deleteAddress,
        ownerAddresses, postOwnerAddress, deleteOwnerAddress,
        ownershipLinks, postOwnershipLink, deleteOwnershipLink,
        addressById, addressesByOwner, ownerById, ownershipsByIpRight, ownershipsByOwner, 
        costCenters, postCostCenter, deleteCostCenter,
        costCenterLinks, postCostCenterLink, deleteCostCenterLink,
        costCentersByIpRightId,
        ipRightByMemberId, ipRightById, ipRightByDennemeyerId, validationsByIpRightId,
        instructions, postInstruction, deleteInstruction, instructionsByIpRightId, instructionByDennemeyerId,
        isLoading, reload,
    }}>
        {children}
    </DennemeyerContext.Provider>
}

export function useDennemeyer() {
    return React.useContext(DennemeyerContext)
}

export interface PcSettings {
    settingsId?: number;
    clientId?: string;
    clientSecret?: string;
    baseUrl?: string;
    notificationId?: string;
    startDate?: string;
}

export function useDmSettings() {
    const {data, postMutation: postSettings, isLoading} = useCrud<PcSettings & Partial<InstructionTimelineSettings>>('settings', e => e.settingsId)
    return {settings: data[0] ?? {startDate: undefined}, postSettings, isLoadingSettings: isLoading}
}

export interface SingleReponse<T> {
    Data: T;
    Errors?: string[];
    HasError: boolean;
    ErrorDetails?: any;
}

//export function useLoadInViewport<T>(queryKey: any, queryFn: () => Promise<T>, canLoad: boolean) {
//    const [inViewPort, setInViewPort] = useState(false)
//    const ref = useRef()
//
//    useEffect(() => {
//        const observer = new IntersectionObserver((entries) => {
//            //console.log('Triggering call')
//            if (entries[0].isIntersecting)
//                setInViewPort(true)
//        })
//        if (ref.current !== undefined && canLoad) {
//            //console.log(ref.current)
//            observer.observe(ref.current)
//        }
//    }, [queryKey, ref, canLoad])
//
//    const { data, isLoading } = useQuery({queryKey, queryFn, enabled: canLoad && inViewPort })
//
//    return { data, isLoading, ref }
//}

export function useDocumentAcknowledge() {
    const queryClient = useQueryClient()

    async function acknowledge(dennemeyerId: string | number) {
        return pass_through(`/v1.2/api/documents/by-id/${dennemeyerId}/ack`, 'POST')
            .then(res => {
                queryClient.invalidateQueries({queryKey: ['documents', {onlyNew: true}]})
                return res
            })
    }
    
    return {acknowledge}
}
export function useDocuments({onlyNew}) {

    const fetchDocuments = async ({pageParam = `/v1.2/api/documents?onlyNew=${onlyNew}`}) => {
        const res = await pass_through(pageParam)
        return res
    }

    const {
        data,
        error,
        fetchNextPage,
        hasNextPage,
        isFetching,
        isFetchingNextPage,
        status,
      } = useInfiniteQuery<PageResponse<DocumentInfo>, ErrorMessage, InfiniteData<PageResponse<DocumentInfo>, string>>({
        queryKey: ['documents', {onlyNew}],
        // @ts-ignore TODO MIGRATION CHECK
        queryFn: fetchDocuments,
        getNextPageParam: (lastPage, pages) => {
            const link = lastPage?.Data?.NextPageLink
            //console.log(lastPage, link)
            return link !== "" ? link : undefined
        },
      })

    return {documentPages: data, error, fetchNextPage, hasNextPage, isFetching, isFetchingNextPage, status}
}

export function useDocumentsPerId(dennemeyerId: string) {
    //const fetchDocuments = async ({pageParam = `/v1.2/api/documents/by-ipright-id/${dennemeyerId}`}) => 
    //    await pass_through(pageParam)
    const fetchDocuments = async () => 
        await pass_through(`/v1.2/api/documents/by-ipright-id/${dennemeyerId}`)

    const {data, error, isFetching} = useQuery<PageResponse<DocumentInfo>>({
        queryKey: ['documents-per-id', dennemeyerId],
        queryFn: fetchDocuments,
        enabled: dennemeyerId !== undefined
    })

    return {documents: data, error, isFetching }
}

export interface DocumentInfo {
    DennemeyerId: number
    DocumentType: string,
    DocumentName: string,
    FileExtension: string,
    Md5Hash: string,
    Sha1Hash: string,
    DocumentDate: string,
}

export function useDocumentInfo(DocumentId: number | undefined) {
    const {acknowledge} = useDocumentAcknowledge()

    const {data, isLoading, error} = useQuery<SingleReponse<DocumentInfo>>({
        queryKey: ['document-info', DocumentId], 
        queryFn: () => pass_through(`/v1.2/api/documents/by-id/${DocumentId}/info`), 
        enabled: DocumentId !== undefined
    })

    async function downloadDocument(filename: string) {
        return download_document(DocumentId, filename)
            .then(() => acknowledge(DocumentId))
    }

    return {documentInfo: data?.Data, isLoading, error, downloadDocument}
}

export type DebitNote = {
    DennemeyerId: string;
    IssueDate?: string;
    DnNumber?: string;
    DocumentId?: number;
    Lines?: any[];
}

export function useDebitNote(DennemeyerId: string) {
    const { data, isLoading, error } = useQuery<SingleReponse<DebitNote>>({
        queryKey: ['debit-note', DennemeyerId],
        queryFn: () => pass_through(`/v1.2/api/renewals/debitnotes/by-id/${DennemeyerId}`), 
        enabled: DennemeyerId !== undefined
    })
    //console.log(data)
    return {debitNote: data?.Data, isLoading, error}
}


export type RenewalNotice = {
    DennemeyerId: string;
    RnNumber?: string;
    RenewalNoticeType?: string;
    IssueDate?: string;
    DocumentId?: number;
    Lines?: any[];
}

export function useRenewalNotice(DennemeyerId: string) {
    const { data, isLoading, error } = useQuery<SingleReponse<RenewalNotice>>({
        queryKey: ['renewal-notice', DennemeyerId],
        queryFn: () => pass_through(`/v1.2/api/renewals/renewalnotices/by-id/${DennemeyerId}`), 
        enabled: DennemeyerId !== undefined
    })
    return {renewalNotice: data?.Data, isLoading, error}
}



export function useIpRight(dennemeyerId?: string) {
    const queryClient = useQueryClient()
    const queryKey = [ipRightKey, dennemeyerId] 

    const {data: dmIpRight, isLoading} = useQuery<SingleReponse<IpRight>>({
        queryKey, 
        queryFn: () => pass_through(`/v1.2/api/portfolio/by-id/${dennemeyerId}`), 
        enabled: dennemeyerId !== undefined
    })

    function reload() {
        queryClient.invalidateQueries({
            queryKey,
            refetchType: 'all',
        })
    }


    return {ipRight: dmIpRight, isLoading, reload}
}

// by maintenance action id
export function useMaintenance(dennemeyerId: string) {
    const {data, isLoading, error} = useQuery<SingleReponse<MaintenanceAction>>({
        queryKey: ['maintenances', dennemeyerId], 
        queryFn: () => pass_through(`/v1.2/api/renewals/maintenances/by-id/${dennemeyerId}`), 
        enabled: dennemeyerId !== undefined
    })

    return {maintenance: data?.Data, isLoading, error}
}

function useInvalidateMaintenances() {
    const queryClient = useQueryClient()

    function invalidateMaintenances() {
        //console.log('invalidating maintenances...')
        return queryClient.invalidateQueries({queryKey: ['maintenances']})
    }

    return {invalidateMaintenances}
}

export interface MaintenanceParameters {
    onlyOpen?: boolean;
    onlyInstructable?: boolean;
    minDate?: string | CalendarDate;
    maxDate?: string | CalendarDate;
}

export function useMaintenances({onlyOpen, onlyInstructable, minDate, maxDate}: MaintenanceParameters) {
    const {hasAnnuities} = useRoles()

    const queryParams = _({
        onlyOpen,
        onlyInstructable,
        minDueDate: minDate instanceof CalendarDate ? minDate.toString() : minDate,
        maxDueDate: maxDate instanceof CalendarDate ? maxDate.toString() : maxDate,
    }).toPairs().filter(([k, v]) => v !== undefined).map(([k, v]) => `${k}=${v}`).join('&')

    //console.log({queryParams})

    //const queryParams = `?onlyOpen=${onlyOpen}&onlyInstructable=${onlyInstructable}`

    const fetchMaintenances = async ({pageParam = `/v1.2/api/renewals/maintenances?${queryParams}`}) => {
        const res = await pass_through(pageParam)
        return res
    }
    //const {data, isLoading, error} = useQuery(['maintenances'], () => pass_through(`/v1.1/api/maintenances${queryParams}`))

    const {
        data,
        error,
        fetchNextPage,
        hasNextPage,
        isFetching,
        isLoading,
      } = useInfiniteQuery<PageResponse<MaintenanceAction>, ErrorMessage, InfiniteData<PageResponse<MaintenanceAction>, string>>({
        queryKey: ['maintenances', {queryParams}],
        // @ts-ignore TODO MIGRATION CHECK
        queryFn: fetchMaintenances,
        enabled: ((minDate === undefined && maxDate === undefined) || minDate < maxDate) && hasAnnuities,
        getNextPageParam: (lastPage, pages) => {
            const link = lastPage?.Data?.NextPageLink
            //console.log(lastPage, link)
            return link !== "" ? link : undefined
        },
      })

    return {data, fetchNextPage, hasNextPage, isFetching, error, isLoading}
}

/*
    synchronizing: false
    const state validation messages
    synchronize ->
    synchronizing: true

*/
export function useSynchronize() {
    const {setErrorMessage} = useMessages()
    const [synchronizing, setSynchronizing] = useState(false)
    const client = useQueryClient()

    async function triggerSynchronize(ipRightIds: number[] = []) {
        setSynchronizing(true)
        return synchronize(ipRightIds.filter(Boolean)).then(res => {
            setSynchronizing(false)
            client.invalidateQueries({queryKey: [ipRightKey]})
            client.invalidateQueries({queryKey: ['validations']})
            client.invalidateQueries({queryKey: ['ip-rights-portfolio']})
            return res
        }).catch(err => setErrorMessage(err.message))
    }

    return {triggerSynchronize, synchronizing}
}

function asCalendarDate(date: string | CalendarDate) {
    return date instanceof CalendarDate ? date : parseDate(date)
}

export function useTimeline() {
    const {settings} = useDmSettings()

    const today = createToday(getLocalTimeZone())
    const timelineSettings = {
        renewalNoticeMonths: 5,
        instructionPeriodMonths: 3,
        paymentTermsDays: 30,
        ...(settings ?? {}),
    }

    function calculateDueDates(dueDate: string) {
        return calculateTimeline(dueDate, today, timelineSettings)
    }

    const firstPossibleFeeDueDate = (instructionDueDate: CalendarDate | string) =>
        calculateFirstPossibleDueDate(asCalendarDate(instructionDueDate), timelineSettings)

    const latestPossibleFeeDueDate = (instructionDueDate: CalendarDate | string) =>
        calculateLatestPossibleDueDate(asCalendarDate(instructionDueDate), timelineSettings)

    return {calculateDueDates, firstPossibleFeeDueDate, latestPossibleFeeDueDate}
}

// let's see if this works or response is too large...
export function useIpRightsPortfolio() {
    const {hasAnnuities} = useRoles()
    const {data, isLoading, error} = useQuery<PageResponse<IpRight>>({
        queryKey: ['ip-rights-portfolio'], 
        queryFn: () => pass_through(`/v1.2/api/portfolio?iptype=Patent&PageSize=10000&modifiedfrom=2023-11-30&onlyactive=false`), 
        enabled: hasAnnuities
    })

    const ipRights = data?.Data?.Page
    const ipRightByDennemeyerId = _(ipRights).filter(ipr => !isNaN(+ipr.UniqueCaseKey)).keyBy('DennemeyerId').value()
    return {ipRights, ipRightByDennemeyerId, isLoading, error}
}

export function useFullIpRightsPortfolio() {
    const {hasAnnuities} = useRoles()

    const fetchIpRights = async ({pageParam = `/v1.2/api/portfolio?PageSize=1000`}) => {
        return pass_through(pageParam)
    }

    const { data, error, fetchNextPage, hasNextPage, isFetching, isLoading } = useInfiniteQuery<PageResponse<IpRight>, ErrorMessage, InfiniteData<PageResponse<IpRight>, string>>({
        queryKey: ['ip-rights-portfolio', 'full'],
        // @ts-ignore TODO MIGRATION CHECK
        queryFn: fetchIpRights,
        //() => pass_through(`/v1.2/api/portfolio?PageSize=10000`), 
        enabled: hasAnnuities,
        getNextPageParam: (lastPage, pages) => {
            const link = lastPage?.Data?.NextPageLink
            return link !== "" ? link : undefined
        },
    })

    return {data, error, fetchNextPage, hasNextPage, isFetching, isLoading}
}

// This should fully stop the IP right by setting stopping the payments, setting the status to inactive and syncronizing with the DM
export function useStopIpRight(ipRightId: number) {
    const {calculateDueDates} = useTimeline()
    const { ipRightById, postInstruction, postIpRight, invalidateIpRights } = useDennemeyer()
    const { invalidateMaintenances } = useInvalidateMaintenances()

    const {triggerSynchronize, synchronizing} = useSynchronize()

    const pcIpRight = ipRightById[ipRightId]

    const {ipRight: ipRightData, isLoading} = useIpRight(pcIpRight?.dennemeyerId)
    const ipRight = ipRightData?.Data

    const openMaintenanceActions = _(ipRight?.MaintenanceActions ?? [])
        .map(m => ({...m, ...calculateDueDates(m.DueDate)}))
        .filter(m => isOpen(m.status))
        .value()

    async function fullStop() {
        if (openMaintenanceActions.length > 0) {
            const instructions = openMaintenanceActions.map(ma => ({DennemeyerId: ma.DennemeyerId, Instruction: 'Cancel' as Instruction}))
            await post_instructions(instructions)
            await Promise.all(instructions.map(i => postInstruction({ipRightId, dennemeyerId: i.DennemeyerId, instruction: i.Instruction})))
            invalidateMaintenances()
        }
        await postIpRight({...pcIpRight, status: 'Inactive'})
        triggerSynchronize([ipRightId])
        invalidateIpRights()
    }

    return {fullStop, synchronizing, isLoading}
}