import { useTranslation } from "react-i18next";
import { usePatents } from "../patents/PatentsProvider";
import { IncomeLookup, useIncome } from "./IncomeProvider";
import { commodityUrl, useProductMapping } from "../products/products";
import { useRoles } from "../user/Auth";
import _ from "lodash";
import clsx from "clsx";
import { Link } from "react-router-dom";
import { useSingleTeamMangement } from "../Management";
import { memberUrl } from "../patents/utils";
import { useFxContext } from "../forecast/FxProvider";
import { eaCountries, epCountries } from "../data";

// create a formatter for numbers
const formatter = new Intl.NumberFormat(undefined, {maximumFractionDigits: 0, minimumFractionDigits: 0})

export default function PatentIncome({familyMemberIds}: {familyMemberIds: number[]}) {
  const {t} = useTranslation()

  const {team} = useSingleTeamMangement()
  const {fxConverter} = useFxContext()
  const currency = team?.currency ?? 'EUR'

  const {hasClaimScopes} = useRoles()

  const {memberById} = usePatents()
  const {incomeLookup} = useIncome()
  const {commoditiesByMemberId, claimScopeCommoditiesByFamilyMemberId} = useProductMapping()

  const members = familyMemberIds.map(familyMemberId => memberById[familyMemberId]).filter(Boolean)

  if (members.length === 0) return null

  const mappedCommodities = _(familyMemberIds)
    .flatMap(familyMemberId => [
      ...commoditiesByMemberId[familyMemberId] ?? [],
      ...((hasClaimScopes ? claimScopeCommoditiesByFamilyMemberId[familyMemberId] : []) ?? [])
    ])
    .filter(c => c && c.isThirdParty === false)
    .uniqBy(c => c.commodityId)
    .sortBy(c => `${c.commodityClass ?? ''} / ${c.commodityReference}`)
    .value()

  // Add country codes which are relevant for EP and EA. But only consider them when I actually appear in the inComeLookup List (presentCountries)
  const broaderCountryCodes = members
    .flatMap(member => {
      if (member.countryCode === 'EP')
        return epCountries
      else if (member.countryCode === 'EA')
        return eaCountries
      else
        return [member.countryCode]
    })
  const relevantIncomes: IncomeLookup = _(incomeLookup)
    .pick(mappedCommodities.map(c => c.commodityId))
    .mapValues(byCommodity => _.pick(byCommodity, broaderCountryCodes))
    .value()

  const presentCountries = _(relevantIncomes)
    .values()
    .flatMap(_is => _.keys(_is))
    .uniq()
    .value()
  const countryCodes = _(members).map(m => m.countryCode).push('WO').concat(presentCountries).uniq().sortBy().value()

  const years = _(relevantIncomes)
    .values()
    .flatMap(_is => _.values(_is).flatMap(_.keys))
    .uniq()
    .sortBy()
    .reverse()
    .value()

  //console.log({years})

  const totalByYear = _(relevantIncomes)
    .values()
    .flatMap(_is => _.values(_is).flatMap(_.values))
    .groupBy('year')
    .mapValues(_is => _.sumBy(_is, i => fxConverter.convert(i.amount, i.currency, currency)))
    .value()

  return (
    <div>
      <h3 id="income" className="sm:pl-2">{t('income')}</h3>
      <div>
        <table>
          <thead>
            <tr className="border-b-2 border-pcx-300">
              <th className={clsx(headerClass, 'text-left')}>{t('commodity')}</th>
              {members.length > 1 && 
                <th className={clsx(headerClass, 'text-left')}>{t('reference')}</th>}
              <th className={clsx(headerClass, 'text-left')}>{t('country')}</th>
              {years.map(year => <th key={year} className={clsx(headerClass, 'text-right')}>{year}</th>)}
            </tr>
          </thead>
          <tbody className="border-b-2 border-pcx-300">
            {mappedCommodities.map(c => {
              return countryCodes.map((country, i) => {
                return (
                  <tr key={c.commodityId + '-' + country} className={clsx(i === 0 && "first:border-t-0 border-t-2 border-pcx-200")}>
                    <td className="px-2.5">
                      {i === 0 
                        ? <Link to={commodityUrl(c) + '/income'} className="text-pcx-500 hover:underline">{c.commodityClass} / {c.commodityReference}</Link>
                        : null}
                    </td>
                    {members.length > 1 &&
                      <td className="px-2.5 flex flex-col">
                        {members.filter(m => m.countryCode === country).map(m => 
                          <Link key={m.internalReference} to={memberUrl(m)} className="text-pcx-500 hover:underline">{m.internalReference}</Link>)}
                      </td>}
                    <td className="px-2.5 tabular-nums">{country}</td>
                    {years.map(year =>  {
                      const income = relevantIncomes[c.commodityId]?.[country]?.[year]
                      const hasAmount = income?.amount !== undefined
                      return <td key={year} className={clsx("text-right px-2.5 tabular-nums", !hasAmount && 'text-slate-300')}>
                        {hasAmount ? formatter.format(income.amount) : '-'}
                        <span className="pl-1 inline-block w-8">{income?.currency ?? '-'}</span>
                      </td>
                    })}
                  </tr>
                )
              })
            })}
          </tbody>
          <tfoot>
            <tr>
              <td className="px-2.5">{t('total')}</td>
              <td className="px-2.5"></td>
              {members.length > 1 && <td />}
              {years.map(year =>
                <td key={year} className="text-right px-2.5 tabular-nums">
                  {totalByYear[year] ? formatter.format(totalByYear[year]) : '-'}
                  <span className="pl-1 inline-block w-8">{currency ?? '-'}</span>
                </td>)}
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  );
}

const headerClass = "px-2.5 py-2 text-pcx-600 text-sm font-semibold uppercase tracking-wider whitespace-nowrap"