import { useState, useEffect} from 'react'

import Package from '../../package.json'
import { useMessages } from '../Messages'
import { Trans } from 'react-i18next'

const packageVersion = Package.version

function useServerVersion() {
    const [serverVersion, setVersion] = useState(undefined)

    useEffect(() => {
        if (serverVersion === undefined)
            fetch("/version.json", {
              method: "GET",
              cache: "no-store",
            })
              .then((r) => r.json())
              .then(({ version }) => setVersion(version));
    }, [serverVersion])

    return {serverVersion}
}

export function ModernVersion() {
    const { serverVersion } = useServerVersion()
    const { setInfoMessage, message } = useMessages()

    // If there is another infoMessage, the reload won't be shown. But we can live with that.
    if (serverVersion && (packageVersion !== serverVersion) && message === undefined) {
        console.log(`Server: ${serverVersion} - Client: ${packageVersion}`)
        setInfoMessage(<span><Trans i18nKey="version-reload" components={{
            reload: <button className='underline' onClick={() => window.location.reload()}></button>
        }} /></span>)
    }

    return <div className=" text-gray-400 text-xs font-thin">{packageVersion}</div>
}