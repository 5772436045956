//import { Buffer } from 'buffer';
import { loadLocalTeam } from './user/Auth'

export async function parseBackend(promise: Promise<Response>) {
    return promise.then(res => res.ok
        ? res.json()
        : (res.status >= 500)
            ? Promise.reject({ status: "unreachable", message: `${res.status}: Server is unreachable` })
            : Promise.reject({ status: "unauthorized", message: `${res.status}: You are not authorized` })
    ).then(res =>
        (res.status !== "ok")
            ? Promise.reject(res) // .status + ": " + res.message)
            : res.payload ?? {})
}

// ENTITIES
export interface RpcEntitiesProps {
    id?: number;
    operation: "get" | "update" | "delete" | "add";
    entity: string;
    data?: object;
}
export async function rpcEntities<T = any>(body: RpcEntitiesProps): Promise<T> {

    // TODO: if (inUiOnlyDev) return ....
    return parseBackend(fetch("/api/entity", {
        method: "POST",
        body: JSON.stringify(body),
        headers: {
            'Content-Type': 'application/json',
        },
        credentials: 'same-origin'
    }))
}

// LINKS
export interface RpcLinksProps {
    operation: "get" | "update" | "delete" | "add" | "bulk-add" | "bulk-delete";
    link: string;
    data?: object;
}
export async function rpcLinks(body: RpcLinksProps) {
    return parseBackend(fetch("/api/link", {
        method: "POST",
        headers: { 'Content-Type': 'application/json' },
        credentials: 'same-origin',
        body: JSON.stringify(body)
    }))
}

// STATUS (to get the full state)
export async function rpcState() {
    return parseBackend(fetch("/api/status"))
}

// IMAGES
export async function uploadImage(imageFile: any) {
    //console.log(imageFile)
    const formData = new FormData()
    formData.append('image-file', imageFile.file)
    formData.append('image-id', imageFile.id)

    return parseBackend(fetch("/api/images", {
        method: "POST",
        credentials: 'same-origin',
        body: formData
    }))
}

// Excel Extract & Import
export async function extractExcel(excelFile: File, target?: string) {
    const formData = new FormData()
    formData.append('excel-file', excelFile)

    const param = target ? `?target=${target}` : ""

    return parseBackend(fetch(`/api/extract${param}`, {
        method: "POST",
        credentials: 'same-origin',
        body: formData,
    }))
}

export async function importExcelPortfolio(portfolio: object) {
    return parseBackend(fetch("/api/import", {
        method: "POST",
        credentials: 'same-origin',
        body: JSON.stringify(portfolio),
        headers: { 'Content-Type': 'application/json' },
    }))
}

export async function downloadImportExample(target?: string) {
    const param = target ? `?target=${target}` : ""
    return fetch(
        `/api/extract${param}`, {
        method: "GET",
        credentials: 'same-origin',
    }).then(res => !res.ok
        ? Promise.reject({ status: "fail", message: res.statusText })
        : res.blob().then(blob => {
            const contentDisposition = res.headers.get('Content-Disposition')
            const re = /filename="([^"]*)"/
            const filename = re.exec(contentDisposition)[1] ?? "ImportExample.xlsx"
            const urlObj = window.URL.createObjectURL(blob)
            const a = document.createElement('a')
            a.href = urlObj
            a.download = filename
            document.body.appendChild(a) // we need to append the element to the dom -> otherwise it will not work in firefox
            a.click()
            a.remove()  //afterwards we remove the element again         
        })
    )
}

//export type ExcelJson = Record<string, Record<string, any>[]>
export type ExcelCell = string | number | boolean | undefined | null
export type ExcelRow = Record<string, ExcelCell> | ExcelCell[]
export type ExcelSheet = { name: string, header: string[], rows: ExcelRow[] }
export type ExcelJson = { sheets: ExcelSheet[] }

async function errorResponse(res: Response) {
    return res.text()
        .then(text => Promise.reject({ status: "fail", message: `${res.statusText}: ${text}` }))
        .catch(() => Promise.reject({ status: "fail", message: res.statusText }))
}

export async function createExcelFile(excelJson: ExcelJson, filename = "excel.xlsx") {
    return fetch(`/api/convert/from-json?filename=${filename}`, {
        method: "POST",
        credentials: 'same-origin',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(excelJson),
    }).then(res => !res.ok
        ? errorResponse(res)
        : res.blob().then(blob => {
            //const filename = re.exec(contentDisposition)[1] ?? "excel.xlsx"
            const urlObj = window.URL.createObjectURL(blob)
            const a = document.createElement('a')
            a.href = urlObj
            a.download = filename
            document.body.appendChild(a) // we need to append the element to the dom -> otherwise it will not work in firefox
            a.click()
            a.remove()  //afterwards we remove the element again         
        })
    )
}

export async function parseExcelFile(excelFile: File, rowsAsObjects: boolean = false) {
    const formData = new FormData()
    formData.append('excel-file', excelFile)

    return parseBackend(fetch(`/api/convert/to-json${rowsAsObjects ? '?rows-as-objects' : ''}`, {
        method: "POST",
        credentials: 'same-origin',
        body: formData,
    }))
}


// EPO
export async function epoLoad(reqBody: object) {
    return parseBackend(fetch("/api/scrape", {
        method: "POST",
        credentials: 'same-origin',
        body: JSON.stringify(reqBody),
        headers: { 'Content-Type': 'application/json' },
    }))
}

export async function uploadEpoImport(excelFile: File) {
    const formData = new FormData()
    formData.append('excel-file', excelFile)

    return parseBackend(fetch("/api/scrape/excel", {
        method: "POST",
        credentials: 'same-origin',
        body: formData,
    }))
}

export async function scrapeEpoImage(publicationNumber: string) {
    return parseBackend(fetch("/api/scrape/image", {
        method: "POST",
        credentials: 'same-origin',
        body: JSON.stringify({ publicationNumber }),
        headers: { 'Content-Type': 'application/json' },
    }))
}

export async function getPortfolioUpdates({ patentFamilyIds }: { patentFamilyIds: number[] }) {
    return parseBackend(fetch("/api/scrape", {
        method: "POST",
        credentials: 'same-origin',
        body: JSON.stringify({ patentFamilyIds, type: "portfolio" }),
        headers: { 'Content-Type': 'application/json' },
    }))
}

// LOGIN
export async function login(user: string, password: string) {
    //const hashed_credentials = Buffer.from(user + ':' + password).toString('base64')
    const hashed_credentials = btoa(user + ':' + password)

    return fetch("/auth/login", {
        headers: { 'Authorization': 'Basic ' + hashed_credentials },
        credentials: 'same-origin',
    }).then(res => res.ok
        ? { status: 'ok' }
        : Promise.reject({ status: 'unauthorized', message: 'wrong user/password combination' }))
}

// Teams and Realms is a synonym
export async function getTeams() {
    return fetch("/auth/realms", {
        credentials: 'same-origin',
        headers: { 'Accept': 'application/json' },
    }).then(res => res.ok ? res.json() : Promise.reject(res)
    ).then(res => (res.realms ?? []).sort((a, b) => a.name.localeCompare(b.name)))
}

// Teams and Realms is a synonym
export async function getTeamToken(team: string) {

    return fetch("/auth/token", {
        method: "POST",
        headers: { 'Content-Type': 'application/json' },
        credentials: 'same-origin',
        body: JSON.stringify({ realm: team, longSession: true }) // TODO: this can be removed at one point but let's keep it in for now
    }).then(res => (res.ok) ? res : Promise.reject(res))
}

export async function signup({ username, password, email }) {
    return parseBackend(fetch("/auth/signup", {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ username, email, password: btoa(password) })
    }))
}

export async function confirmToken(token: string) {
    return parseBackend(fetch("/auth/confirm/" + token))
}

export async function signupTeam({ teamName, displayName }: { teamName: string, displayName: string }) {
    return parseBackend(fetch("/auth/realms", {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ teamName, displayName })
    }))
}

export async function signout() {
    return fetch("/auth/signout")
}

export async function checkToken() {
    return parseBackend(fetch("/auth/token", {
        credentials: 'same-origin'
    }))
}

// Management for user/logins/realms
export async function management(body: object) {
    return parseBackend(fetch("/api/management", {
        method: "POST",
        headers: { 'Content-Type': 'application/json' },
        credentials: 'same-origin',
        body: JSON.stringify(body)
    }))
}

export async function updatePassword(user: string, password: string, oldPassword?: string) {
    return management({ user, password: btoa(password), oldPassword: oldPassword && btoa(oldPassword), operation: "update", type: "login" })
}

export async function resendPassword(email: string, password: string) {
    return management({ email, password: btoa(password), operation: "update", type: "resend" })
}

// Users
export async function loadUsers() {
    return parseBackend(fetch("/api/users"))
}

// Download 
export interface DownloadReportProps {
    url: string
    report?: string
    alternativeFilename?: string
    opts?: object
}
export async function downloadReport({ url, report = "patents-portfolio-report", alternativeFilename = "download.dat", opts = {} }: DownloadReportProps) {
    return fetch(
        url, {
        method: "POST",
        headers: { 'Content-Type': 'application/json' },
        credentials: 'same-origin',
        body: JSON.stringify({ report, realm: loadLocalTeam(), ...opts })
    })
        .then(res => (res.ok ? res.blob() : Promise.reject({ status: "fail", message: res.statusText }))
            .then(blob => {
                if (blob.type === "application/json") { // this is an error
                    return blob.text().then(j => Promise.reject(JSON.parse(j)))
                }
                const contentDisposition = res.headers.get('Content-Disposition')
                const re = /filename="([^"]*)"/
                const filename = re.exec(contentDisposition)[1] ?? alternativeFilename
                const urlObj = window.URL.createObjectURL(blob)
                const a = document.createElement('a')
                a.href = urlObj
                a.download = filename
                document.body.appendChild(a) // we need to append the element to the dom -> otherwise it will not work in firefox
                a.click()
                a.remove()  //afterwards we remove the element again         
            }))
}

////////////////////////////////////////////////////////////////
// DATA

export async function getFxPairs() {
    return parseBackend(fetch("/api/fx"))
}

////////////////
// BILLING
export async function getBillingInformation() {
    return parseBackend(fetch("/api/bills/information", {
        method: "GET",
        credentials: 'same-origin',
    }))
}

export async function postBillingInformation(body: object) {
    return parseBackend(fetch("/api/bills/information", {
        method: "POST",
        headers: { 'Content-Type': 'application/json' },
        credentials: 'same-origin',
        body: JSON.stringify(body)
    }))
}

export async function getPrices() {
    return parseBackend(fetch("/api/bills/prices", {
        method: "GET",
        credentials: 'same-origin',
    }))
}

export async function getBills() {
    return parseBackend(fetch("/api/bills", {
        method: "GET",
        credentials: 'same-origin',
    }))
}

export async function getBillingPlan() {
    return parseBackend(fetch("/api/bills/plans", {
        method: "GET",
        credentials: 'same-origin',
    }))
}

export async function postBillingPlan(body: object) {
    return parseBackend(fetch("/api/bills/plans", {
        method: "POST",
        headers: { 'Content-Type': 'application/json' },
        credentials: 'same-origin',
        body: JSON.stringify(body)
    }))
}

// ADMIN Stuff
export async function getAllBills() {
    return parseBackend(fetch("/api/bills/all", {
        method: "GET",
        credentials: 'same-origin',
    }))
}

export async function getAllPlans() {
    return parseBackend(fetch("/api/bills/plans/all", {
        method: "GET",
        credentials: 'same-origin',
    }))
}

export async function getAllInformations() {
    return parseBackend(fetch("/api/bills/information/all", {
        method: "GET",
        credentials: 'same-origin',
    }))
}

export async function postBill(body: object) {
    return parseBackend(fetch("/api/bills", {
        method: "POST",
        headers: { 'Content-Type': 'application/json' },
        credentials: 'same-origin',
        body: JSON.stringify(body)
    }))
}

export async function deleteBill(team: string, billId: number) {
    return parseBackend(fetch(`/api/bills/${team}/${billId}`, {
        method: "DELETE",
        credentials: 'same-origin',
    }))
}

export const defaultPreviewProperties = [
                'bestBodyPart.content',
                'mailAttachments.pluck(uuid)',
                'mailAttachments.pluck(name)',
                'inTrash',
                'allFolderPath',
                'createDate',
                'updateDate',
                'deleteDate',
                'fromAddress',
                'ccAddress',
                'bccAddress',
                'toAddress',
                'subject',
                'sentDate',
                // 'RFC822Header',  // could be used to also extract addresses etc.
]