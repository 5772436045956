import _ from "lodash"
import { Helmet } from 'react-helmet-async'
import { useFilteredPatents } from "../filter/FilteredPatents"
import { Commodity, CommodityClaimScopeLink, CommodityFamilyLink, commodityUrl, useProductMapping } from "./products"
import { useProducts } from "./ProductsProvider"
import { Trans, useTranslation } from "react-i18next"
import { createContext, Dispatch, SetStateAction, useContext, useState } from "react"
import { getTitle, ProtectionIcon } from "./MappingState"
import { saveCrudLinks } from "../BackendProvider"
import { useClaims } from "../claims/ClaimsProvider"
import { useRoles } from "../user/Auth"
import { useLocalState } from "../settings/localStorage"
import ToggleButton from "../components/ToggleButton"
import { Family } from "../patents/patents"
import { Link } from "react-router-dom"
import { familyUrl, memberUrl } from "../patents/utils"
import { Popover, PopoverButton, PopoverPanel } from "@headlessui/react"
import { PlainImage } from "../components/Image"
import { usePrompt } from "../components/blocker"
import { downloadReport } from "../backend"
import { useMessages } from "../Messages"
import { useFilteredCommodities } from "../filter/FilteredCommodities"
import { usePatents } from "../patents/PatentsProvider"
import { EmptyPortfolioState } from "../patents/Portfolio"

export default function ProductPatentMapping() {
  const {t} = useTranslation()

  const { commodities } = useFilteredCommodities()
  const { families: allFamilies } = usePatents()
  const { families } = useFilteredPatents()

  const noFamilies = families.length === 0
  const noProducts = commodities.length === 0

  return (
    <>
      {/* @ts-ignore */}
      <Helmet>
        <title>{t("product-patent-mapping")} | Patent Cockpit</title>
      </Helmet>
      {noFamilies || noProducts ? (
        <>
          <div className="header-row">
            <h2 className="modern-h2">{t("product-patent-mapping")}</h2>
          </div>
          {noProducts ? (
            <div className="main-content text-center">
              <Trans
                i18nKey="add-products-hint"
                components={{
                  portfoliolink: (
                    <Link
                      className="text-pcx-500 underline-link"
                      to="/products/portfolio"
                    />
                  ),
                }}
              />
            </div>
          ) : (
            <div className="main-content">
              {allFamilies?.length > 0 
                ? <Trans i18nKey="no-patent-results" /> 
                : <EmptyPortfolioState /> }
            </div>
          )}
        </>
      ) : (
        <MappingSettingsProvider>
          <ProductPatentMappingView />
        </MappingSettingsProvider>
      )}{" "}
    </>
  );
}

const headerStyle = "font-normal text-left whitespace-nowrap px-0 bg-pc-50 sticky -top-4 z-10"

function ProductPatentMappingView() {
    const {t} = useTranslation()
    const {hasClaimScopes} = useRoles()

    const {families, memberById} = useFilteredPatents()
    const {commodities} = useFilteredCommodities()
    const { claims: rawClaims, claimScopeById, } = useClaims()

    const {onlyMain, showFamilyMapping} = useMappingSettings()
    const claims = rawClaims.filter(c => !onlyMain || c.claimType === "independent-claim")

    const latestVersionByMember = _(claims)
      .groupBy((c) => c.familyMemberId)
      .mapValues((cs) => {
        const maxDate = _(cs)
          .map((c) => c.versionDate)
          .max();
        return maxDate;
      })
      .value();

    const claimScopesByFamilyId = _(claims)
      .filter(c => c.claimScopeId !== undefined && c.versionDate === latestVersionByMember[c.familyMemberId])
      .map(c => ({claimScopeId: c.claimScopeId, familyMemberId: c.familyMemberId, patentFamilyId: memberById[c.familyMemberId]?.patentFamilyId}))
      .groupBy(c => c.patentFamilyId)
      .mapValues(cs => _.groupBy(cs, c => c.claimScopeId))
      .value()

    const commoditiesByClass = _(commodities)
        .groupBy(c => c.commodityClass ?? t('unclassified'))
        .mapValues(cs => _.sortBy(cs, c => c.commodityReference))
        .toPairs()
        .sortBy(([key]) => key.toLowerCase())
        .value()

    const sortedFamilies = _.sortBy(families, 'internalReference')
    const columnCount = _(sortedFamilies)
      .map(family => 1 +
          (!hasClaimScopes
            ? 0
            : _.size(claimScopesByFamilyId[family.patentFamilyId])))
      .sum();

    return (
      <ProductPatentMappingProvider>
        <div className="modern-header-row flex flex-col lg:flex-row lg:items-baseline lg:gap-6">
          <h2 className="modern-h2 whitespace-nowrap">
            {t("product-patent-mapping")}
          </h2>
          <div className="ml-auto flex flex-row flex-wrap gap-4 text-sm">
            <MappingSettingsView />
          </div>
        </div>
        <div className="grow overflow-x-auto p-4 bg-pc-50 pr-20">
          <table className="relative bg-pc-50 border-r-0">
            <thead>
              <tr>
                <th className="px-0 bg-pc-50 dark:bg-pc-100 sticky -top-4 -left-4 z-20">
                  <div className="h-1 border border-white dark:border-pc-100 w-full" />{" "}
                  {/* border has to be in here for some werid reason */}
                </th>
                {sortedFamilies.map((family) => {
                  const fam = (
                    <th key={family.patentFamilyId} className={headerStyle}>
                        <HeaderCell {...{family}} />
                    </th>
                  );
                  if (hasClaimScopes) {
                    const familyScopes = _(
                      claimScopesByFamilyId[family.patentFamilyId] ?? {}
                    )
                      .keys()
                      .map((claimScopeId: number) => (
                        <th
                          key={family.patentFamilyId + "-" + claimScopeId}
                          className={headerStyle}
                        >
                            <HeaderCell {...{
                                family, 
                                claimScopeId,
                                summary: claimScopeById[claimScopeId]?.claimScopeSummary
                            }} />
                        </th>
                      ))
                      .value();
                    if (showFamilyMapping) return [fam, ...familyScopes];
                    else return familyScopes;
                  } else {
                    return fam;
                  }
                })}
              </tr>
            </thead>
            <tbody>
              {commoditiesByClass.flatMap(([commodityClass, commodities]) => {
                const cs = commodities.map((commodity) => (
                  <tr
                    key={commodity.commodityId}
                    className="bg-pc-50 dark:bg-pc-100 border-t-2 border-pc-200"
                  >
                    <td className="bg-pc-50 leading-5 sticky z-10 group hover:z-20 -left-2 sm:-left-4 ">
                        <ProductCell {...{commodity}} />
                    </td>
                    {sortedFamilies.map((family) => {
                      const fam = (
                        <td key={family.patentFamilyId} className="text-center">
                          <FamilyMappingButton
                            patentFamilyId={family.patentFamilyId}
                            commodityId={commodity.commodityId}
                          />
                        </td>
                      );
                      if (hasClaimScopes) {
                        const familyScopes = _(
                          claimScopesByFamilyId[family.patentFamilyId] ?? {}
                        )
                          .keys()
                          .map((claimScopeId) => (
                            <td
                              key={family.patentFamilyId + "-" + claimScopeId}
                              className="text-center"
                            >
                              <ClaimScopeMappingButton
                                claimScopeId={claimScopeId}
                                commodityId={commodity.commodityId}
                              />
                            </td>
                          ))
                          .value();
                        if (showFamilyMapping) return [fam, ...familyScopes];
                        else return familyScopes;
                      } else {
                        return fam;
                      }
                    })}
                  </tr>
                ));
                return [
                  <tr
                    key={commodityClass}
                    className="pt-2 bg-pc-50 border-t-2 border-pc-250"
                  >
                    <td className="sticky -left-2 sm:-left-4">
                      <div className="px-2 w-32 whitespace-nowrap">
                        <h3 className="text-pcx-500/80 font-medium text-sm">
                          {commodityClass}
                        </h3>
                      </div>
                    </td>
                    {_.range(columnCount).map((i) => (
                      <td key={`${commodityClass}-${i}`}></td>
                    ))}
                  </tr>,
                  ...cs,
                ];
              })}
            </tbody>
          </table>
          <ExplanationFooter />
        </div>
      </ProductPatentMappingProvider>
    );
}

function FamilyMappingButton({patentFamilyId, commodityId}) {
    const {t} = useTranslation()
    const {commodityById} = useProducts()
    const {getFamilyLink, toggleFamilyLink} = useProductPatentMapping()

    const link = getFamilyLink(commodityId, patentFamilyId)

    const isProtected = link?.protected ?? false
    const isUndefined = link === undefined
    const isThirdParty = commodityById[commodityId]?.isThirdParty ?? false

    return <button
        key={`${commodityId}-${patentFamilyId}-${link?.protected ?? '?'}`}
        onClick={() => toggleFamilyLink(commodityId, patentFamilyId, link)}
        title={t(getTitle(isThirdParty, isUndefined, isProtected))}
    >
        <ProtectionIcon {...{isProtected, isUndefined, isThirdParty}} />
    </button>
}

function ClaimScopeMappingButton({claimScopeId, commodityId}) {
    const {t} = useTranslation()
    const {commodityById} = useProducts()
    const {getClaimScopeLink, toggleClaimScopeLink} = useProductPatentMapping()

    const link = getClaimScopeLink(commodityId, claimScopeId)

    const isProtected = link?.protected ?? false
    const isUndefined = link === undefined
    const isThirdParty = commodityById[commodityId]?.isThirdParty ?? false

    return <button
        key={`${commodityId}-${claimScopeId}-${link?.protected ?? '?'}`}
        onClick={() => toggleClaimScopeLink(commodityId, claimScopeId, link)}
        title={t(getTitle(isThirdParty, isUndefined, isProtected))}
    >
        <ProtectionIcon {...{isProtected, isUndefined, isThirdParty}} />
    </button>
}

function ExplanationFooter() {
    const {t} = useTranslation()
    return (
      <div className="flex flex-col lg:flex-row gap-4 lg:gap-6 mt-8 sticky sm:-left-4 pl-2">
        {[
          {
            isUndefined: true,
            isProtected: true,
            isThirdParty: false,
          },
          {
            isUndefined: false,
            isProtected: true,
            isThirdParty: false,
          },
          {
            isUndefined: false,
            isProtected: false,
            isThirdParty: false,
          },
          {
            isUndefined: false,
            isProtected: true,
            isThirdParty: true,
          },
          {
            isUndefined: false,
            isProtected: false,
            isThirdParty: true,
          },
        ].map((state, i) => (
          <div
            key={i}
            className="inline-flex items-center gap-1 text-sm text-slate-500"
          >
            <ProtectionIcon {...state} />
            {t(getTitle(state.isThirdParty, state.isUndefined, state.isProtected))}
          </div>
        ))}
      </div>
    );
}

const ProductPatentMappingContext = createContext({
    getFamilyLink: (commodityId: number, familyId: number) => undefined as CommodityFamilyLink | undefined,
    getClaimScopeLink: (commodityId: number, claimScopeId: number) => undefined as CommodityClaimScopeLink | undefined,
    toggleFamilyLink: (commodityId: number, familyId: number, old: CommodityFamilyLink | undefined) => {},
    toggleClaimScopeLink: (commodityId: number, claimScopeId: number, old: CommodityClaimScopeLink | undefined) => {},

    isEditing: false as boolean,
    setIsEditing: ((b: boolean) => b) as Dispatch<SetStateAction<boolean>>,
    save: () => Promise.resolve(),
    cancel: () => {},
    isSaving: false as boolean,
})

function ProductPatentMappingProvider({children}) {
    const {hasClaimScopes} = useRoles()

    const {linkByCommodityAndFamily, linkByCommodityAndClaimScope} = useProductMapping()
    const {postCommodityFamilyLink, deleteCommodityFamilyLink, postCommodityClaimScopeLink, deleteCommodityClaimScopeLink} = useProducts()

    const [isEditing, setIsEditing] = useState(false)
    const [isSaving, setIsSaving] = useState(false)
    const [workingCopyFamilyLinks, setWorkingCopyFamilyLinks] = useState({})
    const [workingCopyClaimScopeLinks, setWorkingCopyClaimScopeLinks] = useState({})
    // console.log({isEditing, workingCopyFamilyLinks})

    function cancel() {
        setWorkingCopyFamilyLinks({})
        setWorkingCopyClaimScopeLinks({})
        setIsEditing(false)
    }

    async function save() {
        setIsSaving(true)
        //console.log('saving...')

        const newFamilyLinks: CommodityFamilyLink[] = _(workingCopyFamilyLinks)
            .values()
            .flatMap(_.values)
            .filter(Boolean)
            .value()
        const oldFamilyLinks =_(workingCopyFamilyLinks)
            .toPairs()
            .flatMap(([commodityId, links]) => 
                // This is done so, to catch the deleted links
                _(links).toPairs().map(([patentFamilyId]) => linkByCommodityAndFamily[+commodityId]?.[+patentFamilyId]).value())
            .filter(Boolean)
            .value()
            
        //console.log({newFamilyLinks, oldFamilyLinks})
        const savingFamily = saveCrudLinks(oldFamilyLinks, newFamilyLinks, postCommodityFamilyLink, deleteCommodityFamilyLink, isEqualFamilyLink)

        if (hasClaimScopes) {
          const newClaimScopeLinks: CommodityClaimScopeLink[] = _(workingCopyClaimScopeLinks)
            .values()
            .flatMap(_.values)
            .filter(Boolean)
            .value();
          const oldClaimScopeLinks = _(workingCopyClaimScopeLinks)
            .toPairs()
            .flatMap(([commodityId, links]) =>
              // This is done so, to catch the deleted links
              _(links).toPairs().map(
                  ([claimScopeId]) =>
                    linkByCommodityAndClaimScope[+commodityId]?.[+claimScopeId])
                .value()
            )
            .filter(Boolean)
            .value();

          const savingClaimScopes = saveCrudLinks( oldClaimScopeLinks, newClaimScopeLinks, postCommodityClaimScopeLink, deleteCommodityClaimScopeLink, isEqualScopeLink);
          await savingFamily;
          await savingClaimScopes;
        } else {
          await savingFamily;
        }

        setWorkingCopyFamilyLinks({})
        setWorkingCopyClaimScopeLinks({})
        setIsEditing(false)
        setIsSaving(false)
    }

    const getFamilyLink = isEditing 
        ? (commodityId: number, familyId: number) => {
            // done like this so we can set it to undefined to mark a link as deleted
            if (familyId in (workingCopyFamilyLinks[commodityId] ?? {})) {
                return workingCopyFamilyLinks[commodityId][familyId]
            } else {
                return linkByCommodityAndFamily[commodityId]?.[familyId]
            }
        }
        : (commodityId: number, familyId: number) => linkByCommodityAndFamily[commodityId]?.[familyId]
    const getClaimScopeLink = isEditing
        ? (commodityId: number, claimScopeId: number) => {
            // done like this so we can set it to undefined to mark a link as deleted
            if (claimScopeId in (workingCopyClaimScopeLinks[commodityId] ?? {})) {
                return workingCopyClaimScopeLinks[commodityId][claimScopeId]
            } else {
                return linkByCommodityAndClaimScope[commodityId]?.[claimScopeId]
            }
        }
        : (commodityId: number, claimScopeId: number) => linkByCommodityAndClaimScope[commodityId]?.[claimScopeId]

    const toggleFamilyLink = isEditing
        ? (commodityId: number, familyId: number, old: CommodityFamilyLink | undefined) => {
            //console.log('toggeling...')
            const next = nextFamilyLink(commodityId, familyId, old)
            setWorkingCopyFamilyLinks(wc => ({...wc, [commodityId]: {...(wc[commodityId] ?? {}), [familyId]: next}}))
        }
        : () => {}
    const toggleClaimScopeLink = isEditing
        ? (commodityId: number, claimScopeId: number, old: CommodityClaimScopeLink | undefined) =>  {
            //console.log('toggeling...')
            const next = nextClaimScopeLink(commodityId, claimScopeId, old)
            setWorkingCopyClaimScopeLinks(wc => ({...wc, [commodityId]: {...(wc[commodityId] ?? {}), [claimScopeId]: next}}))
        }
        : () => {}

    const value = {
        getFamilyLink, getClaimScopeLink,
        toggleFamilyLink, toggleClaimScopeLink,
        isEditing, setIsEditing,
        save, isSaving, cancel,
    }

    return <ProductPatentMappingContext.Provider {...{value}}>
        {children}
    </ProductPatentMappingContext.Provider>
}

function useProductPatentMapping() {
    return useContext(ProductPatentMappingContext)
}

function nextFamilyLink(commodityId: number, patentFamilyId: number, link: CommodityFamilyLink | undefined) {
    if (!link) {
        return {commodityId, patentFamilyId, protected: false}
    } else if (link.protected) {
        return undefined
    } else { // link.protected == false
        return {...link, protected: true}
    }
}

function nextClaimScopeLink(commodityId: number, claimScopeId: number, link: CommodityClaimScopeLink | undefined) {
    if (!link) {
        return {commodityId, claimScopeId, protected: false}
    } else if (link.protected) {
        return undefined
    } else { // link.protected == false
        return {...link, protected: true}
    }
}


function MappingSettingsView() {
    const {t} = useTranslation()
    const {setErrorMessage} = useMessages()
    const {hasClaimScopes, hasExcelExport} = useRoles()

    const {showPictures, setShowPictures, onlyMain, setOnlyMain, showFamilyMapping, setShowFamilyMapping} = useMappingSettings()
    const {isEditing, setIsEditing, save, isSaving, cancel} = useProductPatentMapping()

    usePrompt(t("leave-site-changes"), isEditing)

    function excelExport() {
      downloadReport({
        url: "/api/excel",
        report: "product-protection-report",
      }).catch((e) => setErrorMessage(e.message));
    }

    return  <>
          {hasExcelExport && <button onClick={excelExport} className="btn-secondary py-0.5">
            {t("excel-export")}
          </button>}
        {isEditing
            ? <>
                <button className="btn-primary btn-loading py-px text-sm font-normal" onClick={save}>{t('save')} {isSaving && <span className="btn-loading-indicator" />}</button>
                <button className="btn-secondary btn-loading py-px text-sm font-normal" onClick={cancel}>{t('cancel')}</button>
            </>
            : <button className="btn-secondary py-px text-sm font-normal" onClick={() => setIsEditing(true)}>Edit</button>}
        <label className="flex flex-row items-center gap-2">
            <span>{t('show-pictures')}</span>
            <ToggleButton checked={showPictures} setChecked={setShowPictures} />
        </label>
        {hasClaimScopes && 
            <label className="flex flex-row items-center gap-2">
                <span>{t('main-claim-only')}</span>
                <ToggleButton checked={onlyMain} setChecked={setOnlyMain} />
            </label>}
        {hasClaimScopes && 
            <label className="flex flex-row items-center gap-2">
                <span>{t('show-family-mapping')}</span>
                <ToggleButton checked={showFamilyMapping} setChecked={setShowFamilyMapping} />
            </label>}
    </>
}

//type CommodityClaimScopeLink = {commodityId: number; claimScopeId: number}

function isEqualScopeLink(a: CommodityClaimScopeLink, b: CommodityClaimScopeLink) {
    return a.commodityId === b.commodityId && a.claimScopeId === b.claimScopeId && a.protected === b.protected
}

//type CommodityFamilyLink = {commodityId: number; patentFamilyId: number}
function isEqualFamilyLink(a: CommodityFamilyLink, b: CommodityFamilyLink) {
    return a.commodityId === b.commodityId && a.patentFamilyId === b.patentFamilyId && a.protected === b.protected
}

// function getTitle(isThirdParty: boolean, isUndefined: boolean, isProtected: boolean) {
//     if (isThirdParty) {
//         if (isUndefined) {
//             return "undefined-clash"
//         } else if (isProtected) {
//             return "clash"
//         } else {
//             return "no-clash"
//         }
//     } else {
//         if (isUndefined) {
//             return "undefined-protection"
//         } else if (isProtected) {
//             return "protected"
//         } else {
//             return "unprotected"
//         }
//     }
// }

const MappingSettings = createContext({
    showPictures: true as boolean,
    setShowPictures: ((b: boolean) => b) as Dispatch<SetStateAction<boolean>>,
    onlyMain: true as boolean,
    setOnlyMain: ((b: boolean) => b) as Dispatch<SetStateAction<boolean>>,
    showFamilyMapping: true as boolean,
    setShowFamilyMapping: ((b: boolean) => b) as Dispatch<SetStateAction<boolean>>,
})

function MappingSettingsProvider({children}) {

    const [showPictures, setShowPictures] = useLocalState('ppm-show-pictures', true)
    const [onlyMain, setOnlyMain] = useLocalState('ppm-only-main', true)
    const [showFamilyMapping, setShowFamilyMapping] = useLocalState('ppm-family-mapping', true)

    const value = {
        showPictures, setShowPictures,
        onlyMain, setOnlyMain,
        showFamilyMapping, setShowFamilyMapping,
    }

    return <MappingSettings.Provider {...{value}}>
        {children}
    </MappingSettings.Provider>
}

function useMappingSettings() {
    return useContext(MappingSettings)
}


function HeaderCell(
    {family, title, summary, claimScopeId}:
    {family: Family, title?: string, summary?: string, claimScopeId?: number}
) {
    const {showPictures} = useMappingSettings()
    const _title = title ?? family.familyName
    const _summary = summary ?? family.summary
    return (
      <div className="px-px">
        <Popover className="relative group">
          <PopoverButton
            as="div"
            className="flex flex-col min-w-[5rem] w-fit text-sm pb-1"
          >
            <Link to={familyUrl(family)} className="underline-link">
              {family.internalReference} {/*<br/> {family} {h.claimScopeId}*/}
            </Link>
            <p
              title={_title}
              className="max-w-[6rem] overflow-hidden text-ellipsis whitespace-nowrap text-2xs"
            >
              {_title}
            </p>
            {showPictures && (
              <div className="h-12 w-20">
                <PlainImage
                  {...{
                    entity: "claim-scope",
                    entityId: claimScopeId,
                    fallback: {
                      entity: "patent-family",
                      entityId: +family.patentFamilyId,
                    },
                    title: family.internalReference,
                    text: _summary,
                    clickable: false,
                  }}
                />
              </div>
            )}
          </PopoverButton>
          <PopoverPanel
            static
            className= "absolute hidden group-hover:block pt-2"
          >
            <div className="p-4 bg-pc-50 border-2 border-pcx-200 rounded-sm shadow-lg grid grid-cols-[auto_auto] gap-4">
                <div className="w-64 space-y-1 whitespace-normal">
                    <Link to={familyUrl(family)} className="underline-link text-lg">{family.internalReference}</Link>
                    <h4 title={_title} className="overflow-hidden line-clamp-4 text-ellipsis">{_title}</h4>
                    <div className="text-sm text-slate-700" dangerouslySetInnerHTML={{__html: _summary}} />
                </div>
                <div className="h-32 w-48">
                    <PlainImage
                        {...{
                            entity: "claim-scope",
                            entityId: claimScopeId,
                            fallback: {
                                entity: "patent-family",
                                entityId: +family.patentFamilyId,
                            },
                            title: family.internalReference,
                            text: _summary,
                            clickable: false,
                        }} />
                </div>
                <div className="col-span-2 space-y-2">
                    {claimScopeId > 0 && <MemberList {...{ claimScopeId }} />}
                    <ProductList
                      {...{
                        claimScopeId,
                        patentFamilyId: family.patentFamilyId,
                      }}
                    />

                </div>
            </div>
          </PopoverPanel>
        </Popover>
      </div>
    );
}

function ProductList({patentFamilyId, claimScopeId}) {
    const {t} = useTranslation()
    const {commoditiesByFamilyId, commoditiesByClaimScopeId} = useProductMapping()
    
    const products = (commoditiesByClaimScopeId[claimScopeId] ?? commoditiesByFamilyId[patentFamilyId] ?? [])

    if (products.length === 0)
        return <div>
            <h5 className="font-base text-slate-500 font-normal">{t('no-affected-products')}</h5>
        </div>
    else
        return (
            <div>
                <h5 className="font-base text-pcx-500 font-normal">{t('affected-products')}</h5>
                {_(products)
                    .map(p => ({ ...p, name: `${p.commodityClass}: ${p.commodityReference}` }))
                    .sortBy('name')
                    .map(p =>
                        <div key={p.commodityId} className="flex flex-row gap-1 text-sm mt-1">
                            <ProtectionIcon isProtected={true} isThirdParty={p.isThirdParty} /> <Link to={commodityUrl(p)}>{p.name}</Link>
                        </div>)
                    .value()
                }
            </div>
        )
}

function MemberList({claimScopeId}: {claimScopeId: number}) {
  const {t} = useTranslation()
  const {membersByClaimScopeId} = useProductMapping()

  const members = membersByClaimScopeId[claimScopeId] ?? []

  const refs = _(members).map(m => m.internalReference).uniq().sortedUniq().value()

  return members.length === 0 
    ? null 
    : <div className="space-y-1">
      <h5 className="text-pcx-500 font-base font-normal">{t('patents')}</h5>
      {refs.map(internalReference =>
        <Link key={internalReference} to={memberUrl({internalReference})}
            className="hover:underline-link text-sm text-pcx-700 after:content-[';_'] last:after:content-['']" 
        >
          {internalReference}
        </Link>)}
    </div>
}


function ProductCell({ commodity }: { commodity: Commodity }) {
  const { showPictures } = useMappingSettings();
  return (
    <Popover className="relative group">
      <PopoverButton className="bg-pc-50 dark:bg-pc-100 pb-1 pl-4 text-left">
        <Link
          className="w-32 block overflow-hidden text-ellipsis"
          to={commodityUrl(commodity)}
        >
          <span className="whitespace-nowrap underline-link text-sm">
            {" "}
            {commodity.commodityReference}
          </span>
          {showPictures && (
            <div className="w-28 h-12">
              <PlainImage
                {...{
                  entity: "commodity",
                  entityId: commodity.commodityId,
                  clickable: false,
                }}
              />
            </div>
          )}
        </Link>
      </PopoverButton>
      <PopoverPanel
        static
        className="absolute hidden group-hover:block top-0 left-full pl-2"
      >
        <div className="p-4 bg-pc-50 border-2 border-pcx-200 rounded-sm shadow-lg ">
          <Link
            className="underline-link whitespace-nowrap text-lg"
            to={commodityUrl(commodity)}
          >
            {commodity.commodityClass && (
              <span className="text-pcx-500">
                {commodity.commodityClass} -{" "}
              </span>
            )}{" "}
            {commodity.commodityReference}
          </Link>
          <div className="grid grid-cols-[auto_auto] gap-4 pt-4">
            <div className="space-y-2">
              <div className="w-48 h-32">
                <PlainImage
                  {...{
                    entity: "commodity",
                    entityId: commodity.commodityId,
                    clickable: false,
                  }}
                />
              </div>
              <p className="w-64 empty:hidden text-sm text-slate-700">
                {commodity.commodityDescription}
              </p>
            </div>
            <PatentList {...commodity} />
          </div>
        </div>
      </PopoverPanel>
    </Popover>
  );
}

function PatentList({commodityId, isThirdParty}) {
    const {t} = useTranslation()
    const {hasClaimScopes} = useRoles()
    const {familiesByCommodityId, claimScopeFamiliesByCommodityId} = useProductMapping()

    const patents = _([
        ...(familiesByCommodityId[commodityId] ?? []),
        ...(hasClaimScopes ? (claimScopeFamiliesByCommodityId[commodityId] ?? []) : [])
    ]).filter(Boolean).unionBy('patentFamilyId').sortBy(f => f.internalReference.toLowerCase()).value()

    return <div className="" > {
        (patents.length === 0) ?
            <h5 className="whitespace-nowrap font-base text-slate-500 font-normal">{t('no-overlapping-patents')}</h5>
            : <>
                <h5 className="whitespace-nowrap font-base text-pcx-500 font-normal">{t('overlapping-patents')}</h5>
                {patents.map(p =>
                    <div key={'patents-' + p.internalReference} className="flex flex-row gap-1 text-sm mt-1">
                        <ProtectionIcon isProtected={true} isThirdParty={isThirdParty} /> <Link className="hover:underline-link tabular-nums" to={familyUrl(p)}>{p.internalReference}</Link>
                    </div>)}
            </>
    }
    </div>
}