import { family_member, patent_family, trade_mark } from "../../data"
import { Family, Member } from "../../patents/patents"
import { familyUrl, memberUrl } from "../../patents/utils"
import { Trademark } from "../../trademarks/TrademarksProvider"
import { trademarkUrl } from "../../trademarks/utils"
import { ReferenceProps } from "./ReferenceLinker"


export function getReference(
    link: {entity?: string, entityId?: number},
    familyById: Record<number, Family>,
    memberById: Record<number, Member>,
    trademarkById: Record<number, Trademark>
): ReferenceProps & {to: string} {

    const entity = link.entity
    const entityId = link.entityId
    let internalReference = undefined
    let to = undefined
    if (entity === patent_family && entityId in familyById) {
        const family = familyById[entityId]
        internalReference = family?.internalReference
        to = familyUrl(family)
    } else if (entity === family_member && entityId in memberById) {
        const member = memberById[entityId]
        internalReference = member?.internalReference
        to = memberUrl(member)
    } else if (entity === trade_mark && entityId in trademarkById) {
        const trademark = trademarkById[entityId]
        internalReference = trademark?.reference
        to = trademarkUrl(trademark)
    }

    return ({
        entity,
        entityId,
        internalReference,
        to,
    })
}

export function isEquivalent(a?: ReferenceProps, b?: ReferenceProps) {
    return a?.entity === b?.entity && a?.entityId === b?.entityId
}