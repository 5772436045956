import React, { useContext, useState } from "react";

export type Message = {
    message: string | JSX.Element
    messageType: "error" | "info"
}

const MessagesContext = React.createContext({
    message: undefined as Message | undefined,
    setMessage: (message: Message) => {},
    // errorMessage: undefined,
    setErrorMessage: (err: string | JSX.Element) => {},
    // infoMessage: undefined,
    setInfoMessage: (info: string | JSX.Element) => {}
})

export function MessagesProvider({children}) {
    const [message, setMessage] = useState(undefined)

    const value = {
        message, setMessage, 
        setErrorMessage: (message: string | JSX.Element) => message ? setMessage({message, messageType: "error"}) : setMessage(undefined),
        setInfoMessage: (message: string | JSX.Element) => message ? setMessage({message, messageType: "info"}) : setMessage(undefined),
    }

    return (
        <MessagesContext.Provider value={value}>
            {children}
        </MessagesContext.Provider>
    )
}

export function useMessages() {
    return useContext(MessagesContext)
}

// error response
export interface ErrorMessage {
    status: string;
    message: string;
}