import React, { useContext } from "react";
import { Commodity, CommodityAgentLink, CommodityClaimScopeLink, CommodityCountryLink, CommodityFamilyLink } from "./products";
import { useCrud, useLinkedCrud } from "../BackendProvider";
import { commodity_agent_link, commodity_claim_scope_link, commodity_country_link, commodity_family_link } from "../data";
import _ from "lodash";
import { useQueryClient } from "@tanstack/react-query";

const ProductsContext = React.createContext({
    commodities: [] as Commodity[],
    postCommodity: (commodity: Commodity) => Promise.resolve(commodity),
    deleteCommodity: (commodity: Commodity) => Promise.resolve({}),
    commodityById: {} as Record<number, Commodity>,
    commodityByReference: {} as Record<string, Commodity>,

    commodityFamilyLinks: [] as CommodityFamilyLink[],
    postCommodityFamilyLink: (links: CommodityFamilyLink | CommodityFamilyLink[]) => Promise.resolve(links) as Promise<CommodityFamilyLink | CommodityFamilyLink[]>,
    deleteCommodityFamilyLink: (links: CommodityFamilyLink | CommodityFamilyLink[]) => Promise.resolve({}),
    commodityClaimScopeLinks: [] as CommodityClaimScopeLink[],
    postCommodityClaimScopeLink: (links: CommodityClaimScopeLink | CommodityClaimScopeLink[]) => Promise.resolve(links) as Promise<CommodityClaimScopeLink | CommodityClaimScopeLink[]>,
    deleteCommodityClaimScopeLink: (links: CommodityClaimScopeLink | CommodityClaimScopeLink[]) => Promise.resolve({}),
    commodityCountryLinks: [], // TODO
    commodityCountryLinksByCommodityId: {} as Record<number, CommodityCountryLink[]>,
    postCommodityCountryLink: (links: CommodityCountryLink | CommodityCountryLink[]) => Promise.resolve(links) as Promise<CommodityCountryLink | CommodityCountryLink[]>,
    deleteCommodityCountryLink: (links: CommodityCountryLink | CommodityCountryLink[]) => Promise.resolve({}),

    commodityAgents: [] as CommodityAgentLink[],
    commodityAgentsByCommodityId: {} as Record<number, CommodityAgentLink[]>,
    postCommodityAgent: (links: CommodityAgentLink | CommodityAgentLink[]) => Promise.resolve(links) as Promise<CommodityAgentLink | CommodityAgentLink[]>,
    deleteCommodityAgent: (links: CommodityAgentLink | CommodityAgentLink[]) => Promise.resolve({}),

    isLoading: false as boolean,
    reload: () => {},
})

export function ProductsProvider({children}) {
    const queryClient = useQueryClient()

    const {data: commodities, postMutation: postCommodity, deleteMutation: deleteCommodity, isLoading: isCommoditiesLoading} =
        useCrud<Commodity>('commodity', c => c.commodityId)

    const {data: commodityFamilyLinks, postMutation: postCommodityFamilyLink, deleteMutation: deleteCommodityFamilyLink, isLoading: isFamilyLinksLoading} =
        useLinkedCrud<CommodityFamilyLink>(commodity_family_link, (a, b) => a.commodityId === b.commodityId && a.patentFamilyId === b.patentFamilyId)
    const {data: commodityClaimScopeLinks, postMutation: postCommodityClaimScopeLink, deleteMutation: deleteCommodityClaimScopeLink, isLoading: isClaimScopesLoading} =
        useLinkedCrud<CommodityClaimScopeLink>(commodity_claim_scope_link, (a, b) => a.commodityId === b.commodityId && a.claimScopeId === b.claimScopeId)
    const {data: commodityCountryLinks, postMutation: postCommodityCountryLink, deleteMutation: deleteCommodityCountryLink, isLoading: isCountriesLoading} =
        useLinkedCrud<CommodityCountryLink>(commodity_country_link, (a, b) => a.commodityId === b.commodityId && a.countryCode === b.countryCode)
    const {data: commodityAgents, postMutation: postCommodityAgent, deleteMutation: deleteCommodityAgent, isLoading: isAgentsLoading} =
        useLinkedCrud<CommodityAgentLink>(commodity_agent_link, (a, b) => a.commodityId === b.commodityId && a.agentId === b.agentId)

    const commodityById = _.keyBy(commodities, c => c.commodityId)
    const commodityByReference = _.keyBy(commodities, 'commodityReference')
    const commodityCountryLinksByCommodityId = _.groupBy(commodityCountryLinks, 'commodityId')
    const commodityAgentsByCommodityId = _.groupBy(commodityAgents, 'commodityId')

    const isLoading = isCommoditiesLoading || isFamilyLinksLoading || isClaimScopesLoading || isCountriesLoading || isAgentsLoading

    function reload() {
        queryClient.invalidateQueries({queryKey: ['commodity']})
        queryClient.invalidateQueries({queryKey: [commodity_family_link]})
        queryClient.invalidateQueries({queryKey: [commodity_claim_scope_link]})
        queryClient.invalidateQueries({queryKey: [commodity_country_link]})
        queryClient.invalidateQueries({queryKey: [commodity_agent_link]})
    }

    // TODO deleteing commodity should do this:
    /*

                  .then(() =>
                    Promise.all(
                      entityOperation(commodity_family_link, "get"),
                      entityOperation(commodity_claim_scope_link, "get")
                    )
                  )
                  */

    const value = {
        commodities,
        postCommodity, deleteCommodity,
        commodityById, 
        commodityByReference,
        commodityFamilyLinks,
        postCommodityFamilyLink, deleteCommodityFamilyLink,
        commodityClaimScopeLinks,
        postCommodityClaimScopeLink, deleteCommodityClaimScopeLink,
        commodityCountryLinks, commodityCountryLinksByCommodityId,
        postCommodityCountryLink, deleteCommodityCountryLink,
        commodityAgents, commodityAgentsByCommodityId,
        postCommodityAgent, deleteCommodityAgent,
        isLoading, reload,
    }

    return (
        <ProductsContext.Provider value={value}>
            {children}
        </ProductsContext.Provider>
    )
}

export function useProducts() {
    return useContext(ProductsContext)
}