import { BoltIcon, BoltSlashIcon } from "@heroicons/react/24/outline";
import { useTranslation } from 'react-i18next'
import _ from "lodash";

import { IconCircleQuestion, IconCircleX, IconShieldCheck } from "../components/icons";


//// Unify code from TMs and patents

export function getTitle(isThirdParty: boolean, isUndefined: boolean, isProtected: boolean) {
    if (isThirdParty) {
        if (isUndefined) {
            return "undefined-clash"
        } else if (isProtected) {
            return "clash"
        } else {
            return "no-clash"
        }
    } else {
        if (isUndefined) {
            return "undefined-protection"
        } else if (isProtected) {
            return "protected"
        } else {
            return "unprotected"
        }
    }
}

export function ProtectionIcon({isThirdParty, isUndefined = false, isProtected}: {isThirdParty: boolean, isUndefined?: boolean, isProtected: boolean}) {
    if (isUndefined) {
        return <span className="text-pcx-700/40"><IconCircleQuestion className="h-5 w-5" /></span>
    } else if (isThirdParty) {
        if (isProtected) {
            return <span className="text-warn-600"><BoltIcon className="h-5 w-5" /></span>
        } else {
            return <span className="text-pcx-700"><BoltSlashIcon className="h-5 w-5" /></span>
        }
    } else {
        if (isProtected) {
            return <span className="text-pcx-700"><IconShieldCheck className="h-5 w-5" /></span>
        } else {
            return <span className="text-warn-600"><IconCircleX className="h-5 w-5" /></span>
        }
    }
}

// link: { commodityId, claimScopeId}
export type TriStateProps<L> = {
  links: L[], fallBack?: L[], setLinks: (ls: L[]) => void, baseLink: Partial<L>, disabled: boolean, isEqualLink: (l1: Partial<L>, l2: Partial<L>) => boolean, isThirdParty: boolean
}

export function TriState<L>({links, fallBack, setLinks, baseLink, disabled, isEqualLink, isThirdParty} : TriStateProps<L>) {
    const {t} = useTranslation()
    const currentIdx = _.findIndex(links, (l) => isEqualLink(l, baseLink))
    const current = currentIdx < 0 ? fallBack?.find(l => isEqualLink(l, baseLink)) : links[currentIdx]
    //console.log(baseLink, links, currentIdx, current)
    const isUndefined = currentIdx < 0 || current === undefined
    // @ts-ignore
    const isProtected = !isUndefined && current.protected
    function nextState() {
        //console.log(baseLink)
        //console.log(current)
        //console.log(links)
        if (isUndefined) {
            setLinks([...links, {...baseLink, protected: false} as L])
        } else if (isProtected) {
            setLinks([...links.slice(0, currentIdx), ...links.slice(currentIdx + 1)])
        } else {
            setLinks([...links.slice(0, currentIdx), {...baseLink, protected: true} as L, ...links.slice(currentIdx + 1)])
        }
    }
    return (
        <button
            title={t(getTitle(isThirdParty, isUndefined, isProtected))}
            disabled={disabled}
            onClick={() => nextState()}
        >
            <ProtectionIcon {...{isThirdParty, isUndefined, isProtected}} />
        </button>

    )
}