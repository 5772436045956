import { Family, Member } from "../patents/patents";

const lookbackYears = 10

// start: start date (yyyy-mm-dd);
// end: end date (yyyy-mm-dd);
// today: today's date (yyyy-mm-dd);
export function aggregateOverYears(members: { start: string, end: string }[], today: string) {
    const currentYear = parseInt(today.slice(0, 4), 10);

    // Prepare counters
    const activeDelta: Record<number, number> = {};
    const started: Record<number, number> = {};
    const stopped: Record<number, number> = {};

    let minYear = currentYear;

    // Parse ranges into events
    for (const { start: startDate, end: endDate } of members) {
        const start = parseInt(startDate.slice(0, 4), 10);
        const end = parseInt(endDate.slice(0, 4), 10);

        if (start < minYear) {
            minYear = start;
        }

        // Mark the beginning of activity
        activeDelta[start + 1] = (activeDelta[start + 1] || 0) + 1;
        // Mark the end of activity
        // Move one year ahead to avoid weird counting
        activeDelta[end + 1] = (activeDelta[end + 1] || 0) - 1;

        // Count starts and stops for exact years
        started[start] = (started[start] || 0) + 1;
        stopped[end] = (stopped[end] || 0) + 1;
    }

    // Calculate active counts year by year
    const result: { year: number; active: number; started: number; stopped: number }[] = [];
    let activeCount = 0;

    for (let year = minYear; year <= currentYear; year++) {
        // Update the active count for the current year
        activeCount += activeDelta[year] || 0;

        // Collect the data for the year
        result.push({
            year,
            active: activeCount,
            started: started[year] || 0,
            stopped: stopped[year] || 0,
        });
    }

    return result.slice(-lookbackYears - 1);
}


export function prepareMembers(members: Member[], familyById: Record<number, Family>, membersByFamilyId: Record<number, Member[]>) {
    // const numbersByFamilyId = _(membersByFamilyId)
    //     .toPairs()
    //     .map(([familyId, members]) => {
    //         const prio = familyById[familyId]?.priorityDate ?? _(members).map('applicationDate').min();
    //         const hasPct = members.some(m => m.pctRouteFiling)
    //         const expiry = _(members).map('expiryDate').max() ?? (prio !== undefined ? plusPeriod(prio, { years: hasPct ? 21 : 20 }) : undefined);
    //         return [familyId, { familyId, prio, expiry }]
    //     })
    //     .fromPairs()
    //     .value()
    return members.map(member => {
        const family = familyById[member.patentFamilyId];
        const start = member.applicationDate ?? family.priorityDate //?? numbersByFamilyId[member.patentFamilyId]?.prio
        const end = member.abandonmentDate ?? member.expiryDate //?? numbersByFamilyId[member.patentFamilyId]?.expiry
        return {
            start, //: start ?? (end ? plusPeriod(end, { years: -20 }) : undefined), // just go backk 20 years if there is no start date
            end,
            reference: member.internalReference,
            familyMemberStatus: member.familyMemberStatus,
        };
    });
}