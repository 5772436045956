import { useQueryClient, useQuery } from "@tanstack/react-query";
//import React from "react";

import { management } from "../backend";
import { Dispatch, SetStateAction } from "react";

export interface TeamFlag {
    flag: string;
    realm: string;
}

export function useTeamFlags(team: string, setStrike?: Dispatch<SetStateAction<number>>) {
    const queryKey = ['team-flags', team]
    const queryClient = useQueryClient()
    async function getFlag() {
        return management({operation: 'get', realm: team, type: 'team-flag'})
            .then(flags => {
                setStrike?.(0)
                return flags
            })
            .catch(e => {
                console.warn('Team Flags: ' + JSON.stringify(e))
                setStrike?.(s => s + 1)
                return []
            })
    }
    const {data: flags} = useQuery<TeamFlag[]>({
        queryKey, 
        queryFn: getFlag,
        placeholderData: [],
        enabled: !!team,
    })

    function asFullFlag(flag: string) {
        return ({flag, realm: team})
    }

    // the next two don't work unless you are a superadmin
    async function addFlags(flags: string[]) {
        return management({operation: 'bulk-add', realm: team, type: 'team-flag', payload: flags.map(asFullFlag)})
            .finally(() => queryClient.invalidateQueries({queryKey}))
    }
    async function removeFlags(flags: string[]) {
        return management({ operation: 'bulk-delete', realm: team, type: 'team-flag', payload: flags.map(asFullFlag) })
            .finally(() => queryClient.invalidateQueries({queryKey}))
    }

    //console.log({flags})
    return {
        flags: flags?.map(f => f.flag) ?? [],
        addFlags,
        removeFlags,
    }
}