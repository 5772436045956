import React, { useContext } from 'react'
import _ from 'lodash'

import { isActiveMember } from '../patents/utils'
import {Family, Member} from '../patents/patents';
import {Claim} from '../claims/MemberClaimsProvider';
import { useFilteredPatents } from '../filter/FilteredPatents';
import { useFilteredCommodities } from '../filter/FilteredCommodities';
import { usePatents } from '../patents/PatentsProvider';
import { useProducts } from './ProductsProvider';
import { useClaims } from '../claims/ClaimsProvider';

export type CommodityStatus = 'active' | 'stopped' | 'planned';

export type Commodity = {
    commodityId: number;
    commodityReference: string;
    commodityClass?: string;
    commodityDescription?: string;
    status: CommodityStatus;
    isThirdParty: boolean;
}

export type CommodityAgentLink = {
    commodityId: number;
    agentId: number;
}

export type CommodityFamilyLink = {
    commodityId: number;
    patentFamilyId: number;
    protected: boolean;
}

export type CommodityClaimScopeLink = {
    commodityId: number;
    claimScopeId: number;
    protected: boolean;
}

export type CommodityCountryLink = {
    commodityId: number;
    countryCode: string;
}

const ProductMappingContext = React.createContext({
        // from filter context
        families: [] as Family[], 
        familyById: {} as {[key: number]: Family}, 
        members: [] as Member[], 
        membersByFamilyId: {} as {[key: number]: Member[]}, 
        commodities: {} as {[key: number]: Commodity},
        // calculated
        membersByClaimScopeId: {} as {[key: number]: (Member & {claim: Claim})[]},
        membersByCommodityId: {} as {[key: number]: Member[]},
        familiesByCommodityId: {} as {[key: number]: Family[]},
        claimScopeMembersByCommodityId: {} as {[key: number]: Member[]},
        claimScopeFamiliesByCommodityId: {} as {[key: number]: Family[]},

        commoditiesByFamilyId: {} as {[key: number]: Commodity[]},
        commoditiesByMemberId: {} as {[key: number]: Commodity[]},
        commoditiesByClaimScopeId: {} as {[key: number]: Commodity[]},
        claimScopeCommoditiesByFamilyMemberId: {} as {[key: number]: Commodity[]},
        claimScopeCommoditiesByPatentFamilyId: {} as {[key: number]: Commodity[]},

        linkByCommodityAndFamily: {} as {[key: number]: {[key: number]: CommodityFamilyLink}},
        linkByCommodityAndClaimScope: {} as {[key: number]: {[key: number]: CommodityClaimScopeLink}},
})

export function ProductMappingProvider({children}) {

    const { memberById } = usePatents()
    const {
        commodityClaimScopeLinks,
        commodityFamilyLinks,
        //claimScopes, // TODO: claimScopes by member id
    } = useProducts()
    const { claims, } = useClaims()

    const { families, familyById, members, membersByFamilyId  } = useFilteredPatents()
    const { commodities } = useFilteredCommodities()

    const commodityById = _.keyBy(commodities, c => c.commodityId)

    const membersByClaimScopeId = calcMembersByClaimScopeId(claims, memberById)

    // Get for each commodity all patents linked by family
    const membersByCommodityId = calcMembersByCommodityId(commodityFamilyLinks, membersByFamilyId)

    // Get for each commodity all patents linked by claim
    const claimScopeMembersByCommodityId = calcClaimScopeMembersByCommodityId(commodityClaimScopeLinks, membersByClaimScopeId)

    // Get for each family all commodities linked by family
    const commoditiesByFamilyId = calcCommoditiesByFamilyId(commodityFamilyLinks, commodityById)
    const commoditiesByMemberId = calcCommoditiesByMemberId(memberById, commoditiesByFamilyId)

    const commoditiesByClaimScopeId = calcCommoditiesByClaimScopeId(commodityClaimScopeLinks, commodityById)
    // Get for each member all commodities linked by claim
    const claimScopeCommoditiesByFamilyMemberId = calcClaimScopeCommoditiesByFamilyMemberId(claims, commoditiesByClaimScopeId)

    // Get for each family all commodities linked by claim
    const claimScopeCommoditiesByPatentFamilyId = calcClaimScopeCommoditiesByPatentFamilyId(membersByFamilyId, claimScopeCommoditiesByFamilyMemberId)

    const claimScopeFamiliesByCommodityId = _(claimScopeMembersByCommodityId)
        .mapValues(members => 
            _(members)
                .map(m => m?.patentFamilyId)
                .uniq()
                .map(id => familyById[id])
                .filter(Boolean)
                .value())
        .pickBy(families => families?.length > 0)
        .value()

    const familiesByCommodityId = _(membersByCommodityId)
        .mapValues(members => 
            _(members)
                .map(m => m?.patentFamilyId)
                .uniq()
                .map(id => familyById[id])
                .filter(Boolean)
                .value())
        .pickBy(families => families?.length > 0)
        .value()

    const linkByCommodityAndFamily = _(commodityFamilyLinks)
      .groupBy((l) => l.commodityId)
      .mapValues((l) => _.keyBy(l, (l) => l.patentFamilyId))
      .value();
    const linkByCommodityAndClaimScope = _(commodityClaimScopeLinks)
      .groupBy((l) => l.commodityId)
      .mapValues((l) => _.keyBy(l, (l) => l.claimScopeId))
      .value();

    const value = {
        // from filter context
        families, 
        familyById, 
        members, 
        membersByFamilyId, 
        commodities,
        // calculated
        membersByClaimScopeId,
        membersByCommodityId,
        familiesByCommodityId,
        claimScopeMembersByCommodityId,
        claimScopeFamiliesByCommodityId,
        commoditiesByFamilyId,
        commoditiesByMemberId,
        commoditiesByClaimScopeId,
        claimScopeCommoditiesByFamilyMemberId,
        claimScopeCommoditiesByPatentFamilyId,

        linkByCommodityAndFamily,
        linkByCommodityAndClaimScope,
    }

    return <ProductMappingContext.Provider value={value} >
        {children}
    </ProductMappingContext.Provider>
}

export function useProductMapping() {
    return useContext(ProductMappingContext)
}

// == helpers 
export function calcMembersByCommodityId(commodityFamilyLinks: CommodityFamilyLink[], membersByFamilyId: {[key: number]: Member[]}) {
    return _(commodityFamilyLinks)
        .groupBy('commodityId')
        .mapValues(familyLinks => familyLinks
            .filter(l => l.protected)
            .flatMap(l => membersByFamilyId[l.patentFamilyId] ?? [])
            .filter(isActiveMember))
        .toPairs()
        .filter(([, members]) => members.length > 0)
        .fromPairs().value()
}

export function calcCommoditiesByFamilyId(commodityFamilyLinks: CommodityFamilyLink[], commodityById: {[key: number]: Commodity}) {
    return _(commodityFamilyLinks)
        .groupBy('patentFamilyId')
        .mapValues(familyLinks => familyLinks
            .filter(l => l.protected)
            .map(l => commodityById[l.commodityId])
            .filter(Boolean))
        .toPairs()
        .filter(([, commodities]) => commodities.length > 0)
        .fromPairs().value()
}

export function calcCommoditiesByMemberId(
    memberById: { [key: number]: Member },
    commoditiesByFamilyId: { [key: number]: Commodity[] }
): { [key: number]: Commodity[] } {
    return _(memberById).toPairs()
        .map(([id, member]) => [id, commoditiesByFamilyId[member.patentFamilyId] ?? []])
        .filter(([, commodities]) => commodities.length > 0)
        .fromPairs().value()
}

export function calcMembersByClaimScopeId(claims: Claim[], memberById: {[key: number]: Member}): Record<number, (Member & {claim: Claim})[]> {

    // Calculate the highest vesion first
    const maxDates = _(claims)
        .groupBy('familyMemberId')
        .mapValues(cs => _.maxBy(cs, 'versionDate')?.versionDate)
        .value()

    return _(claims)
        .filter(c => c.claimScopeId !== undefined)
        .groupBy('claimScopeId')
        .mapValues(cs => cs
            .filter(c => c.versionDate === maxDates[c.familyMemberId])
            .map(claim => ({...memberById[claim.familyMemberId], claim}))
            .filter(isActiveMember))
        .toPairs().filter(([id, values]) => values.length > 0).fromPairs()
        .value()
}

export function calcClaimScopeMembersByCommodityId(commodityClaimScopeLinks: CommodityClaimScopeLink[], membersByClaimScopeId: {[key: number]: Member[]}) {
    return _(commodityClaimScopeLinks)
        .filter(l => l.protected)
        .groupBy('commodityId')
        .mapValues(claimLinks => claimLinks.flatMap(l => membersByClaimScopeId[l.claimScopeId] ?? []))
        .toPairs().filter(([id, values]) => values.length > 0).fromPairs()
        .value()
}

export function calcCommoditiesByClaimScopeId(commodityClaimScopeLinks: CommodityClaimScopeLink[], commodityById: {[key: number]: Commodity}) { 
    return _(commodityClaimScopeLinks)
        .filter(l => l.protected)
        .groupBy('claimScopeId')
        .mapValues(claimLinks => claimLinks.map(l => commodityById[l.commodityId]).filter(Boolean))
        .toPairs().filter(([id, values]) => values.length > 0).fromPairs()
        .value()
}

export function calcClaimScopeCommoditiesByFamilyMemberId(claims: Claim[], commoditiesByClaimScopeId: {[key: number]: Commodity[]}) {
    // Calculate the highest vesion first
    const maxDates = _(claims)
        .groupBy('familyMemberId')
        .mapValues(cs => _.maxBy(cs, 'versionDate')?.versionDate)
        .value()

    return _(claims)
        .filter(c => c.claimScopeId !== undefined)
        .groupBy('familyMemberId')
        .mapValues(cs => cs
                .filter(c => c.versionDate === maxDates[c.familyMemberId])
                .flatMap(c => commoditiesByClaimScopeId[c.claimScopeId] ?? []))
        .toPairs().filter(([id, values]) => values.length > 0).fromPairs()
        .value()
}

export function calcClaimScopeCommoditiesByPatentFamilyId(membersByFamilyId: {[key: number]: Member[]}, claimScopeCommoditiesByFamilyMemberId: {[key: number]: Commodity[]}) {
    return _(membersByFamilyId)
        .mapValues(members => _(members)
            .flatMap(m => claimScopeCommoditiesByFamilyMemberId[m.familyMemberId] ?? [])
            .uniqBy('commodityId')
            .value())
        .toPairs().filter(([id, values]) => values?.length > 0).fromPairs()
        .value()
}


export function useClaimScopes() {
    const {memberById, membersByFamilyId } = usePatents()
    const {claims, claimScopes} = useClaims()
    const membersByClaimScopeId = calcMembersByClaimScopeId(claims, memberById)

    const claimScopeIdsByMemberId = _(claims)
        .groupBy('familyMemberId')
        .mapValues(cs => {
            const versionDate = _(cs).map(c => c.versionDate).max()
            const latest = _(cs)
                .filter(c => c.versionDate === versionDate && c.claimScopeId !== undefined)
                .map(c => c.claimScopeId)
                .uniq().sortBy().value()
            return latest
        })
        .value()

    const claimScopeIdsByFamilyId = _(membersByFamilyId)
        .mapValues(members =>
            _(members)
                .flatMap(m => claimScopeIdsByMemberId[m.familyMemberId] ?? [])
                .uniq().sortBy().value())
        .value()

    const claimscopeById = _.keyBy(claimScopes, 'claimScopeId')

    return {
        membersByClaimScopeId,
        claimScopeIdsByMemberId,
        claimScopeIdsByFamilyId,
        claimscopeById,
    }
}

export function commodityName({ commodityClass, commodityReference }: Pick<Commodity, 'commodityClass' | 'commodityReference'>, unclassified = 'unclassified') {
    return `${commodityClass ?? unclassified}: ${commodityReference}`
}

export function commodityUrl({commodityId}: {commodityId: number}) {
    return `/products/portfolio/product/${commodityId}`
}

export function commodityReferenceUrl({reference}: {reference: string}) {
    return `/products/portfolio/product/by-ref/${reference}`
}


export function calculateClaimScopesByMemberId (claims: Claim[]) {
    return _(claims)
        .groupBy(c => c.familyMemberId)
        .mapValues(cs => {
            const maxDate = _(cs).map(c => c.versionDate).max()
            return _(cs)
                .filter(c => c.versionDate === maxDate)
                .map(c => c.claimScopeId)
                .filter(Boolean)
                .sortBy()
                .value()
        })
        .value()
}