import { useState } from "react";
import { get_realm_overview, get_realm_statistics } from "../dennemeyer_backend";
import DateRangePicker from "../../components/input/DateRangePicker";
import clsx from "clsx";
import _ from "lodash";
import { MaintenanceActionPhase } from "../DennemeyerProvider";
import { createExcelFile } from "../../backend";
import { useMessages } from "../../Messages";
import { IconSpinner } from "../../components/icons";

export default function RenwalsStatistics() {

    const [start, setStart] = useState('2024-01-01')
    const [end, setEnd] = useState('2024-12-31')

    const [statistics, setStatistics] = useState(undefined)

    async function onClick() {
        const stats = await get_realm_statistics(start, end)
        setStatistics(stats)
        // console.log({ stats })
    }

    return (
        <>
            <div className="flex flex-row items-baseline gap-4 ">
                <label className="label">Range</label>
                <DateRangePicker className="py-1 px-1.5" {...{minDate: start, setMinDate: setStart, maxDate: end, setMaxDate: setEnd, name: ''}} />
                <button className="btn-primary" onClick={onClick}>Get Statistics</button>
            </div>

            <div className="pt-4">
                {statistics === undefined
                    ? <p>Click the button to get the statistics.</p>
                    : <StatisticsTable {...{statistics, start, end}} />
                }
                <>
                </>
            </div>
        </>
    );
}

interface Stats {
    droppedFees: number
    end: string
    openFees: number
    paidFees: number
    realm: string
    start: string
    totalFees: number
}

const fields = [
    'realm',
    'openFees',
    'droppedFees',
    'paidFees',
    'totalFees'
]
const headerClass = "px-2.5 py-2 text-pcx-600 text-sm font-semibold uppercase tracking-wider whitespace-nowrap"

function StatisticsTable({ statistics, start, end }: { statistics: Stats[], start: string, end: string }) {
    const {setErrorMessage} = useMessages()
    const [isLoading, setIsLoading] = useState(false)

    const [selected, setSelected] = useState(_.fromPairs(statistics.map(s => [s.realm, true])))

    const total = statistics.reduce((acc, stats) => {
        if (!selected[stats.realm]) return acc

        fields.forEach(field => {
            acc[field] += stats[field]
        })
        return acc
    }, _.fromPairs(fields.map(field => [field, 0])))

    const isAll = _.values(selected).every(v => v)

    async function getOverview() {
        setIsLoading(true)
        const overview: SingleOverview[] = await get_realm_overview(start, end)
        const filtered = isAll ? overview : overview.filter(o => selected[o.realm])
        const completed = completeData(filtered)

        const as_excel = {
            sheets: [
                {
                    name: 'Overview',
                    header: _.keys(completed[0]),
                    rows: completed,
                }
            ]
        }

        await createExcelFile(as_excel, `PC_DM_Statistics_${start}_${end}.xlsx`)
            .catch(err => setErrorMessage(err.message))
            .finally(() => setIsLoading(false))
        // console.log({ overview })
    }

    return <>
        <table>
            <thead>
                <tr className="border-b-2 border-pcx-300">
                    {fields.map(field =>
                        <th key={field} className={clsx('text-left', headerClass)}>
                            {field === 'realm' && <input type="checkbox" className="form-checkbox inline-block mr-2"
                                checked={isAll}
                                onChange={() => setSelected(_.fromPairs(statistics.map(s => [s.realm, !isAll])))}
                            />}
                            {_.snakeCase(field).replace('_', ' ')}
                        </th>)}
                    <th className={clsx('text-left', headerClass, 'text-slate-400')}>
                        Difference
                    </th>
                </tr>
            </thead>
            <tbody>
                {statistics.map((stats, index) => {
                    return <tr key={index}>
                        {fields.map(field =>
                            <td key={field} className={clsx(field === 'realm' ? 'text-left' : 'text-right', 'px-2.5')}>
                                {field === 'realm' && 
                                    <input type="checkbox" className="form-checkbox inline-block mr-2"
                                        checked={selected[stats.realm]}
                                        onChange={() => setSelected({ ...selected, [stats.realm]: !selected[stats.realm] })} 
                                    />}
                                {stats[field]}
                            </td>)}
                        <td className="text-right px-2.5 text-slate-400">
                            {stats.totalFees - stats.droppedFees - stats.paidFees - stats.openFees}
                        </td>
                    </tr>
                })}
            </tbody>
            <tfoot>
                <tr className="border-t-2 border-pcx-300 font-semibold text-pcx-600">
                    {fields.map(field =>
                        <td key={field} className={clsx(field === 'realm' ? 'text-left pl-9' : 'text-right px-2.5')}>
                            {field === 'realm' ? 'Total' : total[field]}
                        </td>)}
                    <td className="text-right px-2.5 text-slate-400">
                        {total.totalFees - total.droppedFees - total.paidFees - total.openFees}
                    </td>
                </tr>
            </tfoot>
        </table>
        <div className="py-4">
        <button onClick={getOverview} className="btn-primary whitespace-nowrap flex flex-row items-center gap-2">Get Full Overview as Excel {isLoading && <IconSpinner className="animate-spin size-4"/>}</button>
        </div>
    </>
}

/*

Numbers is about invoiced patents ("is paid")

                   BRONZE       SILVER     GOLD       PLATINUM
                   (0-10k)      (10k-30k)  (50k-100k) (>100k)
Small client        1.5 EUR      3.0 EUR    4.5 EUR    6.0 EUR
(<100)

Medium Client       0.6 EUR      1.2 EUR    1.8 EUR    2.4 EUR
(100-2000)

Large Client        0.4 EUR     0.8 EUR    1.2 EUR    1.6 EUR
(>2000)
*/

function getReferralPayment(total_pc: number, total_realm: number) {
    if (total_pc <= 100000) {
        // BRONZE
        if (total_realm <= 100) return 1.5
        else if (total_realm <= 2000) return 0.6
        else return 0.4
    } else if (total_pc <= 300000) {
        // SILVER
        if (total_realm <= 100) return 3.0
        else if (total_realm <= 2000) return 1.2
        else return 0.8
    } else if (total_pc <= 1000000) {
        // GOLD
        if (total_realm <= 100) return 4.5
        else if (total_realm <= 2000) return 1.8
        else return 1.2
    } else {
        // PLATINUM
        if (total_realm <= 100) return 6.0
        else if (total_realm <= 2000) return 2.4
        else return 1.6
    }
}

type SingleOverview = {
    realm: string
    Phase: MaintenanceActionPhase
    DueDate: string
    CustomerReference: string
    CountryCode: string
}

function isPaid(phase: MaintenanceActionPhase) {
    return phase === 'Auto-Pay in Progress' || phase === 'Closed' || phase === 'Instructed / In Progress'
}

function completeData(overview: SingleOverview[]) {
    const total_pc = overview.length
    const total_by_realm = _.countBy(overview, 'realm')
    return overview
        .map(o => {
            const p = isPaid(o.Phase) ? 'yes' : 'no'
            return {
            ...o,
            isPaid: p,
            Amount: p ? getReferralPayment(total_pc, total_by_realm[o.realm]) : 0
        }})
}

function MaOverview({overview}: {overview: SingleOverview[]}) {
    //{realm: "WoodWelding", Phase: "Auto-Pay in Progress", DueDate: "2024-06-12", CustomerReference: "P3470 EP-GB", CountryCode: "GB"}
    const header = ['realm', 'Phase', 'isPaid', 'DueDate', 'CustomerReference', 'CountryCode', 'Amount']

    const data = completeData(overview)

    return (
      <div className="grid grid-cols-[repeat(7,auto)] gap-2 text-xs">
        {header.map((h) => (
          <div key={h} className={clsx(headerClass, "text-left")}>
            {h}
          </div>
        ))}

        {data.flatMap((ma, index) => {
          return header.map((h) => (
            <div key={h + '-' + index} className="px-2"> {ma[h]} </div>
          ));
        })}
      </div>
    );
}