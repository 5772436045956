import { useState } from "react";

export default function LoadingButton(props) {
  const { onClick, children, className, ...rest } = props;

  const [isLoading, setIsLoading] = useState(false);

//   console.log({isLoading, onClick})

  return (
    <button
      className={`${className ?? ""} btn-loading`}
      disabled={isLoading}
      {...rest}
      onClick={(e) => {
        setIsLoading(true);

        const result = onClick(e);
        if (isPromise(result)) {
            return result.finally(() => setIsLoading(false));
        } else {
            setIsLoading(false);
        }
      }}
    >
      {children}
      {isLoading && <span className="btn-loading-indicator" />}
    </button>
  );
}

function isPromise<T>(value: unknown): value is Promise<T> {
  return !!value && typeof (value as any).then === "function";
}