import React, { Dispatch, SetStateAction, useEffect, useState } from "react"
import { useContext } from "react"
import { Navigate, useLocation, useNavigate } from "react-router"

import { 
    getTeams, 
    getTeamToken as getTeamToken_req, 
    login, 
    signup as signup_req, 
    signupTeam as signupTeam_req,
    signout, 
} from "../backend"
import { useMessages } from "../Messages"
import { RolesContext } from "./RolesProvider"

function storeLocalUser(user: string) {
    window.localStorage.setItem('user', user)
}
function loadLocalUser(): string {
    return window.localStorage.getItem('user')
}
function removeLocalUser() {
    window.localStorage.removeItem('user')
}

function storeLocalTeam(team) {
    window.localStorage.setItem('team', team)
}

export function loadLocalTeam() {
    return window.localStorage.getItem('team')
}
function removeLocalTeam() {
    window.localStorage.removeItem('team')
}

const AuthContext = React.createContext({
    signin: (user: string, password: string) => Promise.resolve({}),
    signup: ({username, password, email}: {username: string, password: string, email: string}) => Promise.resolve({}),
    signout: () => Promise.resolve({}),
    //getRealms: () => Promise.resolve([]),
    getTeamToken: (team: string) => Promise.resolve({} as Response),
    signupTeam: ({teamName, displayName}: {teamName: string, displayName?: string}) => Promise.resolve({}),
    user: undefined,
    team: undefined as (string | undefined),
    teams: [],
    strike: 0,
    setStrike: ((s: number) => {}) as Dispatch<SetStateAction<number>>,
})

// TODO: split into UserProvider and TeamProvider
export function AuthProvider({children}) {

    const [user, setUser] = useState(loadLocalUser())
    const [team, setTeam] = useState(loadLocalTeam())

    //const {data: teams} = useQuery({queryKey: queryKey_teams, queryFn: getTeams, enabled: user !== undefined})
    const [teams, setTeams] = useState([])
    //console.log(realms)

    // Count strikes for unauthorized requests
    const [strike, setStrike] = useState(0)

    useEffect(() => {
      getTeams()// args passed in to make the linter happy
        .then(setTeams) 
    }, [user, team])

    async function signin(user: string, password: string) {
        return login(user, password).then(res => {
            setUser(user)
            storeLocalUser(user)
            setStrike(0)
            return res
        })
    }

    function signup({ username, email, password }) {
        return signup_req({ username, email, password })
            .then(() => signin(username, password))
    }

    async function getTeamToken(team: string) {
        return getTeamToken_req(team).then(res => {
            setTeam(team)
            storeLocalTeam(team)
            setStrike(0)
            return res
        })
    }

    function signupTeam({teamName, displayName}: {teamName: string, displayName?: string}) { 
        return signupTeam_req({teamName, displayName})
            //.then(() => getTeamToken(teamName, true))
    }

    const value = {
        signin,
        signup,
        signupTeam,
        signout: () => {
            setUser(null)
            setTeam(null)
            setStrike(0)
            removeLocalUser()
            removeLocalTeam()
            setTeams([])
            return signout()
        },
        getTeamToken,
        user,
        team,
        teams,
        strike,
        setStrike,
    }

    return (
        <AuthContext.Provider value={value}>
            {children}
        </AuthContext.Provider>
    )
}

export function useAuth() {
    return useContext(AuthContext)
}

export function useRoles() {
    return useContext(RolesContext)
}


const strikeLimit = 3
export function RequireAuth({autoSignOut = true, children}) {

    const {signout, user, team, strike} = useAuth()
    const location = useLocation()
    const navigate = useNavigate()
    const { setMessage } = useMessages()
    
    // TODO: if more than 3? Strikes, explicitly check token and if then it is still invalid, sign out
    if (strike > 0) {
        console.warn(`Unauthorized request! Strike ${strike} of ${strikeLimit}.`)
    }
    const isNotValid = strike > strikeLimit

    if (isNotValid) {
        console.warn(`More than ${strikeLimit} strikes! Signing out...`)
    }

    if (autoSignOut && isNotValid && !location.pathname.startsWith("/teams")) {
        setMessage(undefined)
        signout().then(() =>
            navigate("/login", {
                state: { from: location.pathname !== '/teams' && location },
                replace: true,
            }))
    }
    
    if (!user || isNotValid || (!team && !location.pathname.startsWith("/teams"))) {
        return <Navigate to="/login" state={{ from: location.pathname !== '/teams' && location }} replace />
    } else
        return children
}

export function SignOut() {
    const {signout} = useAuth()
    const {setMessage} = useMessages()
    const [signoutState, setSignoutState] = useState(1) // 1: not yet, 2: in the process, 3: done

    useEffect(() => {
        if (signoutState === 1) {
            setSignoutState(2)
            setMessage(undefined)
            signout().then(() => setSignoutState(3))
        }
    }, [signoutState, signout, setMessage])
    
    if (signoutState === 3)
        return <Navigate to="/login" />
    else   
        return <div>Signing out...</div>
}
