import { createContext, useContext } from "react";
import { useCrud } from "../BackendProvider";
import _ from "lodash";
import { useRoles } from "../user/Auth";

export type Income = {
    incomeId?: number,
    commodityId: number,
    countryCode: string,
    amount: number,
    currency: string,
    year: number,
}

/** commodityId -> countryCode -> year -> Income */
export type IncomeLookup = Record<number, Record<string, Record<number, Income>>>

const IncomeContext = createContext({
    incomes: [] as Income[],
    postIncome: (income: Income) => Promise.resolve({}),
    deleteIncome: (income: Income) => Promise.resolve({}),

    incomeLookup: {} as IncomeLookup,
    isLoading: false as boolean,
    reload: () => {},
});

// const initial_incomes = [
//     { incomeId: 1, commodityId: 92, countryCode: 'US', amount: 100, year: 2020 },
//     { incomeId: 2, commodityId: 92, countryCode: 'US', amount: 200, year: 2021 },
//     { incomeId: 3, commodityId: 92, countryCode: 'US', amount: 300, year: 2022 },
//     { incomeId: 4, commodityId: 92, countryCode: 'CH', amount: 1000, year: 2021 },
//     { incomeId: 5, commodityId: 92, countryCode: 'CH', amount: 2000, year: 2022 },
// ]

export function IncomeProvider({children}: {children: React.ReactNode}) {
    //const {data: incomes, isLoading, postMutation: postIncome, deleteMutation: deleteIncome} = useDummyCrud<Income>('income', 'incomeId', initial_incomes)
    const {hasRevenues} = useRoles()
    const {data: incomes, isLoading, postMutation: postIncome, deleteMutation: deleteIncome} = useCrud<Income>('income', i => i.incomeId, hasRevenues)

    // console.log({incomes})

    const incomeLookup = _(incomes)
        .groupBy('commodityId')
        .mapValues(_is => _(_is)
            .groupBy('countryCode')
            .mapValues(byCc => _.keyBy(byCc, c => c.year))
            .value()
        )
        .value()
    const reload = () => {} // TODO

    const value = {
        incomes, postIncome, deleteIncome,
        incomeLookup,
        isLoading, reload,
    }

    return (
        <IncomeContext.Provider {...{value}}>
            {children}
        </IncomeContext.Provider>
    )
}

export function useIncome() {
    return useContext(IncomeContext)
}