import { createContext, useContext } from "react";
import { useCrud, useLinkedCrud } from "../BackendProvider";
import _ from "lodash";
import { family_member } from "../data";
import { isEquivalent } from "../components/input/references";
import { useRoles } from "../user/Auth";

export interface CostItem {
    costId?: number;
    date: string;
    amount: number;
    vat: number;
    currency: string;
    reference: string;
    costCenter?: string;
    comment: string;
}

// TODO: delete when deleting member or family
export interface CostLink {
    costId: number;
    entityId: number;
    entity: string;
}

/** entity -> entity id -> Cost[] */
export type CostsLookup = Record<string, Record<number, CostItem[]>>

const CostContext = createContext({
    costs: [] as CostItem[],
    postCost: (cost: CostItem) => Promise.resolve(cost),
    deleteCost: (cost: CostItem) => Promise.resolve({}),

    costLinks: [] as CostLink[],
    postCostLink: (link: CostLink) => Promise.resolve({}),
    deleteCostLink: (link: CostLink) => Promise.resolve({}),

    costById: {} as Record<number, CostItem>,

    costsByMemberId: {} as Record<number, CostItem[]>,
    costsByEntity: {} as CostsLookup,
    linkByCostId: {} as Record<number, CostLink>,
})

export function CostProvider({children}) {
    const {hasCosts} = useRoles()

    const {data: costs, postMutation: postCost, deleteMutation: deleteCost} = useCrud<CostItem>('cost-item', c => c.costId, hasCosts, ['cost-link'])

    const {data: costLinks, postMutation: postCostLink, deleteMutation: deleteCostLink} = useLinkedCrud<CostLink>('cost-link', isEquivalent, hasCosts)

    const costById = _.keyBy(costs, c => c.costId)

    const costsByEntity = _(costLinks)
        .groupBy(l => l.entity)
        .mapValues(ls => _(ls)
            .groupBy(l => l.entityId)
            .mapValues(ls => ls.map(l => costById[l.costId]).filter(Boolean))
            .value())
        .value()

    const costsByMemberId = costsByEntity[family_member] || {}

    const linkByCostId = _.keyBy(costLinks, l => l.costId)

    //console.log({costs, costLinks})

    return <CostContext.Provider value={{
        costs, postCost, deleteCost,
        costLinks, postCostLink, deleteCostLink,
        costById,
        costsByMemberId, costsByEntity,
        linkByCostId,
    }}>
        {children}
    </CostContext.Provider>
}

export function useCosts() {
    return useContext(CostContext)
}   