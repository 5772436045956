import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import clsx from 'clsx'
import { SideBarBadge } from "../components/badges";
import _ from 'lodash'

import { Member } from "../patents/patents";
import { useDennemeyer, useDmSettings, useMaintenances, useTimeline } from "./DennemeyerProvider";
import ProtectionIcon from "../components/ProtectionIcon";
import { family_member, patent_family } from "../data";
import { PlainImage } from "../components/Image";
import { useRoles } from "../user/Auth";
import { commodityUrl, useProductMapping } from "../products/products";
import { scoreBackground, useFamilyScores, useMemberScores } from "../valuations/scores";
import { useComments } from "../comments/CommentsProvider";
import { SmallComment } from "../comments/Comments";
import { useFilteredPatents } from "../filter/FilteredPatents";
import { Disclosure, DisclosureButton, DisclosurePanel } from "@headlessui/react";
import { useTags } from "../tags/TagsProvider";
import { IconChevronDown, IconChevronUp } from "../components/icons";

function UnfoldingSections({children}) {
    return (
        <div className="flex flex-col border-b-2 border-pcx-300 h-fit">
            {children}
        </div>
    )
}

function UnfoldingSection({title, preview = undefined, children}) {
    return (
        <Disclosure>{({open}) => <>
            <DisclosureButton className="flex flex-row items-center gap-2 cursor-pointer text-pcx-600 border-t-2 border-pcx-300 py-1 w-full">
                {open 
                    ? <IconChevronUp className="shrink-0 h-5 w-5 mb-1" />
                    : <IconChevronDown className="shrink-0 h-5 w-5 mb-1" />}
                <h5 className="text-base text-pcx-600 shrink-0">{title}</h5>
                {preview && !open && 
                        <span className="text-gray-400 min-w-0 truncate text-sm"> - {preview}</span> }
            </DisclosureButton>
            <DisclosurePanel
                transition
                className="text-sm text-gray-500 pb-2 pl-7 origin-top transition duration-200 ease-out data-[closed]:-translate-y-6 data-[closed]:opacity-0 whitespace-pre-wrap"
            >
                {children}
            </DisclosurePanel>
        </>}</Disclosure>
    )
}

export function FamilyGridDetails({family}) {
    const {t} = useTranslation()

    const {tagsLookup} = useTags()

    const {hasClaimScopes} = useRoles()
    const {membersByFamilyId} = useFilteredPatents()
    const {commentsLookUp} = useComments()
    const {commoditiesByFamilyId, claimScopeCommoditiesByPatentFamilyId} = useProductMapping()
    const commodities = _(((hasClaimScopes ? claimScopeCommoditiesByPatentFamilyId : {})[family.patentFamilyId] ?? commoditiesByFamilyId[family.patentFamilyId]) ?? [])
        .filter(Boolean)
        .sortBy(c => `${c.commodityClass ?? t("unclassified")} ${c.commodityReference}`.toLocaleLowerCase())
        .value()

    const familyComments = commentsLookUp[patent_family]?.[family?.patentFamilyId] ?? []
    const memberComments = (membersByFamilyId[family.patentFamilyId] ?? []).flatMap(m => commentsLookUp[family_member]?.[m.familyMemberId] ?? [])

    const tags = tagsLookup[patent_family]?.[family.patentFamilyId] ?? []

    return <>
        <div className="h-32 p-1">
            <PlainImage {...{ entity: patent_family, entityId: family.patentFamilyId, clickable: false }} />
        </div>

        <div className="min-w-0">
            <UnfoldingSections>
                <UnfoldingSection title={t('summary')} preview={family.summary}>{family.summary ?? '-'}</UnfoldingSection>
                <UnfoldingSection title={t('products')} preview={`${commodities.length}`}>
                    <ul className="list-none pb-2">
                        <li className="hidden only:block text-slate-400">{t('none')}</li>
                        {commodities.map(c =>
                            <li key={c.commodityId}>
                                <Link className="flex flex-row items-center" to={commodityUrl(c)}>
                                    <ProtectionIcon isThirdParty={c.isThirdParty} />{c.commodityClass ?? t("unclassified")}: {c.commodityReference}
                                </Link>
                            </li>)}
                    </ul>
                </UnfoldingSection>
            </UnfoldingSections>
            <div className="flex flex-row flex-wrap gap-1 text-xs text-gray-600 py-2 empty:py-0">
                {tags.map((tag) => <div key={tag} className="py-0.5 px-2 rounded-sm shadow-sm bg-pcx-300/50">{tag}</div>)}
            </div>
        </div>

        <div className="col-span-2 text-xs max-h-52 overflow-y-auto mb-2 pr-1 text-slate-500">
            <div className="flex flex-row gap-1 justify-between">
                <h5 className="pb-1 text-slate-600 text-base max-sm:only:hidden">{t('comments')}</h5>
                <Link className="btn-tertiary px-0" to={`comment?entity=${patent_family}&entityId=${family.patentFamilyId}`}>+ {t('add-comment')}</Link>
            </div>
            {[...familyComments, ...memberComments].map(c => <SmallComment key={`comment-${c.commentId}`} to="comment" {...c} />)}
        </div>
    </>
}

export function FamilyDetails({family}) {
    const {t} = useTranslation()

    const {tagsLookup} = useTags()

    const {hasClaimScopes} = useRoles()
    const {membersByFamilyId} = useFilteredPatents()
    const {commentsLookUp} = useComments()
    const {commoditiesByFamilyId, claimScopeCommoditiesByPatentFamilyId} = useProductMapping()
    const commodities = _(((hasClaimScopes ? claimScopeCommoditiesByPatentFamilyId : {})[family.patentFamilyId] ?? commoditiesByFamilyId[family.patentFamilyId]) ?? [])
        .filter(Boolean)
        .sortBy(c => `${c.commodityClass ?? t("unclassified")} ${c.commodityReference}`.toLocaleLowerCase())
        .value()

    const familyComments = commentsLookUp[patent_family]?.[family?.patentFamilyId] ?? []
    const memberComments = (membersByFamilyId[family.patentFamilyId] ?? []).flatMap(m => commentsLookUp[family_member]?.[m.familyMemberId] ?? [])

    const tags = tagsLookup[patent_family]?.[family.patentFamilyId] ?? []

    return (
        <div className="flex flex-col md:flex-row gap-2 md:gap-4">
            <div>
                <div className="flex flex-col md:flex-row gap-2 md:gap-4">
                    <div className="h-32 w-44 p-1">
                        <PlainImage {...{ entity: patent_family, entityId: family.patentFamilyId, clickable: false }} />
                    </div>
                    <div className="min-w-md max-w-md">
                        <UnfoldingSections>
                            <UnfoldingSection title={t('summary')} preview={family.summary}>{family.summary ?? '-'}</UnfoldingSection>
                            <UnfoldingSection title={t('products')} preview={`${commodities.length}`}>
                                <ul className="list-none pb-2">
                                    <li className="hidden only:block text-slate-400">{t('none')}</li>
                                    {commodities.map(c =>
                                        <li key={c.commodityId}>
                                            <Link className="flex flex-row items-center" to={commodityUrl(c)}>
                                                <ProtectionIcon isThirdParty={c.isThirdParty} />{c.commodityClass ?? t("unclassified")}: {c.commodityReference}
                                            </Link>
                                        </li>)}
                                </ul>
                            </UnfoldingSection>
                        </UnfoldingSections>
                        <div className="flex flex-row flex-wrap gap-1 text-sm text-gray-600 py-2 empty:py-0">
                            {tags.map((tag) => <div key={tag} className="py-0.5 px-2 rounded-sm shadow-sm bg-pcx-300/50">{tag}</div>)}
                        </div>
                    </div>
                </div>
            </div>
            <div className="ml-auto w-96 text-xs max-h-52 overflow-y-auto mb-2 pr-1 text-slate-500">
                <div className="flex flex-row gap-1 justify-between">
                    <h5 className="pb-1 text-slate-600 text-base max-sm:only:hidden">{t('comments')}</h5>
                    <Link className="btn-tertiary px-0" to={`comment?entity=${patent_family}&entityId=${family.patentFamilyId}`}>+ {t('add-comment')}</Link>
                </div>
                {[...familyComments, ...memberComments].map(c => <SmallComment key={`comment-${c.commentId}`} to="comment" {...c} />)}
            </div>
        </div>
    )
}

export function FamilyRibbons({family}) {
    const {familyScore} = useFamilyScores(family.patentFamilyId)

    return <ScoreRibbon score={familyScore} />
}

export function ExpiryRibbon({member, short = false}: {member: Member, short?: boolean}) {
    const {t} = useTranslation()
    return member.expiryDate 
        ? <div className="text-xs ribbon rounded-sm w-fit">{!short && <span className="text-slate-500">{t('expires')}:</span>} {member.expiryDate}</div> 
        : null
}


export function MemberRibbons({member, short = false}) {
    const score = useMemberScores(member.familyMemberId)

    return <ScoreRibbon {...{score, short}} />
}

export function ScoreRibbon({score, short = false}) {
    const {t} = useTranslation()
    return score
        ? <div className={clsx(scoreBackground(score), 'px-2 py-1 w-fit rounded-sm text-xs whitespace-nowrap text-gray-900/80')}>{!short && (t('score') + ':')} {score}</div>
        : null

}


export function LoadButton({hasNextPage, fetchNextPage}) {
    const {t} = useTranslation()
    const ref = useRef()

    useEffect(() => {
        const observer = new IntersectionObserver(([entry]) => {
            console.log('Triggering call')
            if (entry.isIntersecting)
                fetchNextPage()
        })
        if (ref.current !== undefined && hasNextPage) {
            //console.log(ref.current)
            observer.observe(ref.current)
        }
        return () => observer.disconnect()
    }, [fetchNextPage, hasNextPage, ref])

    return (
        <button 
            ref={ref}
            onClick={() => fetchNextPage()} 
            className="btn btn-primary"
        >{t('load-more')}</button>
    )
}

export function RenewalsBadge({smallMenu}) {
    const {settings} = useDmSettings()

    if (settings?.clientId && settings?.clientSecret)
        return <RenewalsBadgeWithCredentials {...{smallMenu}} />
    else
        return null
}

function RenewalsBadgeWithCredentials({smallMenu}) {
    const {instructionByDennemeyerId} = useDennemeyer()
    const {data: openMaintenances} = useMaintenances({onlyInstructable: false, onlyOpen: true})
    const { calculateDueDates } = useTimeline()

    const today = new Date().toISOString().slice(0, 10)
    const openAndUndecidedMaintenances = openMaintenances?.pages?.flatMap(d => d.Data?.Page ?? [])
        ?.filter(m => !instructionByDennemeyerId[m.DennemeyerId] && m.DueDate > today) ?? []

    const has_new = openAndUndecidedMaintenances.length > 0
    //console.log({has_new, openMaintenances, openAndUndecidedMaintenances})

    const warn = openAndUndecidedMaintenances.find(m => {
        if (!m.PermanentOrder) {
            const {status} = calculateDueDates(m.DueDate)
            return status === 'critical' || status === 'manual-instruction'
        } else return false
    }) !== undefined
    const severity = warn ? 'warning' : has_new ? 'info' : 'no-show'
    //const has_new = true
    //const warn = true
    //console.log({has_new, newDocs: newDocuments, maint: openMaintenances})

    return <SideBarBadge {...{smallMenu, severity}} />
}
