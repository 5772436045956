import { useTranslation } from 'react-i18next'
import _ from 'lodash'

import Image from "../../components/Image";
import { useAuth, useRoles } from "../../user/Auth";
import { updatePassword } from "../../backend";
import { Field, Formik, Form, ErrorMessage } from "formik";
import { useUserSettings } from "../../user/UserSettingsProvider";
import { LinkedTabCard } from '../../components/TabCard';

export default function UserSettings() {
    const {t} = useTranslation()
    const { isDev, hasDocuments } = useRoles()

    return (
        <>
            <div className="header-row">
                <h2 className='modern-h2'>{t('user-settings')}</h2>
            </div>
            <div className="main-content h-full bg-pcx-100 pt-2" >
                    <div className='w-fit sm:min-w-lg'>
                        <LinkedTabCard links={[
                            { to: ".", label: t('general-information') }, 
                            { to: "password", label: t('password') },
                            hasDocuments && { to: "api-token", label: t('api-tokens') },
                        ].filter(Boolean)} />
                    </div>
                {isDev && <AccountSettings />}
            </div>
        </>
    )
}

export function UserAppearance() {

    const {t, i18n} = useTranslation()
    const { userSettings: settings, updateUserSettings } = useUserSettings()

    const language = settings?.language ?? i18n.resolvedLanguage

    return (
        <div className="pt-2">
            <div className="flex sm:flex-row flex-col gap-4">
                <div className="w-48 h-fit border-2 border-pc-200">
                    <Image {...{
                        entity: "user",
                        entityId: settings?.userId,
                    }} />
                </div>

                <Formik 
                    initialValues={{language, ...settings}} onSubmit={updateUserSettings}
                    enableReinitialize
                    validate={values => {
                        const errors = {}
                        if ((!values.email || values.email === '') && settings?.email) {
                            errors["email"] = t('email-error')
                        }
                        return errors
                    }}
                >{({dirty, errors, touched}) => 
                    <Form className="max-w-sm flex flex-col gap-4">
                        <label className="label leading-loose" htmlFor="email">{t('email')} <br />
                            <Field name="email" className="form-input" type="email" placeholder={`${t('email')}...`} autoFocus />
                            <ErrMessage name="email"/>
                        </label>

                        <label className="label leading-loose" htmlFor="displayName">{t('name')} <br />
                            <Field name="displayName" className="form-input" type="text" placeholder={`${t('name')}...`} />
                        </label>

                        <label className="label leading-loose" htmlFor="language">{t('language')}<br />
                            <Field 
                                name="language" className="form-select" 
                                as="select"
                            >
                                <option value="en">English</option>
                                <option value="de">Deutsch</option>
                                <option value="fr">Français</option>
                            </Field>
                        </label>
                        <div className="col-span-3 flex flex-row justify-end py-3">
                            <input type="submit" disabled={!dirty} className="btn-primary disabled:btn-disabled" value={t('save')} />
                        </div>
                    </Form>
                }</Formik>
            </div>
        </div>
    )
}

function ErrMessage({name}) {
    return <div className="text-red-700"><ErrorMessage className="normal-case" name={name} /></div>
}

export function UserSettingsPassword() {
    const {t} = useTranslation()
    const {user} = useAuth()

    const initialValues = { oldPassword: "", newPassword: "", confirmPassword: "" }

    const labelStyle="pt-2 text-gray-700"
    return (
        <div>
            <span className="text-slate-500 text-sm">{t('only-use-asci')}</span>
            <Formik 
                initialValues={initialValues}
                validate={values => {
                    const errors = {}
                    if (values.oldPassword === "")
                        errors['oldPassword'] = t('no-old-given')
                    if (values.newPassword.length < 8)
                        errors['newPassword'] = t('at-least-8-chars')
                    if (values.newPassword !== values.confirmPassword)
                        errors['confirmPassword'] = t('password-match')
                    return errors
                }}
                onSubmit={(values, actions) => {
                    updatePassword(user, values.newPassword, values.oldPassword)
                    // @ts-ignore
                        .then(() => actions.resetForm(initialValues))
                        .catch(err => actions.setFieldError("oldPassword", err.message))
                }}
            >{({errors, dirty}) => {
                const canSubmit = _.size(errors) === 0 && dirty
                return (
                    <Form className="flex flex-col gap-1 max-w-sm">

                        <label className={labelStyle}>{t('old-password')}</label>
                        <Field id="oldPassword" name="oldPassword" type="password" className="form-input" />
                        <ErrMessage name="oldPassword"/>

                        <label className={labelStyle}>{t('new-password-min-8')}</label>
                        <Field id="newPassword" name="newPassword" type="password" className="form-input" />
                        <ErrMessage name="newPassword"/>

                        <label className={labelStyle}>{t('confirm-password')}</label>
                        <Field id="confirmPassword" name="confirmPassword" type="password" className="form-input" />
                        <ErrMessage name="confirmPassword"/>

                        <div className="flex flex-row-reverse py-2">
                            <button disabled={!canSubmit} className={canSubmit ? "btn-primary" : "btn-disabled"} type="submit">
                                {t('save')}
                            </button>
                        </div>
                    </Form>
                )
                }}</Formik>
        </div>
    )
}

function AccountSettings() {
    const {team} = useAuth()
    return (
        <div className="flex flex-col gap-2 py-2">
            <h3>Account</h3>
            <div className="border-2 border-red-600 bg-red-100 p-3 max-w-xs text-center">
                Nothing works below!
            </div>
            <div className="flex flex-row gap-2">
                <button className="btn-warn">
                    Leave {team}
                </button>
                <button className="btn-warn">
                    Delete User Account
                </button>
            </div>
            <div>
                <h4 className="pt-4 py-2">API Keys</h4>
                <input className="form-input" type="text" placeholder="asdfip34r7haf"/>
            </div>
        </div>
    )
}