import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import {
    Navigate,
    Route,
    createBrowserRouter,
    createRoutesFromElements,
    RouterProvider,
    useLocation,
    useRouteError,
    Link,
    Outlet,
  } from "react-router-dom";
import { QueryClient, QueryClientProvider, } from '@tanstack/react-query'
// import { ReactQueryDevtools } from '@tanstack/react-query-devtools'

import { NoTabMenu, NotFound, UnauthenticatedContent } from "./layout";

import Cockpit from "./cockpit/Cockpit";

import {AddCommentModal} from './comments/Comments'

import { EpoImportFamily } from "./patents/EpoImport";
import { AddEditFamily, EpoLoader, FamilyCosts, FamilyDocuments, FamilyIncome, FamilyIndex, FamilyOverview, FamilySubfamilies } from "./patents/Family";
import { EditFamilyMember, FamiliyMemberOverview, FamilyMemberClaims, FamilyMemberDocuments, FamilyMemberIndex, FamilyMemberCosts, FamilyMemberIncome } from "./patents/FamilyMember";
import Portfolio from "./patents/Portfolio";
import { ExcelExtractPortfolio } from "./patents/PatentsImport";
import CountryFamilyMap from "./patents/CountryFamilyMap";
import PatentsMenu from "./patents/PatentsMenu";
import DataWarehouse from "./patents/datawizard/DataWizard";
import PortfolioUpdate, { PortfolioUpdateImport } from "./patents/PortfolioUpdate";
import ClaimScape from "./claims/ClaimScape";
import { EditAddClaim, EditClaimVersion, AddClaimVersion } from "./claims/Claims";
import { MemberClaimsProvider } from "./claims/MemberClaimsProvider";
import ImportClaims from "./claims/ClaimsImport";
import Valuations from "./valuations/Valuations";

import RenewalsMenu from "./renewal/RenewalsMenu";
import { RenewalsTrail } from './renewal/RenewalsHistory';
import Documents from "./renewal/Documents";
import RenewalSettings, {Credentials, MasterData} from "./renewal/settings/Settings";
import {Notifications} from "./renewal/notifications/Subscriptions";
import CostCenters, {CostCenterEdit} from './renewal/settings/CostCenters'
import DennemeyerProvider from "./renewal/DennemeyerProvider";
import Owners, { OwnerEdit } from "./renewal/settings/Owners";

import ProductPortfolio from "./products/ProductPortfolio";
import ImportProductPortfolio from "./products/ImportProductPortfolio";
import ProductsMenu from "./products/ProductsMenu";
import ProductProtectionMap from "./products/ProductProtectionMap";

import Settings from "./settings/Settings";
import { AddUser, CreateUser, UserTable } from "./settings/UserManagement";
import Agents from "./agents/Agents";
import AdminPage, {UserManagement as UserAdminManagement, TeamManagement, AddTeam } from "./settings/AdminPage";
import { DataCuration } from "./settings/DataCuration";
import UserSettings, { UserAppearance, UserSettingsPassword } from "./settings/user/UserSettings";
import TeamSettings, { TeamGeneralSettings } from "./settings/TeamSettings";
import ComingSoon from "./settings/ComingSoon";
import { BillsProvider } from "./settings/bills/BillsProvider";
import Billing from "./settings/bills/Billing";
import AdminBilling, { AddBillingPlan, AddInvoiceButton, AdminBillingProvider, AdminTeamDetails, AllBills, InvoiceEdit } from "./settings/bills/AdminBilling";

import Login from "./user/Login";
import SignUp, {Confirmation, TeamLogin, TeamSignup, WaitingForApproval, WaitingForConfirmation} from "./user/SignUp";
import SelectTeam from "./user/SelectTeam";
import { UserSettingsProvider } from "./user/UserSettingsProvider";
import { AuthProvider, RequireAuth, SignOut, useAuth } from "./user/Auth";

import Designs from "./designs/Designs";

import { BackendProvider } from "./BackendProvider";
import { MessagesProvider } from "./Messages";
import { AdminManagmentProvider } from "./Management";

import InventionPortfolio from "./inventions/InventionPortfolio";
import InventionsProvider from "./inventions/InventionsProvider";
import Invention, { AddEditInventionModal, AddInventorModal, InventionDocuments, InventionIndex } from "./inventions/Invention";
import InventionPayments, { EditPaymentModal } from "./inventions/InventionPayments";

import Licenses from "./licenses/Licenses";
import ValuationsProvider from "./valuations/ValuationsProvider";
import { FilterProvider } from "./filter/Filter";
import CommentsProvider from "./comments/CommentsProvider";
import { HistoryNavigate, LocationProvider } from "./cockpit/Location";
import Forecast, { AddEditScenarioAmount, AddEditScenarioFamily, AddEditScenarioMember, AddEditScenarioVersion, CopyScenarioFamily, DeleteScenario, FamilyForecast, ScenarioFamilyView } from "./forecast/Forecast";
import { FxProvider } from './forecast/FxProvider';
import ShowModels from "./forecast/ShowModels";
import ForecastProvider from "./forecast/ForecastProvider";
//import { FilterBarProvider } from "./filter/FilterBar";
import { ProductMappingProvider } from "./products/products";
import { TrademarkProvider } from './trademarks/TrademarksProvider';
import TrademarksMenu from './trademarks/TrademarksMenu';
import TrademarkPortfolio from './trademarks/TrademarkPortfolio';
import Trademark, { PostTrademark, TrademarkClasses, TrademarkCosts, TrademarkDocuments, TrademarkOverview } from './trademarks/Trademark';
import TrademarkFamily, { PostTrademarkFamily, TrademarkFamilyCosts, TrademarkFamilyDocuments, TrademarkFamilyOverview } from './trademarks/TrademarkFamily';
import TmDatawizard from './trademarks/TrademarksDatawizard';
import TrademarkProductMapping from './trademarks/ProductMapping';
import TrademarkImport from './trademarks/TrademarkImport';

import { TasksProvider } from './tasks/TasksProvider';
import Tasks, {PostTask} from './tasks/Tasks';
import { PostTrademarkClass } from './trademarks/TrademarkClasses';
import TrademarkCountries from './trademarks/TrademarkCountries';
import Events from './settings/events/Events';

import DevPage from './utils/DevPage';
import { CostProvider } from './costs/CostsProvider';
import { CostItemsDetails, Costs } from './costs/Costs';
import { PostCost } from './costs/Costs';
import CostsImport from './costs/CostsImport';
//import { MemberRenwalsEdit } from './renewal/MemberOverview';
import { MemberRenwalsEdit } from './renewal/import/ImportModal';
import { FilteredPatentsProvider } from './filter/FilteredPatents';
import { FilteredCommoditiesProvider } from './filter/FilteredCommodities';
import { FilteredTrademarksProvider } from './filter/FilteredTrademarks';
import TagsManagement from './settings/TagsManagement';
import PatentsProvider from './patents/PatentsProvider';
import ApiStatusView from './renewal/settings/ApiStatus';
import StatisticsPage from './settings/statistics/StatisticsPage';
import DocumentsBrowser, { DocumentsMenu } from './documents/DocumentsBrowserPage';
import { DocumentPreview, FullPagePreviewRoute } from './documents/Preview';
import { DmsProvider } from './documents/DocumentsSession';
import Layout from './layout/Layout';
import SimpleMaintenanceActions from './renewal/SimpleMaintenanceActions';
import MaintenanceHistory from './renewal/HistoryTable';
import { useEffect } from 'react';
import { postEvent } from './settings/events';
import { ClaimScopeMappingEdit, PatentsPerCommodityMapping, ProductPatentFamilyMappingEdit, ProductTrademarkFamilyMappingEdit, TrademarksPerCommodityMapping } from './products/ProductFamilyMapping';
import { ProductsProvider } from './products/ProductsProvider';
import ProductView, {PostProduct, ProductByRef, ProductDocuments, ProductIncome, ProductIndex} from './products/ProductView';
import { InstructionModal } from './renewal/instruction/Instructions';
import BulkImportPage from './renewal/import/BulkImport';
import BulkEpoImport from './import/BulkEpoImport';
import UsptoImport from './import/UsptoImport';
import { TrademarkSingleImport } from './trademarks/TrademarkSingleImport';
import { ClaimsProvider } from './claims/ClaimsProvider';
import MemberBulkEdit from './patents/MemberBulkEdit';
import { family_member, trade_mark } from './data';
import RenwalsStatistics from './renewal/admin/RenwalsStatistics';
import ApiTokensManagement from './settings/user/ApiTokens';
//import {OutlookPlugin, OutlookPluginHome, OutlookPluginSettings} from './outlook';
import {OutlookPlugin} from './outlook/plugin';
import { RolesProvider } from './user/RolesProvider';
import ProductPatentMapping from './products/ProductPatentMapping';


// TODO: add trademark provider
function Providers() {
    const queryClient = new QueryClient()

    return (
        <QueryClientProvider client={queryClient}>
            <RequireAuth>
                <RolesProvider>
                    <LocationProvider>
                        <UserSettingsProvider>
                            <PatentsProvider>
                                <ProductsProvider>
                                    <ClaimsProvider>
                                    <BackendProvider >
                                        <CommentsProvider>
                                            <TasksProvider>
                                                <DndProvider backend={HTML5Backend}>
                                                    <FilterProvider>
                                                        <FilteredPatentsProvider><FilteredCommoditiesProvider>
                                                            <TrademarkProvider><FilteredTrademarksProvider>
                                                                <ProductMappingProvider>
                                                                    <ValuationsProvider>
                                                                        <FxProvider>
                                                                            <ForecastProvider>
                                                                                <InventionsProvider>
                                                                                    <DmsProvider>
                                                                                        <Layout />
                                                                                    </DmsProvider>
                                                                                </InventionsProvider>
                                                                            </ForecastProvider>
                                                                        </FxProvider>
                                                                    </ValuationsProvider>
                                                                </ProductMappingProvider>
                                                            </FilteredTrademarksProvider></TrademarkProvider>
                                                        </FilteredCommoditiesProvider></FilteredPatentsProvider>
                                                    </FilterProvider>
                                                </DndProvider>
                                            </TasksProvider>
                                        </CommentsProvider>
                                        {/* <ReactQueryDevtools position="top-left" /> */}
                                    </BackendProvider>
                                    </ClaimsProvider>
                                </ProductsProvider>
                            </PatentsProvider>
                        </UserSettingsProvider>
                    </LocationProvider>
                </RolesProvider>
            </RequireAuth>
        </QueryClientProvider >
    )
}

function ErrorBoundary() {
    const {user, team: realm} = useAuth()
    const error = useRouteError() as {message: string, fileName?: string, lineNumber?: number, columnNumber?: number, stack?: string}
    console.warn({error})

    useEffect(() => {
        const payload = {
            message: error.message,
            fileName: error.fileName,
            lineNumber: error.lineNumber,
            columnNumber: error.columnNumber, 
            stack: error.stack,
        }
        postEvent({user, realm, eventType: "error", payload: JSON.stringify(payload)})
    })

    return (
        <div className="flex flex-col gap-8 p-2 sm:p-8">
            <h1>Sorry, an unexpected error happend!</h1>
            <Link className="underline" to="/">Go back to home</Link>

            <a className="underline" href="mailto:support@patent-cockpit.com">Or contact support</a>

            <p className="text-slate-600 mt-8">Error: {error?.message ?? ''}</p>
        </div>
    )
}

export default function App() {

    const router = createBrowserRouter(createRoutesFromElements(<>
        <Route path="/outlook" element={<OutlookPlugin />} >
            {/* <Route index element={<OutlookPluginHome />} />
            <Route path="settings" element={<OutlookPluginSettings />} /> */}
        </Route>

        <Route element={<UnauthenticatedContent />}>
            <Route path="/login" >
                <Route index element={<Login />} />
                <Route path="team" element={<TeamLogin />} />
            </Route>
            <Route path="/signup" >
                <Route path="waiting" element={<WaitingForConfirmation />} />
                <Route path="confirm/:token" element={<Confirmation />} />
                <Route index element={<SignUp />} />
            </Route>
            <Route path="/confirm/:token" element={<Confirmation forwardUrl="/" />} />
            <Route path="/signout" element={<SignOut />} />
            <Route path="/reload" element={<Navigate to="/" />} />
        </Route>

        {/* No *really* unauthenticated; just without a team token */}
        <Route path="/teams" element={<UnauthenticatedContent />}>
            <Route path="signup" element={<TeamSignup />} />
            <Route path="waiting" element={<WaitingForApproval />} />
            <Route index element={<SelectTeam />} />
        </Route>

        <Route path="/" element={<Providers /> } errorElement={<ErrorBoundary />} >

            <Route index element={<RootRoute />} />

            <Route path="cockpit" element={
                <CostProvider>
                        <Cockpit />
                </CostProvider>
            } />

            {/* We might add a dash board at / in one point in time; so try to refer to /patents when needed */}
            <Route path="patents" element={<PatentsMenu />}>
                <Route path="portfolio">
                    <Route index element={<Portfolio />} />
                    <Route path="import" element={<EpoImportFamily />} />
                    <Route path="extract" element={<ExcelExtractPortfolio />} />
                    <Route path="family/add" element={<AddEditFamily />} />
                    <Route path="family/:internalReference" element={<CostProvider><FamilyIndex /></CostProvider>} >
                        <Route element={<FamilyOverview />} >
                            <Route index element={null} />
                            <Route path="edit" element={<AddEditFamily />} />
                            <Route path="comments" element={<AddCommentModal />} />
                            <Route path="epo" element={<EpoLoader />} />
                            <Route path="products" element={<ProductPatentFamilyMappingEdit />} />
                        </Route>
                        <Route path="subfamilies" element={<FamilySubfamilies />} >
                            <Route path="products/:claimScopeId" element={<ClaimScopeMappingEdit />} />
                        </Route>
                        <Route path="costs" element={<FamilyCosts />} >
                            <Route path="cost" element={<PostCost />} />
                        </Route>
                        <Route path="income" element={<FamilyIncome />} />
                        <Route path="documents" element={<FamilyDocuments />} />
                    </Route>
                    <Route path="member/add" element={<EditFamilyMember />} />
                    <Route path="member/:internalReference/claims/import" element={<MemberClaimsProvider><ImportClaims /></MemberClaimsProvider>} />
                    <Route
                        path="member/:internalReference"
                        element={<MemberClaimsProvider><DennemeyerProvider><CostProvider><FamilyMemberIndex /></CostProvider></DennemeyerProvider></MemberClaimsProvider>}
                    >
                        <Route element={<FamiliyMemberOverview />} >
                            <Route index element={null} />
                            <Route path="edit" element={<EditFamilyMember />} />
                            <Route path="comments" element={<AddCommentModal />} />
                            <Route path="task" element={<PostTask />} />
                            {/* <Route path="renewals/history" element={<RenewalsHistory />} /> */}
                            <Route path="renewals/history" element={<RenewalsTrail />} />
                            <Route path="renewals" element={<MemberRenwalsEdit />} />
                            <Route path="instruct/:id" element={<InstructionModal />} />
                        </Route>
                        <Route path="claims" element={<FamilyMemberClaims />}>
                            <Route path="version/edit" element={<EditClaimVersion />} />
                            <Route path="version/add" element={<AddClaimVersion />} />
                            <Route path="add" element={<EditAddClaim />} />
                            <Route path=":claimId" element={<EditAddClaim />} />
                        </Route>
                        <Route path="documents" element={<FamilyMemberDocuments />} />
                        <Route path="costs" element={<FamilyMemberCosts />} >
                            <Route path="cost" element={<PostCost />} />
                        </Route>
                        <Route path="income" element={<FamilyMemberIncome />} />
                    </Route>
                </Route>
                <Route path="country-family" element={<CountryFamilyMap />} />

                <Route path="forecast" element={<Forecast />} >
                    <Route path="family/:patentFamilyId" element={<FamilyForecast />} >
                        <Route path="member/:scenarioMemberId" element={<AddEditScenarioMember />} />
                        <Route path="member/" element={<AddEditScenarioMember />} />
                        <Route path="amount/:scenarioAmountId" element={<AddEditScenarioAmount />} />
                        <Route path="amount/" element={<AddEditScenarioAmount />} />
                    </Route>
                    <Route path="scenario-family/add" element={<AddEditScenarioFamily />} />
                    <Route path="scenario-family/:scenarioFamilyId" element={<ScenarioFamilyView />} >
                        <Route path="copy" element={<CopyScenarioFamily />} />
                        <Route path="edit" element={<AddEditScenarioFamily />} />
                        <Route path="member/:scenarioMemberId" element={<AddEditScenarioMember />} />
                        <Route path="member/" element={<AddEditScenarioMember />} />
                        <Route path="amount/:scenarioAmountId" element={<AddEditScenarioAmount />} />
                        <Route path="amount/" element={<AddEditScenarioAmount />} />
                    </Route>
                    <Route path="scenario" element={<AddEditScenarioVersion />} />
                    <Route path="scenario/:scenarioId" element={<AddEditScenarioVersion />} />
                    <Route path="scenario/:scenarioId/delete" element={<DeleteScenario />} />
                </Route>
                <Route path="forecast/model" element={<ShowModels />} />

                <Route path="evaluations">
                    <Route index element={<Valuations />} />
                </Route>
                <Route path="profit-protection" element={<ComingSoon title="Profit Protection" />} />
                <Route path="claim-scapes" element={<ClaimScape />} />
                <Route path="data" element={<CostProvider><DennemeyerProvider><DataWarehouse /></DennemeyerProvider></CostProvider>} />
                <Route path="products" element={<ProductPatentMapping />} />

                <Route path="costs" element={<CostProvider><Outlet /></CostProvider>} >
                    <Route element={<Costs entity={family_member} />} >
                        <Route index element={null} />
                        <Route path="cost" element={<PostCost />} />
                        <Route path="details" element={<CostItemsDetails />} />
                    </Route>
                    <Route path="import" element={<CostsImport />} />
                </Route>

                <Route path="update-portfolio">
                    <Route index element={<PortfolioUpdate />} />
                    <Route path="import" element={<PortfolioUpdateImport />} />
                </Route>

                <Route path="bulk-edit" element={<MemberBulkEdit />} />

                <Route index element={<HistoryNavigate prefix="/patents" fallback="/patents/portfolio" />} />
            </Route>

            <Route path="renewals" element={<DennemeyerProvider><RenewalsMenu /></DennemeyerProvider>}>
                <Route path='maintenances' element={<SimpleMaintenanceActions />} >
                    <Route path="comment" element={<AddCommentModal />} />
                    {/* <Route path="instruct/:id" element={<MaintenanceModal />} /> */}
                    <Route path="instruct/:id" element={<InstructionModal />} />
                </Route>

                <Route path='history' element={<MaintenanceHistory />} />
                <Route path="documents" element={<Documents />} />

                {/* NOTE: Make sure to adapt urls in settings/index.ts, when changing something here */}
                <Route path="master-data" element={<MasterData />} >
                    <Route path="owners" element={<Owners />} >
                        <Route path="edit/:id" element={<OwnerEdit />} />
                        <Route path="add" element={<OwnerEdit isAdding />} />
                    </Route>

                    <Route path="cost-centers" element={<CostCenters />} >
                        <Route path="edit/:id" element={<CostCenterEdit />} />
                        <Route path="add" element={<CostCenterEdit isAdding />} />
                    </Route>

                    <Route path="api-status" element={<ApiStatusView />} />

                    <Route index element={<HistoryNavigate prefix="/renewals/master-data" fallback="/renewals/master-data/owners" />} />
                </Route>

                {/* NOTE: Make sure to adapt urls in settings/index.ts */}
                <Route path="settings" element={<RenewalSettings />} >

                    <Route path="credentials" element={<Credentials />} />

                    <Route path="notifications" element={<Notifications />} />

                    <Route path="bulk-import" element={<BulkImportPage />} />

                    <Route index element={<HistoryNavigate prefix="/renewals/settings" fallback="/renewals/settings/credentials" />} />
                </Route>

                <Route index element={<HistoryNavigate prefix="/renewals" fallback="/renewals/maintenances" />} />
            </Route>

            <Route path="inventions">
                <Route index element={<HistoryNavigate prefix="/inventions" fallback="/inventions/portfolio" />} />
                <Route path="portfolio">
                    <Route element={<InventionPortfolio />} >
                        <Route path="add" element={<AddEditInventionModal />} >
                            <Route path="add-inventor" element={<AddInventorModal />} />
                        </Route>
                        <Route path="edit/:reference" element={<AddEditInventionModal />} />
                    </Route>
                    <Route path=":reference" element={<InventionIndex />}>
                        <Route element={<Invention />}>
                            <Route index element={null} />
                            <Route path="edit" element={<AddEditInventionModal />}>
                                <Route path="add-inventor" element={<AddInventorModal />} />
                            </Route>
                            <Route path="payments/add" element={<EditPaymentModal />} />
                            <Route path="payments/:paymentId/edit" element={<EditPaymentModal />} />
                        </Route>
                        <Route path="documents" element={<InventionDocuments />} />
                    </Route>
                    <Route index element={<InventionPortfolio />} />
                </Route>

                <Route path="payments" element={<InventionPayments />}>
                    <Route path="add" element={<EditPaymentModal />} />
                    <Route path=":paymentId" element={<EditPaymentModal />} />
                </Route>
            </Route>

            <Route path="products" element={<ProductsMenu />}>
                <Route index element={<HistoryNavigate prefix="/products" fallback="/products/portfolio" />} />

                <Route path="portfolio"  >
                    <Route index element={<ProductPortfolio />} />
                    <Route path="product/add" element={<PostProduct isAdding />} />
                    <Route path="product/by-ref/:reference" element={<ProductByRef />} />
                    <Route path="product/:id" element={<ProductIndex />} >
                        <Route element={<ProductView />} >
                            <Route index element={null} />
                            <Route path="edit" element={<PostProduct />} />
                            <Route path="patents" element={<PatentsPerCommodityMapping />} />
                            <Route path="trademarks" element={<TrademarksPerCommodityMapping />} />
                        </Route>
                        <Route path="documents" element={<ProductDocuments />} />
                        <Route path="income" element={<ProductIncome />} />
                    </Route>
                </Route>

                <Route path="extract" element={<ImportProductPortfolio />} />
                {/* This is not linked anymore; it's now under patents */}
                <Route path="patent-mapping" element={<ProductPatentMapping />} />
                <Route path="protection" element={<ProductProtectionMap />} />
            </Route>

            <Route path="tasks" element={<Tasks />} >
                <Route path="post" element={<PostTask />} />
            </Route>

            <Route path="trademarks" element={<TrademarksMenu />} >
                <Route index element={<HistoryNavigate prefix="/trademarks" fallback="/trademarks/portfolio" />} />


                <Route path="portfolio">
                    <Route element={<TrademarkPortfolio />} >
                        <Route index element={null} />
                        <Route path="add" element={<PostTrademarkFamily />} />

                    </Route>

                    <Route path="import" element={<TrademarkImport />} />

                    <Route path="family">
                        <Route path=":reference" element={<TrademarkFamily />} >
                            <Route element={<TrademarkFamilyOverview />} >
                                <Route index element={null} />
                                <Route path="edit" element={<PostTrademarkFamily />} />
                                <Route path="add" element={<PostTrademark />} />
                                <Route path="comments" element={<AddCommentModal />} />
                                <Route path="mapping/edit" element={<ProductTrademarkFamilyMappingEdit />} />
                                <Route path="import" element={<TrademarkSingleImport />} />
                            </Route>

                            <Route path="documents" element={<TrademarkFamilyDocuments />} />

                            <Route path="costs" element={<TrademarkFamilyCosts />} >
                                <Route path="cost" element={<PostCost />} />
                            </Route>
                        </Route>
                    </Route>

                    <Route path="member">
                        <Route path=":reference" element={<Trademark />} >
                            <Route element={<TrademarkOverview />} >
                                <Route index element={null} />
                                <Route path="edit" element={<PostTrademark />} />
                                <Route path="comments" element={<AddCommentModal />} />
                                <Route path="task" element={<PostTask />} />
                            </Route>

                            <Route path="classes" element={<TrademarkClasses />} >
                                <Route path="class" element={<PostTrademarkClass />} />
                            </Route>
                            <Route path="documents" element={<TrademarkDocuments />} />

                            <Route path="costs" element={<TrademarkCosts />} >
                                <Route path="cost" element={<PostCost />} />
                            </Route>
                        </Route>
                    </Route>
                </Route>

                <Route path="countries" element={<TrademarkCountries />} />

                <Route path="data" element={<TmDatawizard />} />

                <Route path="costs" element={<CostProvider><Outlet /></CostProvider>} >
                    <Route element={<Costs entity={trade_mark} />} >
                        <Route index element={null} />
                        <Route path="cost" element={<PostCost />} />
                        <Route path="details" element={<CostItemsDetails />} />
                    </Route>
                    <Route path="import" element={<CostsImport />} />
                </Route>

                <Route path="products" element={<TrademarkProductMapping />} />
            </Route>

            <Route path='documents' element={<DocumentsMenu />}>
                <Route index element={<HistoryNavigate prefix="/documents" fallback="/documents/browser" />} />
                {/* <Route path="settings" element={<DocumentsSettings />} /> */}
                <Route path="browser" element={<DocumentsBrowser />}>
                    <Route path="preview/:id" element={<DocumentPreview />} >
                        <Route path="fullpage" element={<FullPagePreviewRoute />} />
                    </Route>
                </Route>
            </Route>

            <Route element={<NoTabMenu />}>
                <Route path="licenses" element={<Licenses />} />
                <Route path="designs" element={<Designs />} />
            </Route>

            <Route path="settings" element={<Settings />}>
                <Route index element={<HistoryNavigate prefix="/settings" fallback="/settings/user" />} />
                <Route path="user" element={<UserSettings />} >
                    <Route index element={<UserAppearance />} />
                    <Route path="password" element={<UserSettingsPassword />} />
                    <Route path="api-token" element={<ApiTokensManagement />} />
                </Route>

                <Route path="team" element={<TeamSettings />} >
                    <Route index element={<TeamGeneralSettings />} />
                    <Route path="users" element={<UserTable />} />
                    <Route path="create" element={<CreateUser />} />
                    <Route path="add" element={<AddUser />} />
                </Route>
                <Route path="billing" element={<BillsProvider><Billing /></BillsProvider>} />

                <Route path="agents" element={<Agents />} />
                <Route path="tags" element={<TagsManagement />} />
                <Route path="data-curation" element={<DataCuration />} />

                <Route path="admin" element={<AdminPage />} >
                    {/* A bit ugly but refreshes users and teams when switching between tabs */}
                    <Route path="users" element={<AdminManagmentProvider><UserAdminManagement /></AdminManagmentProvider>} />
                    <Route path="teams" element={<AdminManagmentProvider><TeamManagement /></AdminManagmentProvider>} />
                    <Route path="add-team" element={<AdminManagmentProvider><AddTeam /></AdminManagmentProvider>} />
                    <Route path="events" element={<Events />} />
                    <Route path="statistics" element={<StatisticsPage />} />

                    <Route path="renewal-statistics" element={<RenwalsStatistics />} />

                    <Route path="billing" element={<AdminManagmentProvider><AdminBillingProvider><AdminBilling /></AdminBillingProvider></AdminManagmentProvider>}>
                        <Route index element={<AllBills />} />
                        <Route path=":team" element={<AdminTeamDetails />} >
                            <Route index element={<AddInvoiceButton />} />
                            <Route path="add-plan" element={<AddBillingPlan />} />
                            <Route path=":billId" element={<InvoiceEdit />} />
                        </Route>
                    </Route>
                    <Route index element={<Navigate to="users" />} />
                </Route>
            </Route>

            <Route path="epo" element={<BulkEpoImport />} />
            <Route path="uspto" element={<UsptoImport />} />

            <Route path="dev" element={<DevPage />} />
            <Route path="404" element={<NotFound />} />
            <Route path="*" element={<CatchAll />} />
        </Route>
    </>))

    return (
        <MessagesProvider>
            <AuthProvider>
                <RouterProvider router={router} />
            </AuthProvider>
        </MessagesProvider>
    )
}

function CatchAll() {
    const location = useLocation()
    console.warn("404 with ", {location})
    //return <Navigate to="/404" />
    return <NotFound />
}

function RootRoute() {
    return <Navigate to="/cockpit" />
}
