import { useMemo, useRef, useState } from "react";
import { useAgents } from "./AgentsProvider";
import { AgentType, nameOf } from "./utils";
import { XMarkIcon } from "@heroicons/react/20/solid";
import _ from "lodash";
import { Combobox, ComboboxButton, ComboboxInput, ComboboxOption, ComboboxOptions, Dialog, DialogBackdrop, DialogPanel } from "@headlessui/react";
import clsx from "clsx";
import Fuse from "fuse.js";
import { useTranslation } from "react-i18next";


export function AgentsEdit(
    {name, agentTypes = ['person', 'company'], value, onChange}:
    {name: string, agentTypes?: AgentType[], value: number[], onChange: (value: number[]) => void | Promise<void>}) {

    const {agentById} = useAgents()
    const remove = (agentId: number) => onChange(value.filter(v => v !== agentId))

    return <>
        {value.map(agentId => {
            const agent = agentById[agentId]
            if (!agent) return null
            const name = nameOf(agent)
            return (
                <div key={agentId} className="flex flex-row gap-1 tracking-tight rounded bg-white border border-slate-400 pl-2 pr-1 py-0.5 w-fit">
                    {name}
                    <button type="button" className="text-slate-500 hover:text-slate-900"><XMarkIcon className="size-4" onClick={() => remove(agentId)}></XMarkIcon></button>
                </div>
            )
        })}
        <AgentSelect {...{
            name,
            onChange: (id: number) => id !== undefined && onChange([...value, id]),
            agentTypes: agentTypes,
            unSelectable: value,
        }} />
    </>
}

export function AgentSelect({name, onChange, agentTypes, unSelectable}) {
    const {t} = useTranslation()

    const buttonRef = useRef(null)
    const {agents, postAgent} = useAgents()

    const [showAddForm, setShowAddForm] = useState(undefined)
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')

    const augmentedAgents = useMemo(() =>
        _(agents)
            .filter(a => agentTypes.includes(a.agentType))
            .map(agent => ({ ...agent, name: nameOf(agent) }))
            .sortBy(a => `${agentTypes.indexOf(a.agentType)} ${a.name}`.toLocaleLowerCase())
            .value(),
        [agents, agentTypes])
    const availableAgents = useMemo(() =>
        augmentedAgents.filter(a => unSelectable.indexOf(a.agentId) === -1),
        [augmentedAgents, unSelectable])

    const searchIndex = useMemo(() => new Fuse(availableAgents, { keys: ['name'], threshold: 0.25 }), [availableAgents])


    const [query, setQuery] = useState('')
    // console.log({query, firstName, lastName})

    const filtered = query === '' ? availableAgents : searchIndex.search(query).map(r => r.item)

    function selectAgent(value?: string) {
        if (agentTypes.includes(value)) {
            //console.log({ value })
            setFirstName(query)
            setShowAddForm(value)
        } else {
            const agentId = parseInt(value ?? '')
            if (!isNaN(agentId)) {
                onChange(agentId)
                setQuery('')
            }
        }
    }

    const allSearchIndex = useMemo(() => new Fuse(augmentedAgents, { keys: ['name'], threshold: 0.1 }), [augmentedAgents])
    const possibleOverlaps = showAddForm ? allSearchIndex.search(`${firstName} ${lastName}`) : []

    async function addNewAgent() {
        const agent = await postAgent({ agentType: showAddForm, firstName, lastName, name: firstName })
        onChange(agent.agentId)
        setFirstName('')
        setLastName('')
        setShowAddForm(undefined)
        setQuery('')
    }

    function addExistingAgent(agentId: number) {
        if (availableAgents.find(a => a.agentId === agentId)) {
            onChange(agentId)
        }
        setShowAddForm(undefined)
    }

    const showAddAgentButtons = query.length > 2

    const createNewLabels = {
        person: t('create-new-person'),
        company: t('create-new-company'),
    }

    return <>
        <Combobox immediate value='' onChange={selectAgent}>{({open}) => <>
            <ComboboxButton ref={buttonRef} className='btn-secondary text-sm font-normal py-0.5'>Select Agent</ComboboxButton>
            {open &&
                <Dialog {...{ open, onClose: () => { /*setQuery(''); buttonRef?.current?.click()*/ } }} 
                    className="relative z-50  transition duration-300 ease-out data-[closed]:opacity-0"
                    transition
             >
                    <DialogBackdrop className="fixed inset-0 bg-black/30" />

                    <div className="fixed inset-0 flex w-screen justify-center p-4 md:pt-16 md:pb-6 xl:pt-24 ">
                        <DialogPanel className='h-fit min-w-full max-w-full md:min-w-lg md:max-w-lg divide-y divide-gray-500 divide-opacity-20 overflow-hidden rounded-xl bg-white xbg-opacity-90 shadow-2xl ring-1 ring-black ring-opacity-5'>
                            <ComboboxInput
                                name={name}
                                className='h-12 w-full border-0 border-b-2 border-white/0 px-4 text-gray-900 focus:ring-0 sm:text-sm focus:outline-none focus:border-pcx-300 bg-white/50'
                                autoFocus value={query} onChange={(e) => setQuery(e.target.value)} 
                            />
                            <ComboboxOptions className='max-h-[60vh] overflow-x-hidden overflow-y-scroll px-2 py-1'>
                                {filtered.map(agent => {
                                    return (
                                    <ComboboxOption key={agent.agentId} value={agent.agentId} title={agent.name}>{({ focus }) => 
                                        <div className={clsx(
                                            // focus ? "text-gray-900 bg-gray-200 rounded-lg" : "text-gray-700",
                                            // "font-base px-4 py-0.5",
                                            "text-sm text-gray-700 px-2 py-1 block rounded-md hover:bg-gray-900 hover:bg-opacity-5 hover:text-gray-900",
                                            focus && "bg-gray-900 bg-opacity-5 text-gray-900"
                                                )}>
                                            { agent.name }
                                        </div>
                                    }</ComboboxOption>
                                    )
                                })}
                            </ComboboxOptions>
                            {showAddAgentButtons &&
                                <ComboboxOptions className="flex flex-row gap-4 p-2">
                                    {agentTypes.map(agentType =>
                                        <ComboboxOption key={agentType} value={agentType}
                                            className="text-sm text-gray-700 px-2 py-1 block rounded-md hover:bg-gray-900 hover:bg-opacity-5 hover:text-gray-900 border border-gray-900/20 cursor-pointer"
                                        >
                                            {createNewLabels[agentType]}
                                        </ComboboxOption>)}
                                </ComboboxOptions>}
                        </DialogPanel>
                    </div>
                </Dialog>}
        </>}
        </Combobox>
        {showAddForm &&
            <Dialog {...{ open: true, onClose: () => setShowAddForm(undefined) }}
                className="relative z-50  transition duration-300 ease-out data-[closed]:opacity-0"
                transition
            >
                <DialogBackdrop className="fixed inset-0 bg-black/30" />

                <div className="fixed inset-0 flex w-screen justify-center p-4 md:pt-20 md:pb-6 xl:pt-24 ">
                    <DialogPanel className='h-fit min-w-full max-w-full md:min-w-md md:max-w-md divide-gray-500 divide-opacity-10 overflow-hidden rounded-xl bg-white shadow-2xl ring-1 ring-black ring-opacity-5'>
                        <div className="p-4 flex flex-col gap-2">
                            <h4>{createNewLabels[showAddForm]}</h4>
                            <label>{showAddForm === 'person' ? t('firstName') : t('name')}</label>
                            <input className="form-input" autoFocus value={firstName} onChange={(e) => setFirstName(e.target.value)} />
                            {showAddForm === 'person' && <>
                                <label>{t('lastName')}</label>
                                <input className="form-input" value={lastName} onChange={(e) => setLastName(e.target.value)}/>
                            </>}
                        </div>
                        {possibleOverlaps.length > 0 &&
                            <div className="px-4 pb-4">
                                Possible Overlap: 
                                {possibleOverlaps.map(agent =>
                                    <button key={agent.item.agentId} type="button"
                                        className="ml-1 inline hover:underline"
                                        onClick={() => {addExistingAgent(agent.item.agentId)}}
                                    >
                                        {agent.item.name}
                                    </button>
                                )}
                            </div>}
                        <div className="bg-pcx-200 p-4 flex flex-row-reverse gap-4">
                            <button type="button" className="btn-primary" onClick={() => addNewAgent()}>{t('add')}</button>
                            <button type="button" className="btn-secondary" onClick={() => setShowAddForm(undefined)}>
                                {t('cancel')}
                            </button>
                        </div>
                    </DialogPanel>
                </div>
            </Dialog>}
    </>
}
