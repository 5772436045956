import { useQuery } from "@tanstack/react-query";
import { createContext } from "react";
import { management } from "../backend";
import { useAuth } from "./Auth";
import { useTeamFlags } from "../settings/TeamFlags";
import { annuities_flag, claim_scope_flag, new_innovation_flag, product_country_flag, portfolio_update_flag, brand_flag, documents_flag, invention_flag, tasks_flag, excel_import_flag, excel_export_flag, costs_flag, tracking_flag, prio_sort_flag, annuities_admin_flag, euipo_import_flag, abandonment_date_flag, bulk_edit_flag, active_changes_flag, revenues_flag, product_owner_flag, } from "../data"

export const RolesContext = createContext({
  roles: [],
  isDev: false as boolean,
  isEditUser: false as boolean,
  isAdmin: false as boolean,
  isSuperAdmin: false as boolean,
  hasClaimScopes: false as boolean,
  hasAnnuities: false as boolean,
  isAnnuityAdmin: false as boolean,
  hasProductCountries: false as boolean,
  hasProductOwners: false as boolean,
  hasInnovation: false as boolean,
  hasNewInnovations: false as boolean,
  hasPortfolioUpdate: false as boolean,
  hasBulkEdit: false as boolean,
  hasRevenues: false as boolean,
  hasCosts: false as boolean,
  hasTasks: false as boolean,
  hasExcelImport: false as boolean,
  hasExcelExport: false as boolean,
  hasBrands: false as boolean,
  hasDocuments: false as boolean,
  hasSortByPrio: false as boolean,
  hasEuipoImport: false as boolean,
  hasAbandonmentDate: false as boolean,
  hasActiveChangesChart: false as boolean,
  hasTracking: false as boolean,
});

const queryKey = ['roles']
export function RolesProvider({children}) {

    const {setStrike, user: username, team} = useAuth()

    const {data: userRoles} = useQuery({
      queryKey,
      queryFn: async () => {
        try {
            //console.log('getting roles again ', username, team)
          const userFlags = await management({ username, operation: "get", type: "flag", })
            .then((flags) => flags.filter((f) => f.username === username).map((f) => f.flag))
            .catch(e => e.status === "unauthorized" && setStrike((s) => s + 1));
          // edit, read, admin
          const userRoles = await management({ userName: username, realm: team, operation: "get", type: "user-realm", })
            .catch(e => e.status === "unauthorized" && setStrike((s) => s + 1));
          //console.log({userRoles, userFlags})

          setStrike(0);
          const rs =  [...userFlags, ...(userRoles[0]?.userRoles ?? [])];
          //console.log({rs})
          return rs
        } catch (e) {
          if (e.status === "unauthorized") {
            setStrike((s) => s + 1);
          }
          return []
        }
      },
      enabled: username !== undefined && team !== undefined, 
    });

    const {flags: teamFlags} = useTeamFlags(team, setStrike)

    //console.log({userRoles, teamFlags})

    if (userRoles === undefined) return null

    const roles = [...userRoles, ...(teamFlags ?? [])]

     const isDev = roles.indexOf('dev') >= 0
     const isAdmin = roles.indexOf("admin-role") >= 0

    const value = {
      roles,
      isDev,
      isAdmin,
      isSuperAdmin: roles.indexOf("super-admin") >= 0,
      isEditUser: roles.indexOf("user-role") >= 0 || isAdmin,

      hasClaimScopes: roles.indexOf(claim_scope_flag) >= 0 || isDev,
      hasAnnuities: roles.indexOf(annuities_flag) >= 0 || isDev,
      isAnnuityAdmin: roles.indexOf(annuities_admin_flag) >= 0 || isDev,
      hasProductCountries: roles.indexOf(product_country_flag) >= 0 || isDev,
      hasProductOwners: roles.indexOf(product_owner_flag) >= 0 || isDev,

      hasRevenues: roles.indexOf(revenues_flag) >= 0 || isDev,
      hasCosts: roles.indexOf(costs_flag) >= 0 || isDev,
      hasTasks: roles.indexOf(tasks_flag) >= 0 || isDev,
      hasExcelImport: roles.indexOf(excel_import_flag) >= 0 || isDev,
      hasExcelExport: roles.indexOf(excel_export_flag) >= 0 || isDev,
      hasBulkEdit: roles.indexOf(bulk_edit_flag) >= 0 || isDev,

      hasInnovation: roles.indexOf(invention_flag) >= 0 || isDev,
      hasNewInnovations: roles.indexOf(new_innovation_flag) >= 0 || isDev,
      hasPortfolioUpdate: roles.indexOf(portfolio_update_flag) >= 0 || isDev,
      hasBrands: roles.indexOf(brand_flag) >= 0 || isDev,
      hasDocuments: roles.indexOf(documents_flag) >= 0 || isDev,

      hasTracking: roles.indexOf(tracking_flag) >= 0,

      hasEuipoImport: roles.indexOf(euipo_import_flag) >= 0 || isDev,
      hasActiveChangesChart: roles.indexOf(active_changes_flag) >= 0 || isDev,

      hasSortByPrio: roles.indexOf(prio_sort_flag) >= 0 || isDev,

      hasAbandonmentDate: roles.indexOf(abandonment_date_flag) >= 0 || isDev,
    };

    return <RolesContext.Provider {...{value}}>{children}</RolesContext.Provider>
}