import React, { Dispatch, SetStateAction, useContext, useEffect, useState } from "react";
import {
    useQuery,
    useMutation,
    useQueryClient,
} from '@tanstack/react-query'
import _ from 'lodash'

import { useAuth } from "./user/Auth";
import { rpcEntities, rpcLinks, rpcState } from "./backend";
import { add, bulkAdd, bulkDel, del, update } from './collection'
import { evaluation_link, evaluation_meta_link, invention_country_link, invention_inventor_link } from "./data";
import { ErrorMessage, useMessages } from './Messages'
import AgentsProvider from "./agents/AgentsProvider";
import TagsProvider from "./tags/TagsProvider";


export type OperationType = 'add' | 'update' | 'delete' | 'get'
export type BulkOperationType = OperationType | 'bulk-add' | 'bulk-delete'

const BackendContext = React.createContext({
    images: [],
    // TODO Inventions Provider
    inventions: [],
    inventionPayments: [],
    // TODO Query Provider
    dataQueries: [],
    entityOperation: (type: string, operation: OperationType, payload?: unknown) => Promise.resolve({}),

    // TODO Image Provider
    imageLinks: [],
    imagesLookup: {}, // imagesLookup[entity][entityId] -> [image]
    // TODO Inventions Provider
    inventionInventors: [],
    inventionCountries: [],
    // TODO Evaluations Provider
    evaluations: [],
    evaluationMetas: [],
    linkOperation: (type: string, operation: BulkOperationType, payload?: unknown) => Promise.resolve({}),

    isLoading: false,
    hasLoaded: false,
})

function handleError_(signout, setErrorMessage, error) {
    if (error === null || error === undefined) {
        setErrorMessage(null)
    } else if (error.status === "unauthorized") {
        // do not display error, just retrigger the redirect to /login through `isAuthorized`
        //console.log(error.status)
        //setIsAuthenticated(false)
        //setIsReady(false)
        signout()
    } else {
        if (error.status === "unreachable") {
            //setIsAuthenticated(false)
        }
        // display error
        const err_msg = error.status + ": " + (error.message ?? "")
        console.error(err_msg)
        setErrorMessage(err_msg)
    }
}

export function BackendProvider({children}) {
    const { setErrorMessage } = useMessages()
    const {signout, team} = useAuth()
    
    const handleError = (error) => handleError_(signout, setErrorMessage, error)

    const [images, setImages] = useState([])

    const [inventions, setInventions] = useState([])
    const [inventionPayments, setInventionPayments] = useState([])

    const [dataQueries, setDataQueries] = useState([])

    // payload is either the id or the data
    function entityOperation(type, operation, payload) {
        function throwError() {
            const msg = "Unknown entity type " + type
            console.warn(msg)
            throw msg
        }

        // @ts-ignore
        const [getId, originalCollection, update_collection] =
            (type === "image")
            ? [(m) => m.imageId, images, setImages]
            : (type === "invention")
            ? [(m) => m.inventionId, inventions, setInventions]
            : (type === "invention-payment")
            ? [(m) => m.paymentId, inventionPayments, setInventionPayments]
            : (type === "data-query")
            ? [(m) => m.queryId, dataQueries, setDataQueries]
            : throwError()

        switch(operation) {
            case "add":
                return rpcEntities({
                    entity: type,
                    operation,
                    data: {...payload, realm: team}
                }).then(entityWithId => {
                    update_collection(add(originalCollection, entityWithId))
                    //console.log("Added", entityWithId, " to ", originalCollection)
                    return entityWithId
                }).catch((err) => handleError(err))
            case "delete": 
                return rpcEntities({
                    entity: type,
                    operation,
                    id: payload
                }).then(() =>
                    update_collection(del(originalCollection, payload, getId))
                ).catch((err) => handleError(err))
            case "update":
                return rpcEntities({
                    entity: type,
                    operation,
                    data: {...payload, realm: team}
                }).then(() =>
                    update_collection(update(originalCollection, payload, getId))
                ).catch((err) => handleError(err))
            case "get":
                return rpcEntities({
                    entity: type,
                    operation,
                }).then(items => update_collection(items)
                ).catch((err) => handleError(err))
            default:
                handleError({status: 'Fail', message: 'Unexpected operation ' + operation + ' on the client side'})
        }
    }

    const [imageLinks, setImageLinks] = useState([]);

    const [inventionInventors, setInventionInventors] = useState([])
    const [inventionCountries, setInventionCountries] = useState([])

    const [evaluations, setEvaluations] = useState([])
    const [evaluationMetas, setEvaluationMetas] = useState([])

    function linkOperation(type: string, operation: string, payload?: object) {
        function throwError() {
            const msg = "Unknown entity type " + type
            console.warn(msg)
            throw msg
        }

        // @ts-ignore
        const [originalCollection, update_collection] =
            (type === "image-link")
            ? [imageLinks, setImageLinks]
            : (type === invention_inventor_link)
            ? [inventionInventors, setInventionInventors]
            : (type === invention_country_link)
            ? [inventionCountries, setInventionCountries]
            : (type === evaluation_link)
            ? [evaluations, setEvaluations]
            : (type === evaluation_meta_link)
            ? [evaluationMetas, setEvaluationMetas]
            : throwError()

        const data = payload instanceof Array 
            ? payload.map(p => ({...p, realm: team})) 
            : {...payload, realm: team}
        switch(operation) {
            case "add":
                return rpcLinks({
                    link: type,
                    operation,
                    data,
                }).then(() =>
                    update_collection(add(originalCollection, data))
                ).catch((err) => handleError(err))
            case "delete": 
                return rpcLinks({
                    link: type,
                    operation,
                    data,
                }).then(() =>
                    update_collection(del(originalCollection, data))
                ).catch((err) => handleError(err))
            case "bulk-add":
                return rpcLinks({
                    link: type,
                    operation,
                    data,
                }).then(() =>
                    update_collection(bulkAdd(originalCollection, data as any[]))
                ).catch((err) => handleError(err))
            case "bulk-delete": 
                return rpcLinks({
                    link: type,
                    operation,
                    data,
                }).then(() =>
                    update_collection(bulkDel(originalCollection, data as any[]))
                ).catch((err) => handleError(err))
            case "get":
                return rpcLinks({
                    link: type,
                    operation,
                }).then((col) => (update_collection(col))
                ).catch((err) => handleError(err))
            default:
                // e.g. no update here
                handleError({status: 'Fail', message: 'Unexpected operation ' + operation + ' on the client side'})
        }
    }

    const [isLoading, setIsLoading] = useState(false)
    const [hasLoaded, setHasLoaded] = useState(false)
    //const [isReady, setIsReady] = useState(true) // is certainly ready once the team token has been received
    const [isLazyLoading, setIsLazyLoading] = useState(false) // Don't show this outside the backend provider

    useEffect(() => {
        function loadState() {
            return rpcState()
        }
        // For some reason the code below will log 'Unhandled Promise Rejection'
        function loadItems(type, setItems) {
            return rpcEntities({
                entity: type,
                operation: "get",
            }).then(items => setItems(items))
        }

        //function loadLinks(type, setLinks) {
        //    return rpcLinks({
        //        link: type,
        //        operation: "get"
        //    }).then(links => setLinks(links))
        //}

        //console.log (!hasLoaded, isLoading)
        if ((!hasLoaded) && (!isLoading) && (!isLazyLoading)) {
            //console.log ("Going to load")
            setIsLoading(true)
            setIsLazyLoading(true)
            //setHasLoaded(true) // have it here and below to make sure we don't load too often
            loadState()
                .then(() => setIsLoading(false)) // lazy load the rest and already show most important things
                .then(() => loadState())
                .then(state => {
                    setImages(state.images)
                    setImageLinks(state.imageLinks)
                    setInventions(state.inventions)
                    setInventionPayments(state.inventionPayments)
                    setInventionInventors(state.inventionInventors)
                    setInventionCountries(state.inventionCountries)
                    setEvaluations(state.evaluations)
                    setEvaluationMetas(state.evaluationMetas)
                })
                .then(() => loadItems("data-query", setDataQueries)) // TODO move to status
                .then(() => setHasLoaded(true))
                .catch((err) => handleError_(signout, setErrorMessage, err))
                .finally(() => setIsLazyLoading(false))
        }
    }, [hasLoaded, isLoading, isLazyLoading, signout, setErrorMessage])

    const imageById = _.keyBy(images, 'imageId')
    const imagesLookup = _(imageLinks)
        .groupBy('entity').toPairs()
        .map(([e, links]) => [e, _(links).map(l => [l.entityId, imageById[l.imageId]]).fromPairs().value()])
        .fromPairs()
        .value()

    const value = {
        images,
        inventions,
        inventionPayments,
        dataQueries,
        entityOperation,

        imageLinks,
        imagesLookup,
        inventionInventors,
        inventionCountries,
        evaluations,
        evaluationMetas,
        linkOperation,

        isLoading,
        hasLoaded,
    }

    return (
        <BackendContext.Provider value={value}>
            <AgentsProvider>
                <TagsProvider>
                    {children}
                </TagsProvider>
            </AgentsProvider>
        </BackendContext.Provider>
    )
}

export function useBackend() {
    return useContext(BackendContext)
}

export function useCreateRestFunction<T>() {
    const {signout, team} = useAuth()
    const { setErrorMessage } = useMessages()
    
    const handleError = (error) => handleError_(signout, setErrorMessage, error)

    function createRestFunctions(setCollection: Dispatch<SetStateAction<T[]>>, id_tag: string, entity: string) {
        // This array isn't really necessary for entities right now but it might become so for a future bulk-add/bulk-delete
        const data = (payload: T | T[]) => payload instanceof Array
            ? payload.map(p => ({ ...p, realm: team }))
            : { ...payload, realm: team }
        return {
            loadEntities: 
                () => rpcEntities({entity, operation: 'get'})
                    .then(cs => { setCollection(cs); return cs })
                    .catch((err) => handleError(err)),
            addEntity:
                (payload: T) => rpcEntities({ operation: 'add', entity, data: data(payload) })
                    .then(withId => { setCollection(cs => [...cs, withId]); return withId })
                    .catch((err) => handleError(err)),
            updateEntity:
                (payload: T) => rpcEntities({ operation: 'update', entity, data: data(payload) })
                    .then(updated => { setCollection(cs => cs.map(c => c[id_tag] === updated[id_tag] ? updated : c)); return updated })
                    .catch((err) => handleError(err)),
            deleteEntity:
                async (payload: T) => {
                    const id = payload[id_tag]
                    return rpcEntities({ operation: 'delete', entity, id })
                        .then(() => setCollection(cs => cs.filter(c => c[id_tag] !== id)))
                        .catch((err) => handleError(err))
                },
        }
    }

    return {createRestFunctions}
}

/**
 * @param [links] originalLinks, the original Links
 * @param [links] newLinks, the new links
 * @returns [links_to_add, links_to_delete]
 */
export function linkDifference<T>(originalLinks: T[] = [], newLinks: T[] = [], comparator = _.isEqual as (a: T, b: T) => boolean): [T[], T[]] {
    const unchanged = _.intersectionWith(originalLinks, newLinks, comparator)
    const toDelete = _.differenceWith(originalLinks, unchanged, comparator)
    const toAdd = _.differenceWith(newLinks, unchanged, comparator)
    // console.log({unchanged, toDelete, toAdd})
    return [toAdd, toDelete]
}

// Always first delete and then get to avoid duplicat entries in DB
export async function saveDifferences<T>(toAdd: T[], toDelete: T[], linkOperation: (t: string, o: string, p?: T[]) => Promise<object>, type: string) {
    return (toDelete.length > 0 ? linkOperation(type, 'bulk-delete', toDelete) : Promise.resolve({}))
        .then(() => toAdd.length > 0 ? linkOperation(type, 'bulk-add', toAdd) : Promise.resolve({}))
        .then(() => (toDelete.length > 0 || toAdd.length > 0) ? linkOperation(type, 'get') : Promise.resolve({}))
}

export async function saveLinks<T>(originalLinks = [] as T[], newLinks = [] as T[], linkOperation, type: string, comparator = _.isEqual as (a: T, b: T) => boolean) {
    const [toAdd, toDelete] = linkDifference(originalLinks, newLinks, comparator)
    return saveDifferences(toAdd, toDelete, linkOperation, type)
}

export async function saveCrudLinks<T>(
    originalLinks: T[], newLinks: T[], 
    postOperation: (ts: T[]) => Promise<any>, deleteOperation: (ts: T[]) => Promise<object>,
    comparator = _.isEqual as (a: T, b: T) => boolean) {

    const [toAdd, toDelete] = linkDifference(originalLinks, newLinks, comparator)
    // console.log({toAdd, toDelete})
    // console.log('deleting...')
    if (toDelete.length > 0) {
        await deleteOperation(toDelete)
    }
    // console.log('done deleting')
    // console.log('adding...')
    if (toAdd.length > 0) {
        await postOperation(toAdd)
    }
    // console.log('done adding')
}


export function createDummyRestFunctions(setCollection, collection, id_tag, entity, entities) {
    return {
        [_.camelCase('load ' + entities)]:
            () => Promise.resolve(collection),
        [_.camelCase('add ' + entity)]:
            (payload) => {
                //console.log("Adding " + payload)
                const newId = Math.max(...collection.map(c => c[id_tag]), 0) + 1
                const withId = {...payload, [id_tag]: newId}
                setCollection(cs => [...cs, withId])
                //console.log("Returning")
                return Promise.resolve(withId)
            },
        [_.camelCase('update ' + entity)]:
            (payload) => {
                //console.log("Updating " + payload)
                const updated = payload
                setCollection(cs => cs.map(c => c[id_tag] === updated[id_tag] ? updated : c))
                return Promise.resolve(updated)
            } ,
        [_.camelCase('delete ' + entity)]:
            (payload) => {
                //console.log("Deleting " + payload)
                const id = payload[id_tag]
                setCollection(cs => cs.filter(c => c[id_tag] !== id))
                return Promise.resolve({})
            },
    }
}

export function useDummyCrud<T>(entity: string, id_tag: string, entities: T[]) {
    const [collection, setCollection] = useState(entities)

    function deleteMutation(payload: T) {
        const id = payload[id_tag]
        setCollection(cs => cs.filter(c => c[id_tag] !== id))
        return Promise.resolve(payload)
    }

    function postMutation(payload: T) {
        console.log("Posting ", payload)
        const id = payload[id_tag]
        if (id) { // updated
            setCollection(cs => cs.map(c => c[id_tag] === id ? payload : c))
            return Promise.resolve(payload)
        } else { // added
            const newId = Math.max(...collection.map(c => c[id_tag]), 0) + 1
            const newEntity = {...payload, [id_tag]: newId}
            setCollection(cs => [...cs, newEntity])
            return Promise.resolve(newEntity)
        }
    }
    return {
        data: collection, 
        isLoading: false, 
        postMutation, 
        deleteMutation
    }
}

export function useDummyLinkedCrud<T>(entity: string, isEqual = (a: T, b: T) => false, inital: T[] = []) {
    const [collection, setCollection] = useState(inital)

    function deleteMutation(payload: T) {
        setCollection(cs => cs.filter(c => !isEqual(c, payload)))
        return Promise.resolve(payload)
    }

    function postMutation(payload: T) {
        const existing = collection.find(c => isEqual(c, payload))
        if (existing) {
            console.warn("Existing link found. Check code")
        } else {
            setCollection(cs => [...cs, payload])
        }
        return Promise.resolve(payload)
    }

    return {
        data: collection, 
        isLoading: false, 
        postMutation, 
        deleteMutation
    }

}

export function useCrud<T>(entity: string, get_id: (e: T) => any, enabled = true, delete_invalidations = []) {
    const queryClient = useQueryClient()
    const {setErrorMessage} = useMessages()
    const {team} = useAuth()
    const {setStrike} = useAuth()

    const queryKey = [entity]
    const queryFn = () => rpcEntities<T[]>({ entity, operation: 'get' })
        .catch(err => { 
            if (err.status === 'unauthorized')
                setStrike(s => s + 1)
            setErrorMessage(err.message);
            return [] as T[] 
        })
    const { data, error, isLoading, refetch } = useQuery<T[], ErrorMessage, T[]>({
        queryKey,
        queryFn,
        placeholderData: [], initialData: [], enabled
    })

    if (error)
        setErrorMessage(error.message)

    function reload() {
        queryClient.invalidateQueries({
            queryKey,
            //queryFn,    // TODO CHECK MIGRATION
            refetchType: 'all',
        })
    }

    const mutationConfig = {
        onError: (err: ErrorMessage, payload: T, context) => {
            console.error(err.message)
            queryClient.setQueryData(queryKey, context.previousEntities)
        },
        onSettled: reload,
        // onSettled: () => {
        //     // @ts-ignore
        //     queryClient.invalidateQueries({
        //         queryKey,
        //         //queryFn,    // TODO CHECK MIGRATION
        //         refetchType: 'all',
        //     })
        // },
        enabled,
    }

    function postOperation(payload: T) {
        if (get_id(payload))
            return "update"
        else
            return "add"
    }

    const postMutation = useMutation<T, ErrorMessage, T>({
        mutationFn: (payload) =>
            rpcEntities({ entity, operation: postOperation(payload), data: { ...payload, realm: team } })
                .catch(err => setErrorMessage(err.message)), 
        ...mutationConfig,
        onMutate: async (payload) => {
            await queryClient.cancelQueries({queryKey})
            const previousEntities = queryClient.getQueryData(queryKey)
            queryClient.setQueryData(queryKey, old => {
                // check if old is of type array
                if (Array.isArray(old)) {
                    //console.log({old})
                    const p_id = get_id(payload)
                    return p_id
                        ? old.map(o => get_id(o) === p_id ? payload : o)
                        : [...old, payload]
                } else {
                    return [payload]
                }
            })
            return { previousEntities }
        },
    })

    const deleteMutation = useMutation<T, ErrorMessage, T>({
        mutationFn: (payload) =>
            rpcEntities({ entity, operation: 'delete', id: get_id(payload) })
                .catch(err => setErrorMessage(err.message)), 
        ...mutationConfig,
        onMutate: async (payload) => {
            //console.log({payload})
            await queryClient.cancelQueries({queryKey})
            const previousEntities = queryClient.getQueryData(queryKey)
            queryClient.setQueryData(queryKey, (old) => {
                return Array.isArray(old) ? old.filter(o => get_id(o) !== get_id(payload)) : []
            })
            return { previousEntities }
        },
        onSuccess: () => {
            delete_invalidations.forEach(queryKey => {
                queryClient.invalidateQueries({ queryKey, refetchType: 'all' })
            })
        }
    })

    return {
        data, 
        isLoading, 
        postMutation: postMutation.mutateAsync, 
        deleteMutation: deleteMutation.mutateAsync,
        reload, refetch,
    }
}

export function useLinkedCrud<T>(entity: string, isEqual = (a: T, b: T) => false, enabled = true) {
    const queryClient = useQueryClient()
    const {setErrorMessage} = useMessages()
    const {team} = useAuth()

    const queryKey = [entity]
    const queryFn = () => rpcLinks({ link: entity, operation: 'get' }).catch(err => { setErrorMessage(err.message); return [] })
    const { data, error, isLoading } = useQuery<T[], ErrorMessage, T[]>({
        queryKey,
        queryFn,
        placeholderData: [], initialData: [], enabled
    })

    function reload() {
        queryClient.invalidateQueries({
            queryKey,
            refetchType: 'all',
        })
    }

    if (error)
        setErrorMessage(error.message)

    const mutationConfig = {
        enabled,
        onError: (err: ErrorMessage, payload: T, context) => {
            console.error(err.message)
            queryClient.setQueryData(queryKey, context.previousEntities)
        },
        onSettled: async () => {
            //console.log('invalidate ' + queryKey)
            await queryClient.invalidateQueries({
                queryKey,
                refetchType: 'all',
            })
        }
    }

    function postOperation(payload: T | T[]) {
        if (Array.isArray(payload))
            return "bulk-add"
        else
            return "add"
    }

    function dataWithTeam(payload: T | T[]) {
        if (Array.isArray(payload))
            return payload.map(p => ({ ...p, realm: team }))
        else
            return { ...payload, realm: team }
    }

    const postMutation = useMutation<T | T[], ErrorMessage, T>({
        mutationFn: (payload) =>
            rpcLinks({ link: entity, operation: postOperation(payload), data: dataWithTeam(payload) })
                .catch((err: ErrorMessage) => setErrorMessage(err.message)), 
        ...mutationConfig,
        onMutate: async (payload) => {
            await queryClient.cancelQueries({queryKey})
            const previousEntities = queryClient.getQueryData(queryKey)
            queryClient.setQueryData(queryKey, (old: undefined | T[]) => {
                if(Array.isArray(payload)) 
                    return [...(old ?? []), ...payload]
                else 
                    return [...(old ?? []), payload]
            })
            return { previousEntities }
        },
    })

    function deleteOperation(payload: T | T[]) {
        if (Array.isArray(payload))
            return "bulk-delete"
        else
            return "delete"
    }


    const deleteMutation = useMutation<T | T[], ErrorMessage, T>({
        mutationFn: (payload) =>
            rpcLinks({ link: entity, operation: deleteOperation(payload), data: dataWithTeam(payload) })
                .catch(err => setErrorMessage(err.message)), 
        ...mutationConfig,
        onMutate: async (payload) => {
            //console.log({payload})
            await queryClient.cancelQueries({queryKey})
            const previousEntities = queryClient.getQueryData(queryKey)
            queryClient.setQueryData(queryKey, (old: undefined | T[]) => {
                if (Array.isArray(payload)) {
                    return (old ?? []).filter(o => !payload.includes(o))
                } else {
                    return Array.isArray(old) ? old.filter(o => !isEqual(o, payload)) : []
                }
            })
            return { previousEntities }
        }
    })

    return {
        data, isLoading, postMutation: postMutation.mutateAsync, deleteMutation: deleteMutation.mutateAsync, reload,
    }
}