import { NavLink, Outlet } from 'react-router-dom';
import clsx from 'clsx'


interface TabLink {
    to: string;
    label: string;
}

export function LinkedTabCard({links, className = "bg-white rounded-xl shadow p-2"}: {links: TabLink[], className?: string}) {
    return (
        <div className={className}>
            <div className='flex flex-row'>
                {links.filter(Boolean).map(({to, label}, index) =>
                    <NavLink key={index} to={to} end className={({isActive}) => clsx(
                        "px-2 sm:px-4 py-2 border-b-2", isActive ? 'border-pcx-500' : 'border-slate-300 hover:border-slate-400',
                        'text-sm font-medium', isActive ? 'text-pcx-600' : 'text-slate-500 hover:text-slate-600',
                        'whitespace-nowrap',
                    )}>{label}</NavLink>
                )}
                <div className='border-b-2 border-slate-300 grow' />
            </div>
            <div className='p-4'>
                <Outlet />
            </div>
        </div>
    )
}