import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { Field, Form, Formik } from "formik"
import { useTranslation } from "react-i18next"
import { parseBackend } from "../../backend"
import { ErrorMessage, useMessages } from "../../Messages"
import { prettyDate } from "../../utils/dates"
import { createContext, useContext, useState } from "react"
import DeleteButton from "../../components/DeleteButton"
import Modal from "../../components/Modal"
import { DocumentDuplicateIcon } from "@heroicons/react/24/outline"

const valid_roles = ['email']

/*
tokenId: Option[Long],
                    token: String,
                    userId: Long,
                    realm: String,
                    name: String,
                    roles: Seq[String],
                    expires: DateTime,
                    created: DateTime,
                    */

type ApiToken = {
    tokenId: number
    name: string
    roles: string[]
    token: string  // is only shown properly after creation
    created: string
    expires: string
}

const queryKey = ['api-tokens']

export default function ApiTokensManagement() {
    const {t} = useTranslation()

    const [showAddModal, setShowAddModal] = useState(false)

    return  <ApiTokensProvider>

        <div>
            <div className="mb-6 flex flex-row justify-between">
                <h2>{t('api-tokens')}</h2>
                <button type="button" className="btn-primary text-sm" onClick={() => setShowAddModal(true)}>Generate API Token</button>
            </div>
            <ApiTokensTable />


            {showAddModal && <AddTokenForm close={() => setShowAddModal(false)} />}
        </div>
    </ApiTokensProvider>
}

function ApiTokensTable() {
    const {t} = useTranslation()
    const {tokens, removeToken} = useApiTokens()

    if ((tokens ?? []).length === 0) {
        return <p className="text-slate-600 py-4">No API Tokens yet. Please generate one to get started. </p>
    }

    return (
            <table>
                <thead>
                    <tr className="pc-header-row">
                        <th className="pc-header-cell">Name</th>
                        <th className="pc-header-cell">Roles</th>
                        <th className="pc-header-cell">Created</th>
                        <th className="pc-header-cell">Expires</th>
                        <th className="pc-header-cell" />
                    </tr>
                    </thead>
                    <tbody>
                        {tokens?.map(token => <tr key={token.tokenId}>
                            <td className="pc-cell">{token.name}</td>
                            <td className="pc-cell">{token.roles.join(', ')}</td>
                            <td className="pc-cell">{token.created ? prettyDate(token.created) : ''}</td>
                            <td className="pc-cell">{token.expires ? prettyDate(token.expires) : ''}</td>
                            <td className="pc-cell align-top">
                                <div className="size-5">
                                <DeleteButton deleteAction={() => removeToken(token.tokenId)} question={t('really-delete-name', {name: token.name})} />
                                </div>
                            </td>
                        </tr>)}
                    </tbody>
            </table>
    )
}

// TODO: display token after success
function AddTokenForm({close}: {close: () => void}) {
    const {t} = useTranslation()

    const {addToken} = useApiTokens()
    const [token, setToken] = useState(undefined as ApiToken | undefined)
    //const [token, setToken] = useState({token: '57b10bfe7242f993d73f4acc63224a77fb723400de90bd1ad6fb3ec36d3ead6671aacecdeeb1702662175080501e43cda8294804fb65ed9f2a7d71b72b9c8fa19088488ad1c120434965dd3cb0cadf40ff5aeb67caf86d62ca578f80609f9c50d860cdfb1734bcef6e530e32f88be1ce6914fa67600bba47ac1cecea2bbb5e5b'} as ApiToken | undefined)

    const roles = valid_roles

    return (
      <Modal>
        {token?.token !== undefined
        ? <div className="p-4">
            <h4 className="mb-2">New API Token</h4>
            <p className="max-w-prose text-slate-800">
                Attention! Please <b>copy the Token</b> below and store it somewhere save. After closing this Modal, the token cannot be seen anymore and a new one must be created.
            </p>
            <div className="py-2 flex flex-row gap-2 items-center">
                <input title={'Token'} className="font-mono w-10/12 text-ellipsis border border-pcx-500 rounded px-1" value={token.token} />

                <label htmlFor="copied" className="h-fit align-top inline-block" title={t('copy')} onClick={() => navigator.clipboard.writeText(token.token)}>
                    <div className="btn-primary p-0.5 align-bottom h-fit">
                    <DocumentDuplicateIcon className="align-middle size-4"/>
                    </div>
                </label>
                <input type="checkbox" id="copied" title={t('copy')} className="peer hidden"/>

                <span className="peer-checked:inline hidden text-sm text-pcx-600">{t('copied')}!</span>
            </div>

            <div className="flex flex-row-reverse mt-2">
                <button type="button" className="btn-secondary" onClick={close}>{t('close')}</button>
            </div>
        </div> 
        : 
        <Formik
          initialValues={{ name: "", roles }}
          onSubmit={async (values) => {
            const token = await addToken(values);
            console.log({token})
            setToken(token);
          }}
        >
          {({ isSubmitting }) => (
            <>
              <Form className="max-w-sm w-fit " autoComplete="off">
                <div className="p-4 flex flex-col gap-4">
              <h4 className="-mb-2">New API Token</h4>
                <label className="label leading-loose" htmlFor="name">
                  Name for your API Token <br />
                  <Field
                    name="name"
                    className="form-input"
                    type="text"
                    placeholder="Name..."
                    required
                    autoFocus
                  />
                </label>
                <div>
                  <span className="label inline">Roll:</span>{" "}
                  <span className="text-lg font-medium">
                    {roles.join(", ")}
                  </span>
                </div>
                </div>
                <div className="p-4 flex flex-row-reverse gap-4 bg-pcx-200">
                  <button
                    type="submit"
                    className="btn-primary"
                    disabled={isSubmitting}
                  >
                    Generate
                  </button>
                  <button type="button" className="btn-secondary" onClick={close}>
                    {t('cancel')}
                  </button>
                </div>
              </Form>
            </>
          )}
        </Formik>
      }</Modal>
    );
}

const ApiTokensContext = createContext({
  tokens: [] as ApiToken[],
  addToken: (token: Partial<ApiToken>) => Promise.resolve(token as ApiToken),
  removeToken: (tokenId: number) => Promise.resolve(),
});

function ApiTokensProvider({children}) {

    const {setErrorMessage} = useMessages()

    const client = useQueryClient()

    // TODO: This must return the API Token
    const { mutateAsync: addToken } = useMutation<ApiToken, ErrorMessage, Partial<ApiToken>>({
        mutationFn: async (values: Partial<ApiToken>) => {
            return parseBackend(fetch("/api/tokens", {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify(values),
            }));
            // return response as ApiToken
        },
        onSuccess: () => {
            client.invalidateQueries({queryKey})
        },
        onError: (error) => {
            setErrorMessage(error.message)
        }
    });

    const { mutateAsync: removeToken } = useMutation<void, ErrorMessage, number>({
        mutationFn: async (tokenId: number) => {
            const response = fetch(`/api/tokens/${tokenId}`, {
                method: "DELETE",
            });
            return (await parseBackend(response))
        },
        onSuccess: () => {
            client.invalidateQueries({queryKey})
        },
        onError: (error) => {
            setErrorMessage(error.message)
        }
    });

    const {data: tokens} = useQuery<ApiToken[]>({
        queryKey,
        queryFn: async () => {
            const response = await fetch('/api/tokens')
            const resp = await response.json()
            return resp.payload
        },
    })

    const value = {
        tokens,
        addToken,
        removeToken,
    }

    return <ApiTokensContext.Provider value={value}>{children}</ApiTokensContext.Provider>
}

function useApiTokens() {
    return useContext(ApiTokensContext)
}